import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import d2Source from './components/d2-source.vue';
var containers = {
  full: function full() {
    return import('./components/d2-container-full.vue');
  },
  fullbs: function fullbs() {
    return import('./components/d2-container-full-bs.vue');
  },
  ghost: function ghost() {
    return import('./components/d2-container-ghost.vue');
  },
  ghostbs: function ghostbs() {
    return import('./components/d2-container-ghost-bs.vue');
  },
  card: function card() {
    return import('./components/d2-container-card.vue');
  },
  cardbs: function cardbs() {
    return import('./components/d2-container-card-bs.vue');
  }
};
export default {
  name: 'd2-container',
  props: {
    // 容器样式
    type: {
      type: String,
      required: false,
      default: 'full'
    },
    // 滚动优化
    betterScroll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    // 始终返回渲染组件
    component: function component() {
      return containers["".concat(this.type).concat(this.betterScroll ? 'bs' : '')] || 'div';
    }
  },
  render: function render(h) {
    var _this = this;
    var slots = [this.$slots.default, this.$slots.header ? h("template", {
      "slot": "header"
    }, [this.$slots.header]) : null, this.$slots.footer ? h("template", {
      "slot": "footer"
    }, [this.$slots.footer]) : null];
    return h("div", {
      "ref": "container",
      "class": "container-component"
    }, [h(this.component, {
      "ref": "component",
      "attrs": _objectSpread({}, this.$attrs),
      "on": {
        "scroll": function scroll(e) {
          return _this.$emit('scroll', e);
        }
      }
    }, [slots]), h(d2Source)]);
  },
  methods: {
    // 返回顶部
    scrollToTop: function scrollToTop() {
      this.$refs.component.scrollToTop();
      // 如果开启了 better scroll 还需要手动触发一遍 scroll 事件
      var bs = this.$refs.component.BS;
      if (bs) this.$refs.component.scroll();
    },
    // 用法同原生方法 scrollBy
    scrollBy: function scrollBy() {
      var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 300;
      if (this.betterScroll) {
        var bs = this.$refs.component.BS;
        if (bs) {
          bs.scrollBy(-x, -y, time);
          // 手动触发一遍 scroll 事件
          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollBy(x, y);
      }
    },
    // 用法同原生方法 scrollTo
    scrollTo: function scrollTo() {
      var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 300;
      if (this.betterScroll) {
        var bs = this.$refs.component.BS;
        if (bs) {
          bs.scrollTo(-x, -y, time);
          // 手动触发一遍 scroll 事件
          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollTo(x, y);
      }
    },
    // 用法同原生方法 scrollTop
    scrollTop: function scrollTop() {
      var top = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;
      if (this.betterScroll) {
        var bs = this.$refs.component.BS;
        if (bs) {
          bs.scrollTo(bs.x, -top, time);
          // 手动触发一遍 scroll 事件
          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollTop = top;
      }
    }
  }
};