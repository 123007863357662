import "core-js/modules/es.array.fill.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "recorder"
  }, [_c("div", {
    staticClass: "mic-bar-container"
  }, [_c("div", {
    staticClass: "mic-testing-container"
  }, [_c("div", {
    staticClass: "mic-bar-container"
  }, _vm._l(new Array(20).fill(""), function (item, index) {
    return _c("div", {
      key: index,
      class: ["mic-bar ".concat(_vm.volume > index && "active")]
    });
  }), 0)]), _c("div", {
    attrs: {
      id: "audio-container"
    }
  })])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };