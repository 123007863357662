import "core-js/modules/es.number.constructor.js";
import { scrollTo } from '@/utils/scrollTo';
export default {
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 5
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [1, 2, 5, 10, 20, 30, 50, 100, 150, 200, 300];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    textAlign: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get: function get() {
        return this.limit;
      },
      set: function set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.currentPage = 1;
      this.$emit('pagination', {
        page: 0,
        limit: val
      });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pagination', {
        page: val,
        limit: this.pageSize
      });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    }
  }
};