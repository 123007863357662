import "core-js/modules/es.array.map.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip, echartToolbox } from '@/config/dataDictionary';
import { getStoresaleTendency as _getStoresaleTendency } from '@/api/businessSystem';
export default {
  name: 'TotalSaleInfo',
  props: {},
  data: function data() {
    return {
      timeArr: [],
      group: '',
      homeTip: homeTip,
      radio: 3,
      tableData: [],
      loading: false,
      chartData: {},
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {},
  created: function created() {
    var startTime = this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss');
    var endTime = "".concat(this.$dayjs().format('YYYY-MM-DD'));
    this.timeArr = [startTime || '', endTime || ''];
    this.getStoresaleTendency();
    // this.getDataList()
  },
  methods: {
    radioGroupChange: function radioGroupChange() {
      this.getStoresaleTendency();
    },
    dateChange: function dateChange() {
      this.getStoresaleTendency();
    },
    getStoresaleTendency: function getStoresaleTendency() {
      var _this = this;
      this.loading = true;
      var timeArr = this.timeArr,
        group = this.group;
      var postDate = {
        startTime: timeArr[0] || null,
        endTime: timeArr[1] || null,
        group: group
      };
      _getStoresaleTendency(postDate).then(function (res) {
        _this.loading = false;
        if (res) {
          _this.tableData = res.body.storeSaleSortList;
          var echartsDate = res.body.saleTendencyList;
          _this.init(echartsDate);
        }
      });
    },
    init: function init(echartsDate) {
      var xData = this.$lodash.map(echartsDate, 'date');
      var totalRevenue = this.$lodash.map(echartsDate, 'totalRevenue');
      var autoDutyRevenue = this.$lodash.map(echartsDate, 'autoDutyRevenue');
      var businessRevenue = this.$lodash.map(echartsDate, 'businessRevenue');
      this.chartData = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // icon: 'roundRect',
          data: ['总销售额', '云值守', '有人值守']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: echartToolbox,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
        },
        calculable: true,
        yAxis: {
          type: 'value',
          axisLine: {
            show: true
          },
          name: '金额',
          min: 0,
          axisLabel: {
            formatter: '{value} 元'
          }
        },
        series: [{
          name: '总销售额',
          unit: '元',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#ea9b37'
            }
          },
          data: totalRevenue
        }, {
          name: '云值守',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#29ba51'
            }
          },
          data: autoDutyRevenue
        }, {
          name: '有人值守',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#1785ff'
            }
          },
          data: businessRevenue
        }]
      };
    }
  }
};