var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "page"
  }, [_c("el-card", [_c("div", {
    staticClass: "box-card"
  }, [_c("el-button", {
    staticClass: "back info",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _c("div", {
    staticClass: "info"
  }, [_vm._v("门店编码：" + _vm._s(_vm.storeNumber))]), _c("div", {
    staticClass: "info"
  }, [_vm._v("门店名称：" + _vm._s(_vm.storeName))])], 1)]), _c("div", {
    ref: "liveBox",
    staticClass: "live-box"
  }, [_vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: item.deviceSerial,
      staticClass: "live-room",
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px"
      }
    }, [(item.ossVideo || item.liveVideo) && _vm.ysToken && _vm.width ? _c("vueVdeoPlayer", {
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px"
      },
      attrs: {
        videoInfo: item,
        liveUrl: item.ossVideo,
        width: _vm.width,
        height: _vm.height,
        ysToken: _vm.ysToken
      }
    }) : _vm._e(), !item.ossVideo && !item.liveVideo ? _c("div", {
      staticClass: "noliveVideo",
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px"
      }
    }, [_vm._v(" 设备【" + _vm._s(item.deviceSerial) + "】暂无直播视频源 ")]) : _vm._e()], 1);
  }), !_vm.list.length ? _c("div", [_c("el-empty", {
    attrs: {
      "image-size": 200,
      description: "暂无视频文件"
    }
  })], 1) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };