import _defineProperty from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.reflect.has.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import EZUIKit from 'ezuikit-js';
import { liveRoomthemeData, ysErrorCode } from '@/config/dataDictionary';
var player = 'liveRoom';
export default {
  name: 'liveRoom',
  props: {
    liveUrl: String,
    keyRef: String,
    // index: String,
    index: [Number, String],
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    curentIndex: [Number, String],
    ysToken: String
  },
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty(_defineProperty({
      liveRoomthemeData: liveRoomthemeData
    }, player + this.keyRef, null), "playAuto", 'start'), "messagError", null), "loading", false);
  },
  watch: {
    height: function height(val) {
      if (val) {
        try {
          this[player + this.keyRef].reSize(this.width, val);
          this[player + this.keyRef] = null;
        } catch (error) {}
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      var that = this;
      var option = {
        id: that.keyRef,
        // 视频容器ID
        accessToken: that.ysToken,
        audio: 0,
        autoplay: false,
        url: this.liveUrl,
        width: this.width,
        height: this.height,
        themeData: liveRoomthemeData,
        handleSuccess: function handleSuccess() {
          _this.messagError = null;
          _this.loading = false;
          if (_this.playAuto === 'start') {
            // 延时是为了有画面
            setTimeout(function () {
              _this.stop();
              _this.playAuto = 'noStart';
            }, 1500);
          }
        },
        handleError: function handleError(res) {
          // 6519 设备端断流 重新加载 https://open.ys7.com/help/37
          if (Reflect.has(ysErrorCode, res.retcode)) {
            var _res$msg, _res$msg2;
            res.msg = (_res$msg = res.msg) === null || _res$msg === void 0 ? void 0 : _res$msg.replace('，刷新重试', '');
            res.msg = (_res$msg2 = res.msg) === null || _res$msg2 === void 0 ? void 0 : _res$msg2.replace('，请重试', '');
            _this.messagError = res;
            _this.loading = true;
            _this[player + _this.keyRef].stop();
            _this[player + _this.keyRef].play();
          }
        }
      };
      this[player + this.keyRef] = new EZUIKit.EZUIKitPlayer(option);
    },
    // 开始播放
    play: function play() {
      var playPromise = this[player + this.keyRef].play();
      playPromise.then(function (data) {});
    },
    // 停止播放
    stop: function stop() {
      var stopPromise = this[player + this.keyRef].stop();
      stopPromise.then(function (data) {});
    },
    getOSDTime: function getOSDTime() {
      var getOSDTimePromise = this[player + this.keyRef].getOSDTime();
      getOSDTimePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    capturePicture: function capturePicture() {
      var capturePicturePromise = this[player + this.keyRef].capturePicture("".concat(new Date().getTime()));
      capturePicturePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    openSound: function openSound() {
      var openSoundPromise = this[player + this.keyRef].openSound();
      openSoundPromise.then(function (data) {
        console.log('promise 关闭声音', data);
      });
    },
    closeSound: function closeSound() {
      var openSoundPromise = this[player + this.keyRef].closeSound();
      openSoundPromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    startSave: function startSave() {
      var startSavePromise = this[player + this.keyRef].startSave("".concat(new Date().getTime()));
      startSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    stopSave: function stopSave() {
      var stopSavePromise = this[player + this.keyRef].stopSave();
      stopSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    ezopenStartTalk: function ezopenStartTalk() {
      // this[player + this.keyRef].openSound()
      this[player + this.keyRef].startTalk();
    },
    ezopenStopTalk: function ezopenStopTalk() {
      // this[player + this.keyRef].closeSound()
      try {
        this[player + this.keyRef].stopTalk();
      } catch (error) {
        this.$emit('closeVideo');
      }
    },
    fullScreen: function fullScreen() {
      this[player + this.keyRef].fullScreen();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this[player + this.keyRef]) {
      try {
        this.ezopenStopTalk();
        this[player + this.keyRef].stop();
        this[player + this.keyRef].destroy();
      } catch (error) {
        // console.log(error)
      }
    }
  }
};