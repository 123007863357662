// import request from '@/utils/request'
import { request } from '@/api/_service.js';
import axios from 'axios';
export function getToken(data) {
  var url = '/automatic-vending/oss/getToken';
  return request({
    url: url,
    method: 'get',
    data: data
  });
}
export function login(data) {
  var url = '/automatic-vending/system-user/login';
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export function logoutApi(data) {
  var url = '/automatic-vending/system-user/logout';
  return request({
    url: url,
    method: 'post'
  });
}
// 获取萤石token
export function getYsToken() {
  var url = '/automatic-vending/duty-task/ys/token';
  return request({
    url: url,
    method: 'get'
  });
}
// 修改密码
export function updatePassword(data) {
  var url = '/automatic-vending/system-user/password/update';
  return request({
    url: url,
    method: 'put',
    data: data
  });
}
// 新增用户
export function addUser(data) {
  var url = '/automatic-vending/system-user/save';
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
// 修改用户
export function updateUser(data) {
  var url = '/automatic-vending/system-user/update';
  return request({
    url: url,
    method: 'put',
    data: data
  });
}
// 删除用户
export function deleteUser(userNumber) {
  var url = "/automatic-vending/system-user/delete/".concat(userNumber);
  return request({
    url: url,
    method: 'delete'
  });
}

// 获取用户列表
export function userList(params, that) {
  var url = '/automatic-vending/system-user/list';
  return request({
    url: url,
    method: 'get',
    params: params
    // // 设置 cancel token 用于多次请求，中断上一次请求
    // cancelToken: new axios.CancelToken(function executor (c) {
    //   // 传递this增加source属性
    //   that.source = c
    // })
  });
}

// 任务列表
export function taskList(params) {
  var url = '/automatic-vending/duty-task/list';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}
// 任务详情
export function taskDetail(params) {
  var url = '/automatic-vending/duty-task/detail';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}

// 结束任务
export function stopTask(params) {
  var url = '/automatic-vending/duty-task/stop';
  return request({
    url: url,
    method: 'put',
    params: params
  });
}
// 开始工作
export function startTask(params) {
  var url = '/automatic-vending/duty-task/work/start';
  return request({
    url: url,
    method: 'post',
    params: params
  });
}
// 结束工作
export function endTask(params) {
  var url = '/automatic-vending/duty-task/work/end';
  return request({
    url: url,
    method: 'post',
    params: params
  });
}
// 工作台
export function worktableList(that) {
  var url = '/automatic-vending/duty-task/worktable';
  return request({
    url: url,
    method: 'get'
    // // 设置 cancel token 用于多次请求，中断上一次请求
    // cancelToken: new axios.CancelToken(function executor (c) {
    //   // 传递this增加source属性
    //   that.worktableListHttp = c
    // })
  });
}
// 商户密码
export function storePassword(params) {
  var url = '/automatic-vending/duty-task/store/password';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}

// 客服手动开门
export function openGate(params) {
  var url = '/automatic-vending/gate/open';
  return request({
    url: url,
    method: 'put',
    params: params
  });
}

// 商户列表
export function searchMerchant(params) {
  var url = '/automatic-vending/merchant-backend/search';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}
// 门店列表
export function searchStoreBackend(params) {
  var url = '/automatic-vending/store-backend/search';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}

// 新增商户
export function addMerchantBackend(data) {
  var url = '/automatic-vending/merchant-backend/add';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 新增门店
export function addstoreBackend(data) {
  var url = '/automatic-vending/store-backend/add';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
export function deleteStoreBackend(storeNumber) {
  var url = "/automatic-vending/store-backend/delete/".concat(storeNumber);
  return request({
    url: url,
    method: 'DELETE'
  }).catch(function (e) {
    return e;
  });
}

// 修改门店
export function editStoreBackend(data) {
  var url = '/automatic-vending/store-backend/edit';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 修改商户
export function editMerchantBackend(data) {
  var url = '/automatic-vending/merchant-backend/edit';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 门店详情
export function getStoreDetail(params) {
  var url = '/automatic-vending/store-backend/detail';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}
// 商户详情
export function getMerchantDetail(params) {
  var url = '/automatic-vending/merchant-backend/detail';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 查询门店二维码是否存在
export function searchQrcode(params) {
  var url = '/automatic-vending/qrcode/status';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 模糊查询门店名下对应任务状态
export function searchDutyStore(params) {
  var url = '/automatic-vending/duty-task/store/search';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}
// 手动创建任务
export function createDuty(params) {
  var url = '/automatic-vending/duty-task/create';
  return request({
    url: url,
    method: 'post',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 转交任务
export function transferDuty(params) {
  var url = '/automatic-vending/duty-task/transfer';
  return request({
    url: url,
    method: 'put',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 认领任务
export function claimDuty(params) {
  var url = '/automatic-vending/duty-task/claim';
  return request({
    url: url,
    method: 'put',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 新增埋点
export function addEventTrack(data) {
  var url = '/automatic-vending/event-tracking/add';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 激活设备
export function deviceActivate(params) {
  var url = '/automatic-vending/store-backend/device/activate';
  return request({
    url: url,
    method: 'put',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 视频直播
export function getVideoLive(params) {
  var url = '/automatic-vending/store/video/live';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 视频回放
export function getVideoPlayback(params) {
  var url = '/automatic-vending/store/task/playback';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 呼叫接口
export function startCall(data) {
  var url = '/automatic-vending/audio/call';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
//  结束通话/退出房间
export function stopCall(data) {
  var url = '/automatic-vending/audio/exit';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}

//  加入对讲 接听
export function joinCall(data) {
  var url = '/automatic-vending/audio/join';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 获取sdk签名接口（web/android端）
export function trtcUserSign(data) {
  var url = '/automatic-vending/audio/user/sign';
  return request({
    url: url,
    method: 'get',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 订单列表
export function getOrderList(params) {
  var url = '/automatic-vending/order/search/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 新增代理商
export function addAgent(data) {
  var url = '/automatic-vending/agent/save';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 代理商列表
export function getAgentList(params) {
  var url = '/automatic-vending/agent/search/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 订单导出
export function exportOrder(params) {
  var url = '/automatic-vending/order/export';
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    // 必须加，否则可能出现乱码或者文件错误，导致文件无法打开
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店配置
export function storeDutyConfig(data) {
  var url = '/automatic-vending/store-backend/duty/config';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 值守记录
export function storeDutyRecord(params) {
  var url = '/automatic-vending/store-backend/duty/record/search';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 权限资源分页查询
export function getResourceList(params) {
  var url = '/automatic-vending/resource/page/search';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 新增权限资源
export function addResource(data) {
  var url = '/automatic-vending/resource/save';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 修改权限资源
export function updateResource(data) {
  var url = '/automatic-vending/resource/update';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 删除权限资源
export function deleteResource(resourceCode) {
  var url = "/automatic-vending/resource/delete/".concat(resourceCode);
  return request({
    url: url,
    method: 'delete'
  }).catch(function (e) {
    return e;
  });
}
// 权限角色分页查询
export function getRoleList(params) {
  var url = '/automatic-vending/role/page/search';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 角色详情
export function getRoleDetail(roleCode) {
  var url = "/automatic-vending/role/".concat(roleCode, "/detail");
  return request({
    url: url,
    method: 'get'
  }).catch(function (e) {
    return e;
  });
}

// 新增角色
export function addRole(data) {
  var url = '/automatic-vending/role/save';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 修改角色
export function updateRole(data) {
  var url = '/automatic-vending/role/update';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 删除角色资源
export function deleteRole(roleCode) {
  var url = "/automatic-vending/role/delete/".concat(roleCode);
  return request({
    url: url,
    method: 'delete'
  }).catch(function (e) {
    return e;
  });
}

// 获取权限全部列表
export function getAllResourcelist(params) {
  var url = '/automatic-vending/resource/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 更新权限角色状态
export function updateRoleStatus(data) {
  var url = '/automatic-vending/role/status/update';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 列举所有数据权限属性列表
export function getAllDataRule(data) {
  var url = '/automatic-vending/data-rule/field/list/all';
  return request({
    url: url,
    method: 'get',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 分页查询数据权限规则
export function getDataRule(params) {
  var url = '/automatic-vending/data-rule/page/search';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 全部数据权限规则
export function getAllDataRrule(params) {
  var url = '/automatic-vending/data-rule/list/all';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}

// 新增数据权限规则
export function addDataRule(data) {
  var url = '/automatic-vending/data-rule/save';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 更新数据权限规则
export function updateDataRule(data) {
  var url = '/automatic-vending/data-rule/update';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 删除数据权限规则
export function deleteDataRule(dataRuleCode) {
  var url = "/automatic-vending/data-rule/delete/".concat(dataRuleCode);
  return request({
    url: url,
    method: 'delete'
  }).catch(function (e) {
    return e;
  });
}
// 更新权限数据权限状态
export function updateDataRuleStatus(data) {
  var url = '/automatic-vending/data-rule/status/update';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 语音播报配置列表
export function getVoiceReportConfig(params) {
  var url = '/automatic-vending/voice-report-config/list/all';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 值守-语音播报
export function reportVoice(data) {
  var url = '/automatic-vending/duty-task/voice-report';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 客服工作台-任务详情
export function getTaskDetail(params, that) {
  var url = '/automatic-vending/duty-task/worktable/task/detail';
  return request({
    url: url,
    method: 'get',
    params: params
    // // 设置 cancel token 用于多次请求，中断上一次请求
    // cancelToken: new axios.CancelToken(function executor (c) {
    //   // 传递this增加source属性
    //   that.taskDetailHttp = c
    // })
  }).catch(function (e) {
    return e;
  });
}
// 强制开锁
export function forceCloseGate(data) {
  var url = '/automatic-vending/duty-task/forceCloseGate';
  return request({
    url: url,
    method: 'put',
    data: data
  }).catch(function (e) {
    return e;
  });
}
// 获取任务订单
export function getOrderByTaskNumber(params) {
  var url = '/automatic-vending/duty-task/order/search';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}
// 查询商户代理商套餐
export function getMealList(params) {
  var url = '/automatic-vending/merchant-backend/meal/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}

// 查询商户代理商套餐时限价格列表
export function getMealTimeLimitList(params) {
  var url = '/automatic-vending/merchant-backend/meal/timelimit/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 标签列表
export function getTagList() {
  var url = '/automatic-vending/tag/list';
  return request({
    url: url,
    method: 'get'
  }).catch(function (e) {
    return e;
  });
}
// 新增标签
export function addTag(data) {
  var url = '/automatic-vending/tag/add';
  return request({
    url: url,
    method: 'post',
    data: data
  });
}

// 修改标签
export function updateTag(data) {
  var url = '/automatic-vending/tag/update';
  return request({
    url: url,
    method: 'put',
    data: data
  });
}
// 删除标签
export function deleteTag(params) {
  var url = "/automatic-vending/tag/delete/".concat(params.tagNumber);
  return request({
    url: url,
    method: 'delete'
  });
}
// 客服值守任务打标签
export function addDutyTask(data) {
  var url = '/automatic-vending/duty-task/tag';
  return request({
    url: url,
    method: 'post',
    data: data
  });
}

// 门店行业列表
export function getIndustryList(params) {
  var url = '/automatic-vending/store-backend/industry/list';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店统计概览
export function getStoreOverview(params) {
  var url = '/automatic-vending/statistics/store/overview';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 值守统计概览
export function getDutyOverview(params) {
  var url = '/automatic-vending/statistics/duty/overview';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店销售趋势
export function getStoresaleTendency(params) {
  var url = '/automatic-vending/statistics/store/sale/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店实时订单趋势
export function getRealtimeOrder(params) {
  var url = '/automatic-vending/statistics/store/realtime/order';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店值守趋势
export function getDutyTendency(params) {
  var url = '/automatic-vending/statistics/duty/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  });
}

// 门店值守订单趋势
export function getDutyTendencyOrder(params) {
  var url = '/automatic-vending/statistics/duty/order/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店值守活跃率
export function getDutyActivityTendency(params) {
  var url = '/automatic-vending/statistics/duty/activity/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店值守活跃率
export function getDutyRetention(params) {
  var url = '/automatic-vending/statistics/duty/retention';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店值守排行榜
export function getDutyTop(params) {
  var url = '/automatic-vending/statistics/duty/top';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 门店区域分布
export function getStoreCity(params) {
  var url = '/automatic-vending/statistics/store/city';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 月新增门店值守留存
export function getStoreNewlyRetention(params) {
  var url = '/automatic-vending/statistics/duty/newly/retention';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}

// 门店商品销售排行榜
export function storeSaleTop(params) {
  var url = '/automatic-vending/statistics/store/sale/top';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 客服值守质量曲线
export function customerQuality(params) {
  var url = '/automatic-vending/statistics/duty/customer/quality';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 客服值守效率曲线
export function customerEfficiency(params) {
  var url = '/automatic-vending/statistics/duty/customer/efficiency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 顾客在店指标
export function inStoreTendency(params) {
  var url = '/automatic-vending/statistics/user/in-store/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 顾客转化率指标
export function userTransferTendency(params) {
  var url = '/automatic-vending/statistics/user/transfer/tendency';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 用户数据-概览
export function userOverview(params) {
  var url = '/automatic-vending/statistics/user/overview';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 值守任务视频呼叫
export function ysVideoCall(data) {
  var url = '/automatic-vending/duty-task/video/call';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 值守任务切换主摄像头
export function switchCoreCamera(data) {
  var url = '/automatic-vending/duty-task/switch/core/camera';
  return request({
    url: url,
    method: 'post',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 查询收银-商品
export function searchGoods(params) {
  var url = '/automatic-vending/duty-task/search/goods';
  return request({
    url: url,
    method: 'get',
    params: params
  }).catch(function (e) {
    return e;
  });
}
// 新增收银-商品
export function addGoods(data) {
  var url = '/automatic-vending/duty-task/add/goods';
  return request({
    url: url,
    method: 'POST',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 添加收银商品到购物车
export function cartUpdate(data) {
  var url = '/automatic-vending/duty-task/cart/update';
  return request({
    url: url,
    method: 'POST',
    data: data
  }).catch(function (e) {
    return e;
  });
}

// 创建支付成功订单
export function createOrder(data) {
  var url = '/automatic-vending/duty-task/create/order';
  return request({
    url: url,
    method: 'POST',
    data: data
  }).catch(function (e) {
    return e;
  });
}