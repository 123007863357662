import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import dayjs from 'dayjs';
import { get } from 'lodash';
// import util from '@/libs/util.js'

export default {
  namespaced: true,
  state: {
    // 错误日志
    // + 日志条目的属性
    //   - message 必须 日志信息
    //   - type 非必须 类型 success | warning | info(默认) | danger
    //   - time 必须 日志记录时间
    //   - meta 非必须 其它携带信息
    log: []
  },
  getters: {
    /**
     * @description 返回现存 log (all) 的条数
     * @param {*} state vuex state
     */
    length: function length(state) {
      return state.log.length;
    },
    /**
     * @description 返回现存 log (error) 的条数
     * @param {*} state vuex state
     */
    lengthError: function lengthError(state) {
      return state.log.filter(function (log) {
        return log.type === 'danger';
      }).length;
    }
  },
  actions: {
    /**
     * @description 添加一个日志
     * @param {Object} context
     * @param {String} param message {String} 信息
     * @param {String} param type {String} 类型
     * @param {Object} payload meta {Object} 附带的信息
     */
    push: function push(_ref, _ref2) {
      var rootState = _ref.rootState,
        commit = _ref.commit;
      var message = _ref2.message,
        _ref2$type = _ref2.type,
        type = _ref2$type === void 0 ? 'info' : _ref2$type,
        meta = _ref2.meta;
      commit('push', {
        message: message,
        type: type,
        time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        meta: _objectSpread({
          // 当前用户信息
          user: rootState.d2admin.user.info,
          // 当前用户的 uuid
          uuid: sessionStorage.getItem('uuid') || '',
          // 当前地址
          url: get(window, 'location.href', '')
        }, meta)
      });
    }
  },
  mutations: {
    /**
     * @description 添加日志
     * @param {Object} state state
     * @param {Object} log data
     */
    push: function push(state, log) {
      state.log.push(log);
    },
    /**
     * @description 清空日志
     * @param {Object} state state
     */
    clean: function clean(state) {
      // store 赋值
      state.log = [];
    }
  }
};