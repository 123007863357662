import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
export default {
  props: {
    list: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedOptions: [],
      options: regionData,
      editForm: {},
      addForm: {}
    };
  },
  methods: {
    handleChange: function handleChange(value) {
      var _TextToCode$province$;
      if (!value.length) {
        this.$emit('update:list', {});
        this.$emit('handleChange', {});
        // this.selectedOptions = []
        this.$emit('update:adressListError', '请选择省市区');
        return false;
      }
      var province = CodeToText[value[0]];
      var city = CodeToText[value[1]];
      var country = CodeToText[value[2]];
      this.addForm.text = "".concat(province, "/").concat(city).concat(country ? '/' : '').concat(country || '');
      this.addForm.CHINESE = !['香港特别行政区', '澳门特别行政区'].includes(province) ? [province, city, country || ''] : [province, city, city || ''];
      this.addForm.ENGLISH = [TextToCode[province].code, TextToCode[province][city].code, ((_TextToCode$province$ = TextToCode[province][city][country]) === null || _TextToCode$province$ === void 0 ? void 0 : _TextToCode$province$.code) || ''];
      this.$emit('update:list', this.addForm);
      this.$emit('update:adressListError', '');
      this.$emit('handleChange', this.addForm);
    },
    editPeople: function editPeople(value) {
      // const row = '内蒙古自治区/通辽市/开鲁县'
      this.selectedOptions = [];
      if (!(value !== null && value !== void 0 && value.length)) {
        this.$emit('update:list', {});
        return false;
      }
      var province = value[0];
      var city = value[1];
      var country = '';
      if (!['香港特别行政区', '澳门特别行政区'].includes(province)) {
        country = value[2];
      }
      var ENGLISH = [];
      console.log(TextToCode);
      if (country) {
        var _TextToCode$province, _TextToCode$province$2, _TextToCode$province$3;
        ENGLISH = [((_TextToCode$province = TextToCode[province]) === null || _TextToCode$province === void 0 ? void 0 : _TextToCode$province.code) || '', ((_TextToCode$province$2 = TextToCode[province][city]) === null || _TextToCode$province$2 === void 0 ? void 0 : _TextToCode$province$2.code) || '', ((_TextToCode$province$3 = TextToCode[province][city][country]) === null || _TextToCode$province$3 === void 0 ? void 0 : _TextToCode$province$3.code) || ''];
      } else {
        var _TextToCode$province2, _TextToCode$province$4;
        ENGLISH = [((_TextToCode$province2 = TextToCode[province]) === null || _TextToCode$province2 === void 0 ? void 0 : _TextToCode$province2.code) || '', ((_TextToCode$province$4 = TextToCode[province][city]) === null || _TextToCode$province$4 === void 0 ? void 0 : _TextToCode$province$4.code) || ''];
      }
      // console.log(ENGLISH)
      this.selectedOptions = ENGLISH;
      this.addForm.text = "".concat(province, "/").concat(city).concat(country || '');
      this.addForm.CHINESE = [province, city, country || ''];
      this.addForm.ENGLISH = ENGLISH;
      this.$emit('update:list', this.addForm);
    }
  }
};