import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm$lastSaleInfo$tota, _vm$lastSaleInfo$tota2, _vm$lastSaleInfo$tota3, _vm$lastSaleInfo$tota4, _vm$lastSaleInfo$tota5, _vm$lastSaleInfo$tota6, _vm$lastSaleInfo$auto, _vm$lastSaleInfo$auto2, _vm$lastSaleInfo$auto3, _vm$lastSaleInfo$auto4, _vm$lastSaleInfo$auto5, _vm$lastSaleInfo$auto6, _vm$lastSaleInfo$busi, _vm$lastSaleInfo$busi2, _vm$lastSaleInfo$busi3, _vm$lastSaleInfo$busi4, _vm$lastSaleInfo$busi5, _vm$lastSaleInfo$busi6;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "gj-flex-wrap",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "box-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      flex: "main:justify"
    }
  }, [_c("span", {
    staticClass: "all-title"
  }, [_vm._v("总销售额(元)")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.totalSalesTip)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "card-digit"
  }, [_vm._v(_vm._s(Number(_vm.storeOverview.totalRevenue || 0).toLocaleString() || 0))]), _c("div", {
    staticClass: "borderTB secord-title"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("云值守")]), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(Number(_vm.storeOverview.autoDutyRevenue || 0).toLocaleString() || 0) + " "), _c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("(" + _vm._s(_vm.storeOverview.autoDutyPercent || 0) + "%)")])])]), _c("div", {
    staticClass: "secord-title"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("有人值守")]), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(Number(_vm.storeOverview.businessRevenue || 0).toLocaleString() || 0) + " "), _c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("(" + _vm._s(_vm.storeOverview.businessPercent || 0) + "%)")])])])]), _c("div", {
    staticClass: "box-card"
  }, [_c("el-tooltip", {
    staticClass: "tooltipPost",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.customerUnitPrice)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })]), _c("div", {
    staticStyle: {
      "justify-content": "space-around"
    },
    attrs: {
      flex: "dir:left"
    }
  }, [_c("div", {
    attrs: {
      flex: "dir:top main:center"
    }
  }, [_c("span", {
    staticClass: "card-title lh30px",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v("云值守客单价(元)")]), _c("span", {
    staticClass: "card-digit-one",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v(_vm._s(Number(_vm.storeOverview.autoDutyPerCustomerDeal || 0).toLocaleString() || 0))])]), _c("div", {
    attrs: {
      flex: "dir:top main:center"
    }
  }, [_c("span", {
    staticClass: "card-title lh30px",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v("有人值守客单价(元)")]), _c("span", {
    staticClass: "card-digit-one",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v(_vm._s(Number(_vm.storeOverview.businessPerCustomerDeal || 0).toLocaleString() || 0))])])])], 1)]), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("div", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      flex: ""
    }
  }, [_c("div", {
    staticClass: "middle-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("span", [_vm._v("昨日销售额(元)")]), _c("span", {
    staticClass: "card-digit"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$tota = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota === void 0 ? void 0 : _vm$lastSaleInfo$tota.revenue) || 0))])]), _c("el-tooltip", {
    staticClass: "tooltipPost",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.yesterdayTotalSalesTip)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })]), _c("div", [_c("span", {
    staticClass: "secord-title"
  }, [_vm._v("店均销售额 "), _c("span", {
    staticClass: "secord-sum"
  }, [_vm._v("¥" + _vm._s(((_vm$lastSaleInfo$tota2 = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota2 === void 0 ? void 0 : _vm$lastSaleInfo$tota2.averageRevenue) || 0))])]), _c("div", {
    attrs: {
      flex: "main:justify"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "30px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("日环比")]), ((_vm$lastSaleInfo$tota3 = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota3 === void 0 ? void 0 : _vm$lastSaleInfo$tota3.dailyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$tota4 = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota4 === void 0 ? void 0 : _vm$lastSaleInfo$tota4.dailyBasis) || 0) + "%")])]), _c("div", [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("周环比")]), ((_vm$lastSaleInfo$tota5 = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota5 === void 0 ? void 0 : _vm$lastSaleInfo$tota5.weeklyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$tota6 = _vm.lastSaleInfo.totalSale) === null || _vm$lastSaleInfo$tota6 === void 0 ? void 0 : _vm$lastSaleInfo$tota6.weeklyBasis) || 0) + "%")])])])])], 1), _c("div", {
    staticClass: "card-title borderTB",
    attrs: {
      flex: ""
    }
  }, [_c("div", {
    staticClass: "middle-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("span", [_vm._v("云值守销售额")]), _c("span", {
    staticClass: "card-digit-one"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$auto = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto === void 0 ? void 0 : _vm$lastSaleInfo$auto.revenue) || 0))])]), _c("div", [_c("span", {
    staticClass: "secord-title"
  }, [_vm._v("店均销售额 "), _c("span", {
    staticClass: "secord-sum"
  }, [_vm._v("¥" + _vm._s(((_vm$lastSaleInfo$auto2 = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto2 === void 0 ? void 0 : _vm$lastSaleInfo$auto2.averageRevenue) || 0))])]), _c("div", {
    attrs: {
      flex: "main:justify"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "30px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("日环比")]), ((_vm$lastSaleInfo$auto3 = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto3 === void 0 ? void 0 : _vm$lastSaleInfo$auto3.dailyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$auto4 = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto4 === void 0 ? void 0 : _vm$lastSaleInfo$auto4.dailyBasis) || 0) + "%")])]), _c("div", [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("周环比")]), ((_vm$lastSaleInfo$auto5 = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto5 === void 0 ? void 0 : _vm$lastSaleInfo$auto5.weeklyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$auto6 = _vm.lastSaleInfo.autoDutySale) === null || _vm$lastSaleInfo$auto6 === void 0 ? void 0 : _vm$lastSaleInfo$auto6.weeklyBasis) || 0) + "%")])])])])]), _c("div", {
    staticClass: "card-title",
    attrs: {
      flex: ""
    }
  }, [_c("div", {
    staticClass: "middle-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("span", [_vm._v("有人值守销售额")]), _c("span", {
    staticClass: "card-digit-one"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$busi = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi === void 0 ? void 0 : _vm$lastSaleInfo$busi.revenue) || 0))])]), _c("div", [_c("span", {
    staticClass: "secord-title"
  }, [_vm._v("店均销售额 "), _c("span", {
    staticClass: "secord-sum"
  }, [_vm._v("¥" + _vm._s(((_vm$lastSaleInfo$busi2 = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi2 === void 0 ? void 0 : _vm$lastSaleInfo$busi2.averageRevenue) || 0))])]), _c("div", {
    attrs: {
      flex: "main:justify"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "30px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("日环比")]), ((_vm$lastSaleInfo$busi3 = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi3 === void 0 ? void 0 : _vm$lastSaleInfo$busi3.dailyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$busi4 = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi4 === void 0 ? void 0 : _vm$lastSaleInfo$busi4.dailyBasis) || 0) + "%")])]), _c("div", [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("周环比")]), ((_vm$lastSaleInfo$busi5 = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi5 === void 0 ? void 0 : _vm$lastSaleInfo$busi5.weeklyBasis) >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(((_vm$lastSaleInfo$busi6 = _vm.lastSaleInfo.businessSale) === null || _vm$lastSaleInfo$busi6 === void 0 ? void 0 : _vm$lastSaleInfo$busi6.weeklyBasis) || 0) + "%")])])])])])])]), _c("el-col", {
    attrs: {
      span: 7
    }
  }, [_c("div", {
    staticClass: "box-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "card-title",
    attrs: {
      flex: "main:justify"
    }
  }, [_c("span", {
    staticClass: "all-title"
  }, [_vm._v("昨日支付笔数")]), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.paymentOrdersTip)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "card-digit"
  }, [_vm._v(_vm._s(_vm.storeOverview.lastPayCount || 0))]), _c("div", {
    staticClass: "borderTB secord-title"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("云值守")]), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(_vm.storeOverview.lastAutoDutyPayCount || 0) + " "), _c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("(" + _vm._s(_vm.storeOverview.lastAutoDutyPayCountPercent || 0) + "%)")])])]), _c("div", {
    staticClass: "secord-title"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("有人值守")]), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(_vm.storeOverview.lastBusinessPayCount || 0) + " "), _c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("(" + _vm._s(_vm.storeOverview.lastBusinessPayCountPercent || 0) + "%)")])])])]), _c("div", {
    staticClass: "box-card"
  }, [_c("el-tooltip", {
    staticClass: "tooltipPost",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.lastRevenueIncreasedPercent)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })]), _c("div", {
    staticStyle: {
      "justify-content": "space-around"
    },
    attrs: {
      flex: "dir:left"
    }
  }, [_c("div", {
    attrs: {
      flex: "dir:top main:center"
    }
  }, [_c("span", {
    staticClass: "card-title lh30px",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v("昨日营业额上升门店比例")]), _c("span", {
    staticClass: "card-digit-one",
    staticStyle: {
      color: "red"
    },
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v(_vm._s(_vm.storeOverview.lastRevenueIncreasedPercent || 0) + "%")])]), _c("div", {
    attrs: {
      flex: "dir:top main:center"
    }
  }, [_c("span", {
    staticClass: "card-title lh30px",
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v("昨日营业额下降门店比例")]), _c("span", {
    staticClass: "card-digit-one",
    staticStyle: {
      color: "green"
    },
    attrs: {
      flex: "main:center"
    }
  }, [_vm._v(_vm._s(_vm.storeOverview.lastRevenueDecreasedPercent || 0) + "%")])])])], 1)])], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("storeTotalSaleInfo", {
    staticClass: "col"
  })], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("storeRealTimeOrders", {
    staticClass: "col"
  })], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("productRanking", {
    staticClass: "col"
  })], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      xs: 24,
      md: 20,
      xl: 16
    }
  }, [_c("storeDistribution", {
    staticClass: "col"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };