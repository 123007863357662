var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("d2-page-cover", [_c("d2-icon-svg", {
    staticClass: "logo",
    attrs: {
      name: "d2-admin"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };