var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-page-cover"
  }, [_vm._m(0), _vm._t("footer")], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-page-cover__logo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/logo@2x.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };