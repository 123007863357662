import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.loading,
      expression: "loading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    staticClass: "page"
  }, [_vm.isShowStoreDetail ? _c("div", [_c("storeDetail", {
    ref: "storeDetail",
    attrs: {
      merchantNumber: _vm.detailInfo.merchantNumber,
      isHeadQuarters: _vm.isHeadQuarters,
      qrCodeList: _vm.qrCodeList,
      parentComp: "businessman",
      storeOption: _vm.storeOption
    },
    on: {
      getStoreDetail: _vm.getStoreDetail,
      parentBack: _vm.parentBack
    }
  })], 1) : _vm._e(), _c("el-form", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowStoreDetail,
      expression: "!isShowStoreDetail"
    }],
    ref: "ruleForm",
    staticClass: "demo-detailInfo-inline",
    attrs: {
      model: _vm.detailInfo,
      rules: _vm.rules,
      inline: true,
      "label-width": "100px",
      "label-position": "top",
      size: "medium"
    }
  }, [_c("el-card", {
    staticClass: "box-card title"
  }, [_vm._v(_vm._s(_vm.editText[_vm.option]) + "商户")]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("el-row", {
    staticClass: "gj-flex-wrap"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户来源:",
      prop: "agentType"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.disabled,
      placeholder: !_vm.disabled ? "请选择商户来源" : _vm.placeholderNull,
      clearable: ""
    },
    on: {
      clear: _vm.onAgentTypeClear
    },
    model: {
      value: _vm.detailInfo.agentType,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "agentType", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.agentType"
    }
  }, _vm._l(_vm.busSourceList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1)], 1), !_vm.disabled ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户归属:",
      prop: "agentNumber"
    }
  }, [_c("agentForm", {
    attrs: {
      disabled: _vm.disabled,
      sourceType: _vm.detailInfo.agentType,
      placeholder: !_vm.disabled ? "请选择商户归属" : _vm.placeholderNull
    },
    model: {
      value: _vm.detailInfo.agentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "agentNumber", $$v);
      },
      expression: "detailInfo.agentNumber"
    }
  })], 1)], 1) : _vm._e(), _vm.disabled ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户归属:",
      prop: "agentNumber"
    }
  }, [_c("agentForm", {
    attrs: {
      disabled: _vm.disabled,
      sourceType: _vm.detailInfo.agentType,
      placeholder: !_vm.disabled ? "请选择商户归属" : _vm.placeholderNull
    },
    model: {
      value: _vm.detailInfo.agentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "agentNumber", $$v);
      },
      expression: "detailInfo.agentNumber"
    }
  })], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm.detailInfo.agentNumber ? _c("el-form-item", {
    attrs: {
      label: "商户代理商套餐:",
      prop: !_vm.disabled ? "mealId" : ""
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: !_vm.disabled ? "请选择商户代理商套餐" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.mealId,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "mealId", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.mealId"
    }
  }, _vm._l(_vm.mealIdList, function (i) {
    return _c("el-option", {
      key: i.id,
      attrs: {
        label: i.name,
        value: i.id
      }
    });
  }), 1)], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm.detailInfo.mealId ? _c("el-form-item", {
    attrs: {
      label: "代理商套餐时限价格列表:",
      prop: !_vm.disabled ? "timeLimit" : ""
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: !_vm.disabled ? "请选择代理商套餐时限价格列表" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.timeLimit,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "timeLimit", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.timeLimit"
    }
  }, _vm._l(_vm.timeLimitList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: "".concat(i.timeLimit, " ").concat(i.timeLimitName, " ").concat(i.price, "(\u5143)"),
        value: i.timeLimit
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1)], 1), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("商户信息")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-row", {
    staticClass: "gj-flex-wrap"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户营业执照名称:",
      prop: "businessLicense"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "40",
      placeholder: !_vm.disabled ? "商户营业执照名称" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.businessLicense,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "businessLicense", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.businessLicense"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "负责人名称:",
      prop: "managerName"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      placeholder: !_vm.disabled ? "请输入负责人名称" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.managerName = _vm.validSe(e);
      }
    },
    model: {
      value: _vm.detailInfo.managerName,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "managerName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.managerName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "负责人联系电话:",
      prop: "managerPhone"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: !_vm.disabled ? "请输入负责人联系电话" : _vm.placeholderNull,
      maxlength: "11",
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.managerPhone = _vm.validtoNumber(e);
      }
    },
    model: {
      value: _vm.detailInfo.managerPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "managerPhone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.managerPhone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "密码:"
    }
  }, [_c("i", {
    staticClass: "el-icon-warning",
    staticStyle: {
      color: "#e6a23c"
    }
  }), _vm._v(" 默认密码为手机后6位 ")])], 1), _c("el-col", {
    attrs: {
      span: 6,
      required: ""
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户所在省市区: ",
      error: _vm.adressListError
    }
  }, [_c("city", {
    ref: "citys",
    attrs: {
      list: _vm.adressList,
      adressListError: _vm.adressListError,
      disabled: _vm.disabled
    },
    on: {
      "update:list": function updateList($event) {
        _vm.adressList = $event;
      },
      "update:adressListError": function updateAdressListError($event) {
        _vm.adressListError = $event;
      },
      "update:adress-list-error": function updateAdressListError($event) {
        _vm.adressListError = $event;
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "详细地址:",
      prop: "address"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "200",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入商户公司注册地址" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.address = _vm.validSe(e);
      }
    },
    model: {
      value: _vm.detailInfo.address,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "address", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.address"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户类型:",
      prop: "merchantType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: !_vm.disabled ? "请选择商户类型" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.merchantType,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "merchantType", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.merchantType"
    }
  }, _vm._l(_vm.merchantTypeList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "可开店数量:",
      prop: "maxStoreCount"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: 9,
      clearable: "",
      placeholder: !_vm.disabled ? "请输入可开店数量" : "",
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.maxStoreCount = _vm.validtoNumber(e);
      }
    },
    model: {
      value: _vm.detailInfo.maxStoreCount,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "maxStoreCount", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.maxStoreCount"
    }
  })], 1)], 1), _vm.unmicro.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照号:",
      prop: "businessLicenseNumber"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入营业执照号" : "",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.businessLicenseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "businessLicenseNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.businessLicenseNumber"
    }
  })], 1)], 1) : _vm._e(), _vm.unmicro.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照开始时间:",
      prop: "businessLicenseStartTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: !_vm.disabled ? "请选择营业执照开始时间" : _vm.placeholderNull,
      "value-format": "yyyy-MM-dd",
      type: "date",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.detailInfo.businessLicenseStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "businessLicenseStartTime", $$v);
      },
      expression: "detailInfo.businessLicenseStartTime"
    }
  })], 1)], 1) : _vm._e(), _vm.unmicro.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照结束时间:",
      prop: "businessLicenseEndTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled,
      "picker-options": _vm.pickerOptions,
      placeholder: !_vm.disabled ? "请选择营业执照结束时间" : _vm.placeholderNull,
      "value-format": "yyyy-MM-dd",
      type: "date",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.detailInfo.businessLicenseEndTime,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "businessLicenseEndTime", $$v);
      },
      expression: "detailInfo.businessLicenseEndTime"
    }
  })], 1)], 1) : _vm._e(), _vm.allType.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人证件类型:",
      prop: "legalManCardType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: !_vm.disabled ? "请选择法人证件类型" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    on: {
      change: _vm.legalManCardTypeChange
    },
    model: {
      value: _vm.detailInfo.legalManCardType,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "legalManCardType", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.legalManCardType"
    }
  }, _vm._l(_vm.legalManCardTypeList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _vm.allType.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人证件名字:",
      prop: "legalManName"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入法人证件名字" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.legalManName = _vm.validSe(e);
      }
    },
    model: {
      value: _vm.detailInfo.legalManName,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "legalManName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.legalManName"
    }
  })], 1)], 1) : _vm._e(), _vm.allType.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人证件号:",
      prop: "legalManIdCard"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: _vm.getlegLen(),
      clearable: "",
      placeholder: !_vm.disabled ? _vm.detailInfo.legalManCardType ? "请输入法人证件号" : "请先选择证件类型" : _vm.placeholderNull,
      disabled: _vm.disabled || !_vm.detailInfo.legalManCardType
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.legalManIdCard = _vm.validEngNum(e);
      }
    },
    model: {
      value: _vm.detailInfo.legalManIdCard,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "legalManIdCard", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.legalManIdCard"
    }
  })], 1)], 1) : _vm._e(), _vm.allType.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件有效开始时间:",
      prop: "idCardStartTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: !_vm.disabled ? "请选择证件有效开始时间" : _vm.placeholderNull,
      "value-format": "yyyy-MM-dd",
      type: "date",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.detailInfo.idCardStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "idCardStartTime", $$v);
      },
      expression: "detailInfo.idCardStartTime"
    }
  })], 1)], 1) : _vm._e(), _vm.allType.includes(_vm.detailInfo.merchantType) ? _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件有效结束时间:",
      prop: "idCardEndTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled,
      "picker-options": _vm.pickerOptions,
      placeholder: !_vm.disabled ? "请选择证件有效结束时间" : _vm.placeholderNull,
      "value-format": "yyyy-MM-dd",
      type: "date",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.detailInfo.idCardEndTime,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "idCardEndTime", $$v);
      },
      expression: "detailInfo.idCardEndTime"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("分账方式")]), _c("el-tag", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("i", {
    staticClass: "el-icon-warning",
    staticStyle: {
      color: "#409EFF"
    }
  }), _vm._v(" 确认分账方式，资金将在单笔订单支付完成后直接在支付平台完成分账动作。")])], 1), _c("div", {
    staticClass: "text item"
  }, [_c("el-row", {
    staticClass: "gj-flex-wrap"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分账比例:",
      prop: "sharePercent"
    }
  }, [_c("div", {
    attrs: {
      flex: ""
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "10",
      placeholder: !_vm.disabled ? "请输入分账比例" : _vm.placeholderNull,
      disabled: _vm.disabled,
      type: "number",
      step: "0.01"
    },
    model: {
      value: _vm.detailInfo.sharePercent,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "sharePercent", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.sharePercent"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v(" %")])], 1)])], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付手续费比例:",
      prop: "channelServiceCharge"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "4",
      placeholder: !_vm.disabled ? "请输入支付手续费比例" : _vm.placeholderNull,
      clearable: "",
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.channelServiceCharge = _vm.validtoFixed(e);
      }
    },
    model: {
      value: _vm.detailInfo.channelServiceCharge,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "channelServiceCharge", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.channelServiceCharge"
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("%")])], 1)])], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户支行:",
      prop: "depositBank"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入开户支行" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.depositBank = _vm.validSe(e);
      }
    },
    model: {
      value: _vm.detailInfo.depositBank,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "depositBank", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.depositBank"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付商户号:",
      prop: "paymentMerchantNumber"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入支付商户号" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.paymentMerchantNumber = _vm.validtoNumber(e);
      }
    },
    model: {
      value: _vm.detailInfo.paymentMerchantNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "paymentMerchantNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.paymentMerchantNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款人名称:",
      prop: "paymentReceiveUser"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入收款人名称" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.paymentReceiveUser = _vm.validSe(e);
      }
    },
    model: {
      value: _vm.detailInfo.paymentReceiveUser,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "paymentReceiveUser", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.paymentReceiveUser"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户收款账户:",
      prop: "paymentCashAccount"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "30",
      clearable: "",
      placeholder: !_vm.disabled ? "请输入商户收款账户" : "",
      disabled: _vm.disabled
    },
    on: {
      input: function input(e) {
        return _vm.detailInfo.paymentCashAccount = _vm.validtoNumber(e);
      }
    },
    model: {
      value: _vm.detailInfo.paymentCashAccount,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "paymentCashAccount", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.paymentCashAccount"
    }
  })], 1)], 1)], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("证件信息 (图片不超过2M)")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-row", {
    staticClass: "gj-flex-wrap"
  }, [_c("el-col", {
    attrs: {
      span: 7
    }
  }, [_c("el-form-item", {
    staticClass: "picForm",
    attrs: {
      label: "上传营业执照:",
      required: "",
      error: _vm.businessLicenseError
    }
  }, [!_vm.disabled ? _c("p", {
    staticClass: "tip",
    attrs: {
      slot: "label"
    },
    on: {
      click: function click($event) {
        return _vm.lookEgPic([_vm.egPicture.businessLicensePicture]);
      }
    },
    slot: "label"
  }, [_vm._v(" 上传营业执照 "), _c("span", {
    staticClass: "eg"
  }, [_vm._v("营业执照示例图")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-zoom-in"
  })]) : _vm._e()]), _c("upLoad", {
    ref: "businessLicensePicture",
    style: {
      marginTop: _vm.businessLicenseError ? "10px" : ""
    },
    attrs: {
      disabled: _vm.disabled,
      fileListUpload: _vm.businessLicensePicture,
      limit: 1,
      title: "营业执照"
    },
    on: {
      "update:fileListUpload": function updateFileListUpload($event) {
        _vm.businessLicensePicture = $event;
      },
      "update:file-list-upload": function updateFileListUpload($event) {
        _vm.businessLicensePicture = $event;
      }
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    staticClass: "picForm",
    attrs: {
      label: "上传法人身份证:",
      required: "",
      error: _vm.idCardFrontError
    }
  }, [!_vm.disabled ? _c("p", {
    staticClass: "tip",
    attrs: {
      slot: "label"
    },
    on: {
      click: function click($event) {
        return _vm.lookEgPic([_vm.egPicture.idCardFrontPicture, _vm.egPicture.idCardBackPicture]);
      }
    },
    slot: "label"
  }, [_vm._v("上传法人身份证 "), _c("span", {
    staticClass: "eg"
  }, [_vm._v("法人身份证示意图")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-zoom-in"
  })]) : _vm._e()]), _c("div", {
    staticStyle: {
      display: "flex"
    },
    style: {
      marginTop: _vm.idCardFrontError ? "10px" : ""
    }
  }, [_c("upLoad", {
    ref: "idCardFrontPicture",
    attrs: {
      disabled: _vm.disabled,
      fileListUpload: _vm.idCardFrontPicture,
      limit: 1,
      title: "身份证人像面"
    },
    on: {
      "update:fileListUpload": function updateFileListUpload($event) {
        _vm.idCardFrontPicture = $event;
      },
      "update:file-list-upload": function updateFileListUpload($event) {
        _vm.idCardFrontPicture = $event;
      }
    }
  }), _c("upLoad", {
    ref: "idCardBackPicture",
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      disabled: _vm.disabled,
      fileListUpload: _vm.idCardBackPicture,
      limit: 1,
      title: "身份证国徽面"
    },
    on: {
      "update:fileListUpload": function updateFileListUpload($event) {
        _vm.idCardBackPicture = $event;
      },
      "update:file-list-upload": function updateFileListUpload($event) {
        _vm.idCardBackPicture = $event;
      }
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 7
    }
  }, [_c("el-form-item", {
    staticClass: "picForm",
    attrs: {
      label: "上传银行卡照片:",
      required: "",
      error: _vm.bankCardError
    }
  }, [!_vm.disabled ? _c("p", {
    staticClass: "tip",
    attrs: {
      slot: "label"
    },
    on: {
      click: function click($event) {
        return _vm.lookEgPic([_vm.egPicture.bankCardPicture]);
      }
    },
    slot: "label"
  }, [_vm._v("上传银行卡照片 "), _c("span", {
    staticClass: "eg"
  }, [_vm._v("银行卡照片示例图")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-zoom-in"
  })]) : _vm._e()]), _c("upLoad", {
    ref: "bankCardPicture",
    style: {
      marginTop: _vm.bankCardError ? "10px" : ""
    },
    attrs: {
      disabled: _vm.disabled,
      fileListUpload: _vm.bankCardPicture,
      limit: 1,
      title: "银行卡照片"
    },
    on: {
      "update:fileListUpload": function updateFileListUpload($event) {
        _vm.bankCardPicture = $event;
      },
      "update:file-list-upload": function updateFileListUpload($event) {
        _vm.bankCardPicture = $event;
      }
    }
  }), !_vm.disabled ? _c("span", {
    staticStyle: {
      "line-height": "36px",
      "font-size": "14px"
    }
  }, [_vm._v("(对公有银行卡上传银行卡图片，无上传开户许可证照片)")]) : _vm._e()], 1)], 1)], 1)]), _vm.option !== "add" ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "f-c-be",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("门店管理 ")]), !_vm.disabled ? _c("i", {
    staticClass: "el-icon-circle-plus",
    staticStyle: {
      "font-size": "40px",
      color: "#409EFF"
    },
    on: {
      click: function click($event) {
        return _vm.handleStore("add", {});
      }
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "text item"
  }, [_c("el-table", {
    ref: "myTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      data: _vm.detailInfo.storeList,
      "max-height": 300
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "storeNumber",
      label: "门店编码",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "storeName",
      label: "门店名称",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "managerName",
      label: "门店负责人名称",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "managerPhone",
      label: "门店负责人手机",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "entranceTime",
      label: "门店所在省市区",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_vm._v(_vm._s("".concat(row.province, "/").concat(row.city, "/").concat(row.region)))])];
      }
    }], null, false, 1828849460)
  }), _c("el-table-column", {
    attrs: {
      prop: "address",
      "show-overflow-tooltip": "",
      label: "门店详细地址",
      width: "180"
    }
  }), !_vm.disabled ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row,
          $index = _ref2.$index;
        return !_vm.disabled ? [_c("el-button", {
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.handleStore("edit", row, $index);
            }
          }
        }, [_vm._v("编辑")]), _c("el-popconfirm", {
          ref: "popover-".concat($index),
          attrs: {
            "confirm-button-text": "确认",
            "cancel-button-text": "取消",
            icon: "el-icon-info",
            "icon-color": "red",
            title: "是否确认删除?确认后将会直接从数据库删除"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteStore(row, $index);
            }
          }
        }, [_c("el-button", {
          staticStyle: {
            "margin-left": "20px"
          },
          attrs: {
            slot: "reference",
            type: "text",
            size: "medium"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)] : undefined;
      }
    }], null, true)
  }) : _vm._e()], 1)], 1)]) : _vm._e(), _c("el-card", {
    staticClass: "box-card submit-class"
  }, [_c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "商户状态设置: ",
      required: ""
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "15px"
    },
    attrs: {
      placeholder: !_vm.disabled ? "请选择商户状态设置" : _vm.placeholderNull,
      disabled: _vm.disabled
    },
    model: {
      value: _vm.detailInfo.status,
      callback: function callback($$v) {
        _vm.$set(_vm.detailInfo, "status", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "detailInfo.status"
    }
  }, _vm._l(_vm.statusList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.onCannel
    }
  }, [_vm._v(_vm._s(!_vm.disabled ? "取消" : "返回"))]), !_vm.disabled ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.picDialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.picDialogVisible = $event;
      }
    }
  }, [_vm.dialogImageUrlList.length ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "flex-direction": "column"
    }
  }, _vm._l(_vm.dialogImageUrlList, function (i) {
    return _c("img", {
      key: i,
      staticStyle: {
        "margin-bottom": "10px"
      },
      attrs: {
        width: "400px",
        src: i,
        alt: ""
      }
    });
  }), 0) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };