var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "title-main"
  }, [_vm._v("门店区域分布")]), _c("el-tooltip", {
    staticStyle: {
      color: "#797979ad",
      "font-size": "19px",
      "margin-left": "10px"
    },
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.storeAreas)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("el-radio-group", {
    on: {
      change: _vm.getStoreCity
    },
    model: {
      value: _vm.source,
      callback: function callback($$v) {
        _vm.source = $$v;
      },
      expression: "source"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "all"
    }
  }, [_vm._v("全部")]), _c("el-radio-button", {
    attrs: {
      label: "agent"
    }
  }, [_vm._v("代理商")]), _c("el-radio-button", {
    attrs: {
      label: "direct_sale"
    }
  }, [_vm._v("直营")])], 1), _c("echartsMain", {
    attrs: {
      chartData: _vm.chartData
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };