import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    start: {
      type: String,
      default: ''
    },
    timeoutMinutes: {
      type: [String, Number],
      default: 10
    }
  },
  data: function data() {
    return {
      time: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.conutSecond();
    this.setIntervalTimer = setInterval(function (x) {
      _this.conutSecond();
    }, 1000 * 60);
  },
  methods: {
    conutSecond: function conutSecond() {
      var start = this.start;
      if (!start) return;
      var now = this.$dayjs().format('YYYY-MM-DD HH:mm');
      console.log('nownownownownownownownownownownownownownow', now);
      this.time = (this.$dayjs(now).diff(this.$dayjs(start), 'minute') || 0) <= 0 ? 0 : this.$dayjs(now).diff(this.$dayjs(start), 'minute') || 0;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.setIntervalTimer && clearInterval(this.setIntervalTimer);
    this.setIntervalTimer = null;
  }
};