var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.showVideo ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.vidoeLoading,
      expression: "vidoeLoading"
    }],
    staticClass: "video-card"
  }, [_vm.isStatus ? _c("div", {
    staticClass: "serialNumber"
  }, [!_vm.isTaskList ? _c("span", [_vm._v(" " + _vm._s(_vm.videoInfo.serialNumber) + " ")]) : _vm._e(), _vm._v(" "), _c("el-tag", {
    attrs: {
      type: _vm.videoInfo.deviceOnlineStatus ? "success" : "danger",
      size: "small",
      effect: "light"
    }
  }, [_c("span", {
    staticClass: "point",
    style: {
      background: _vm.videoInfo.deviceOnlineStatus ? "#67c23a" : "#f56c6c"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.videoInfo.deviceOnlineStatus ? "在线" : "离线"))])])], 1) : _vm._e(), _vm.messagError ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.messagError && _vm.loading,
      expression: "messagError && loading"
    }],
    staticClass: "error-mask",
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      "element-loading-text": _vm.messagError.msg + ",重新加载中"
    }
  }) : _vm._e(), _c("div", {
    ref: _vm.keyRef,
    staticClass: "video-container",
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      id: _vm.keyRef
    }
  }), _c("div", {
    staticClass: "zhe",
    attrs: {
      flex: "dir:top main:center cross:center"
    },
    on: {
      click: function click($event) {
        return _vm.reset();
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh-right",
    staticStyle: {
      "font-size": "20px"
    }
  })]), _c("div", {
    staticClass: "other-btn",
    attrs: {
      slot: "other-btn"
    },
    slot: "other-btn"
  }, [_vm._t("footer")], 2)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };