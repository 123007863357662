import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      inline: true
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入角色名称",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.roleName = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.roleName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.roleName"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("查询")]), _c("el-button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.onReset
    }
  }, [_vm._v("重置")]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_ROLE_SAVE",
      expression: "'GJ_VENDING_ROLE_SAVE'"
    }],
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.goDeatil("", "add");
      }
    }
  }, [_vm._v("新增")])], 1)], 1)], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "contentWrap",
    staticClass: "content-wrap"
  }, [_c("div", {
    staticClass: "table-wrap"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      stripe: "",
      data: _vm.tableData,
      height: _vm.height
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._l(_vm.tableName, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        "show-overflow-tooltip": "",
        prop: item.prop,
        label: item.label,
        width: item.width
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var row = _ref.row;
          return [item.slot == "status" ? _c("div", [_c("el-tag", {
            attrs: {
              type: row.status === "valid" ? "success" : "danger"
            }
          }, [_vm._v(" " + _vm._s(_vm.userStatus[row.status]))])], 1) : _c("div", [_vm._v(_vm._s(row[item.prop] || "-"))])];
        }
      }], null, true)
    });
  }), _vm.$isHasAuth(["GJ_VENDING_ROLE_UPDATE", "GJ_VENDING_ROLE_STATUS_UPDATE"]) ? _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_ROLE_UPDATE",
            expression: "'GJ_VENDING_ROLE_UPDATE'"
          }],
          attrs: {
            size: "medium",
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.goDeatil(row, "edit");
            }
          }
        }, [_vm._v("修改")]), _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_ROLE_STATUS_UPDATE",
            expression: "'GJ_VENDING_ROLE_STATUS_UPDATE'"
          }],
          style: {
            color: row.status === "valid" ? "#f56c6c" : "#67C23A"
          },
          attrs: {
            size: "medium",
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.updateRoleStatus(row);
            }
          }
        }, [_vm._v(_vm._s(row.status === "valid" ? "失效" : "生效"))])];
      }
    }], null, false, 2969094478)
  }) : _vm._e()], 2)], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };