import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import store from '@/store';
import vueThis from '@/main';
/**
 * 节点判断权限
 * @date 2023-07-14 14:29
 * @param {*}
 * @returns
 */
export var hasPermission = function hasPermission(userPermission) {
  // let userPermissionList = Array.isArray(userPermission) ? userPermission : [userPermission]
  // 当前用户的权限列表
  var permissionList = JSON.parse(localStorage.getItem('resourceList')) || []; // ['editBtn', 'commitBtn']
  return permissionList.includes(userPermission);
};

/**
 * js判断权限
 * @date 2023-07-14 14:29
 * @param {*}
 * @returns
 */
export var isHasAuth = function isHasAuth(auth, text) {
  if (!auth) {
    return true;
  }
  var permissionList = JSON.parse(localStorage.getItem('resourceList')) || []; // ['editBtn', 'commitBtn']
  var userPermissionList = Array.isArray(auth) ? auth : [auth];
  // 当前用户的权限列表
  if (!userPermissionList.some(function (e) {
    return permissionList.includes(e);
  })) {
    if (text) {
      vueThis.$message({
        duration: '1500',
        type: 'error',
        message: "\u6682\u65E0".concat(text, "\u6743\u9650,\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458\u6DFB\u52A0\u5594!")
      });
    }
    return false;
  }
  return true;
};