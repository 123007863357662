import _toConsumableArray from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "wbMain",
    staticClass: "page"
  }, [_c("el-collapse-transition", [_vm.unusualStr && _vm.unusualOpen && _vm.taskList.length > 0 ? _c("div", {
    staticClass: "unusual"
  }, [_c("div", {
    staticClass: "box"
  }, [_c("i", {
    staticClass: "el-icon-close close",
    on: {
      click: function click($event) {
        _vm.unusualOpen = false;
      }
    }
  }), _c("div", {
    staticClass: "box-title"
  }, [_c("i", {
    staticClass: "el-icon-s-opportunity",
    staticStyle: {
      color: "#F56C6C",
      "font-size": "20px"
    }
  }), _vm._v("异常处理")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.unusualStr)
    }
  })])]) : _vm._e()]), _c("div", {
    staticClass: "page-header",
    attrs: {
      flex: "cross:center main:justify"
    }
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_WORK",
      expression: "'GJ_VENDING_WORK_TABLE_WORK'"
    }],
    attrs: {
      slot: "footer",
      loading: _vm.detailLoading || _vm.loading,
      type: _vm.customerWorkStatus === "offline" ? "primary" : "warning",
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.contorlSwitch();
      }
    },
    slot: "footer"
  }, [_vm._v(_vm._s(_vm.customerWorkStatus === "offline" ? "开始接收任务" : "结束接收任务"))]), _c("div", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_vm._v("当前处理中任务数 "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.handlingTaskCount))]), _vm.customerWorkStatus !== "offline" ? _c("span", [_vm._v(", 还有 "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.waitingTaskCount))]), _vm._v("个任务在排队中")]) : _vm._e()])], 1), _vm.taskList.length > 0 ? _c("div", [_c("el-radio-group", {
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      size: "small"
    },
    on: {
      change: function change($event) {
        return _vm.videoNumChange();
      }
    },
    model: {
      value: _vm.screen,
      callback: function callback($$v) {
        _vm.screen = $$v;
      },
      expression: "screen"
    }
  }, _vm._l(_vm.screenList, function (i) {
    return _c("el-radio-button", {
      key: i.num,
      attrs: {
        label: i.num
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.refresh();
      }
    }
  }, [_vm._v("刷新")])], 1) : _vm._e()]), _vm.isRefresh ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "page-main"
  }, [_vm.taskList.length > 0 && _vm.isPageShow ? _c("div", {
    staticClass: "wb-list"
  }, _vm._l(_toConsumableArray(_vm.taskList), function (item, index) {
    return _c("div", {
      key: "list" + item.taskNumber,
      staticClass: "store",
      style: {
        width: _vm.width + 12 + "px"
      }
    }, [_c("div", {
      staticClass: "store-order"
    }, [_c("store-order", {
      attrs: {
        currentStore: item,
        panorama: item.panorama,
        height: _vm.height,
        screenScale: _vm.screenScale
      }
    })], 1), _c("div", {
      class: {
        "grid-box": true,
        "active-video": _vm.curentTaskNumber == item.taskNumber
      },
      style: _vm.setTagStyle(item),
      on: {
        dblclick: function dblclick($event) {
          return _vm.taggleCard(item, index);
        }
      }
    }, [_c("div", {
      staticClass: "storeName"
    }, [_vm._v(_vm._s(item.storeInfo.storeName)), _c("span", {
      staticStyle: {
        color: "red"
      }
    }, [_vm._v(" ("), _c("countDown", {
      ref: "countDown-".concat(_vm.curentTaskNumber),
      refInFor: true,
      attrs: {
        start: item.createTime,
        timeoutMinutes: _vm.timeoutMinutes
      }
    }), _vm._v("分钟 ) ")], 1)]), _c("div", {
      staticClass: "option-list"
    }, [_c("el-button", {
      directives: [{
        name: "auth",
        rawName: "v-auth",
        value: "GJ_VENDING_WORK_TABLE_OPEN_GATE",
        expression: "'GJ_VENDING_WORK_TABLE_OPEN_GATE'"
      }],
      style: {
        scale: _vm.screenScale
      },
      attrs: {
        disabled: item.storeInfo.forceClose === 1,
        type: "success",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.onHandleOpen(item.storeInfo.storeNumber);
        }
      }
    }, [_vm._v("手动开门")]), _c("el-button", {
      directives: [{
        name: "auth",
        rawName: "v-auth",
        value: "GJ_VENDING_WORK_TABLE_AUDIO_CALL",
        expression: "'GJ_VENDING_WORK_TABLE_AUDIO_CALL'"
      }],
      style: {
        scale: _vm.screenScale
      },
      attrs: {
        type: "success",
        size: "mini",
        disabled: !!_vm.talkTaggle || !["end_call", "free"].includes(_vm.onTrtcCall)
      },
      on: {
        click: function click($event) {
          _vm.taggleCard(item, index), _vm.startTrtcCall(item);
        }
      }
    }, [_vm._v("发起语音")]), _c("el-button", {
      style: {
        scale: _vm.screenScale
      },
      attrs: {
        type: "warning",
        size: "mini",
        disabled: _vm.callTaskNumber === item.taskNumber || _vm.closeTaskDisabled(item)
      },
      on: {
        click: function click($event) {
          return _vm.closeTask(item.taskNumber);
        }
      }
    }, [_vm._v("关闭任务")])], 1), item.tags.length || item.onCallStatusList.length ? _c("div", {
      staticClass: "tag-list",
      style: {
        "max-height": "".concat(_vm.height * 0.5, "px")
      }
    }, [_vm._l(item.tags, function (tag, index) {
      return _c("el-tag", {
        key: tag.tagNumber + index,
        staticClass: "tag",
        staticStyle: {
          border: "none"
        },
        attrs: {
          effect: "dark",
          color: tag.tone,
          size: "mini"
        }
      }, [_vm._v(" " + _vm._s(tag.tagName) + " ")]);
    }), _vm._l(item.onCallStatusList, function (call, index) {
      return _c("div", {
        key: index
      }, [call.onCallStatus === "calling" ? _c("img", {
        staticStyle: {
          width: "50px"
        },
        attrs: {
          src: require("../workbench/image/calling.gif"),
          alt: "",
          srcset: ""
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            _vm.taggleCard(item, item), _vm.onWsAnswer(call, item);
          }
        }
      }) : _vm._e(), call.onCallStatus === "cancel_call" ? _c("img", {
        staticStyle: {
          width: "50px",
          "margin-top": "5px"
        },
        attrs: {
          src: require("../workbench/image/cancel_call.png"),
          alt: "",
          srcset: ""
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            _vm.taggleCard(item, item), _vm.startCallUncall(call, item);
          }
        }
      }) : _vm._e()]);
    })], 2) : _vm._e(), _c("div", {
      staticClass: "video-list",
      style: {
        height: "".concat(_vm.height - 30, "px")
      }
    }, _vm._l(_vm.playVoiceList, function (voice, index) {
      return _c("playVoice", {
        key: index,
        ref: "playVoicelist".concat(voice.id, "-").concat(item.taskNumber),
        refInFor: true,
        staticClass: "playVoice",
        attrs: {
          height: _vm.height,
          voiceType: "image",
          playVoiceLen: _vm.playVoiceList.length,
          curentTaskNumber: item.taskNumber,
          cashierSnList: item.cashierSnList,
          voice: voice
        }
      });
    }), 1), item.deviceList.length && item.panorama && item.panorama.liveNormalUrl ? _c("video-card", {
      ref: "videoContainerList" + item.taskNumber,
      refInFor: true,
      attrs: {
        talkTaggle: _vm.talkTaggle,
        liveUrl: item.panorama.liveNormalUrl,
        videoInfo: item.panorama,
        ysToken: _vm.ysToken,
        index: item.taskNumber,
        keyRef: "list" + item.taskNumber,
        curentIndex: _vm.curentTaskNumber,
        width: _vm.width,
        height: _vm.height,
        isStatus: false,
        isTaskList: true
      }
    }) : _c("div", {
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px"
      },
      attrs: {
        flex: "dir:left main:center cross:center"
      }
    }, [_c("div", {
      staticClass: "wb-nodata",
      staticStyle: {
        padding: "25px"
      }
    }, [_vm._v("暂无摄像头视频数据")])])], 1)]);
  }), 0) : _vm._e(), _vm.taskList.length === 0 ? _c("div", {
    staticClass: "wb-box"
  }, [_vm._v(" 暂无待处理任务 ")]) : _vm._e()]) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "拨号",
      "destroy-on-close": true,
      visible: _vm.dialogVisibleCall,
      width: "360px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleCall = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("span", [_vm._v("呼叫设备：")]), _c("el-select", {
    staticStyle: {
      width: "240px",
      "margin-right": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.callTrtc.callCashierSn,
      callback: function callback($$v) {
        _vm.$set(_vm.callTrtc, "callCashierSn", $$v);
      },
      expression: "callTrtc.callCashierSn"
    }
  }, _vm._l(_vm.curentInfo.cashierSnList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "medium"
    },
    on: {
      click: function click($event) {
        ;
        _vm.dialogVisibleCall = false, _vm.startCall();
      }
    }
  }, [_vm._v("发起语音对讲")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };