import "core-js/modules/es.array.map.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip, echartToolbox } from '@/config/dataDictionary';
import { getDutyTendencyOrder as _getDutyTendencyOrder } from '@/api/businessSystem';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
export default {
  name: 'DutyAndOrder',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      homeTip: homeTip,
      loading: false,
      chartData: null,
      setIntervalTimer: null,
      dataList: [{
        value: 382318,
        name: 'A类'
      }, {
        value: 136470,
        name: 'B类'
      }, {
        value: 208120,
        name: 'C类'
      }]
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.setIntervalTimer);
  },
  watch: {
    search: function search(newVal) {}
  },
  created: function created() {
    this.getDutyTendencyOrder();
    this.setIntervalTimer = setInterval(this.getDutyTendencyOrder, 10 * 60 * 1000);
  },
  methods: {
    getDutyTendencyOrder: function getDutyTendencyOrder() {
      var _this = this;
      // this.loading = true
      var startTime = "".concat(dayjs().format('YYYY-MM-DD'), " 00:00:00");
      var endTime = "".concat(dayjs().format('YYYY-MM-DD HH:mm:ss'));
      _getDutyTendencyOrder({
        startTime: startTime,
        endTime: endTime
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var echartsDate = res.body;
          _this.init(echartsDate);
        }
      });
    },
    init: function init(echartsDate) {
      var xData = this.$lodash.map(echartsDate, 'date');
      var dutyCount = this.$lodash.map(echartsDate, 'dutyCount');
      var payOrderCount = this.$lodash.map(echartsDate, 'payOrderCount');
      this.chartData = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // icon: 'roundRect',
          data: ['客服任务数', '成交订单数']
        },
        dataZoom: [{
          show: true,
          realtime: true,
          start: 0,
          end: 100
        }, {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: echartToolbox,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: 'value',
          min: 0,
          minInterval: 1,
          axisLine: {
            show: true
          }
        },
        series: [{
          name: '成交订单数',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#4b65bf'
            }
          },
          data: payOrderCount
        }, {
          name: '客服任务数',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(255, 70, 131)'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgb(255, 158, 68)'
            }, {
              offset: 1,
              color: 'rgb(255, 70, 131)'
            }])
          },
          data: dutyCount
        }]
      };
    }
  }
};