var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      inline: true
    }
  }), _c("el-form", {
    ref: "form",
    attrs: {
      inline: true
    }
  }, [_c("el-form-item", [_c("el-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.onCannel
    }
  }, [_vm._v("返回")])], 1), _c("el-form-item", {
    attrs: {
      label: "值守时间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      "picker-options": _vm.pickerOptions,
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.timeDate,
      callback: function callback($$v) {
        _vm.timeDate = $$v;
      },
      expression: "timeDate"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "box-card"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("值守总次数："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.summary.dutyCount))]), _vm._v(" 次")]), _c("span", {
    staticClass: "title"
  }, [_vm._v("值守总时长："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.summary.dutyDuration))]), _vm._v(" 小时")]), _c("span", {
    staticClass: "title"
  }, [_vm._v("产生任务数："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.summary.taskCount))]), _vm._v(" 个")]), _c("span", {
    staticClass: "title"
  }, [_vm._v("产生订单量："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.summary.orderCount))]), _vm._v(" 单")]), _c("span", {
    staticClass: "title"
  }, [_vm._v("订单金额总计："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.summary.orderTotalAmount))]), _vm._v(" 元")])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "contentWrap",
    staticClass: "content-wrap"
  }, [_c("div", {
    staticClass: "table-wrap"
  }, [_c("el-table", {
    ref: "table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      stripe: "",
      data: _vm.tableData,
      "max-height": _vm.height
    },
    on: {
      "sort-change": _vm.onSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._l(_vm.tableName, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        sortable: "custom",
        "show-overflow-tooltip": "",
        prop: item.prop,
        label: item.label,
        width: item.width
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var row = _ref.row;
          return [item.slot == "startTime" ? _c("div", [_vm._v(" " + _vm._s(row.startTime) + _vm._s(row.endTime ? "-" : "") + _vm._s(row.endTime) + " ")]) : _c("div", [_vm._v(_vm._s(row[item.prop]))])];
        }
      }], null, true)
    });
  })], 2)], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };