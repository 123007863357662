var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pagination-container",
    style: {
      textAlign: _vm.textAlign
    }
  }, [_c("el-pagination", _vm._b({
    attrs: {
      background: _vm.background,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      layout: _vm.layout,
      total: _vm.total,
      "page-sizes": _vm.pageSizes
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:pageSize": function updatePageSize($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function updatePageSize($event) {
        _vm.pageSize = $event;
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  }, "el-pagination", _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };