import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "call-notify"
  }, [_c("div", {
    staticClass: "box"
  }, [!_vm.newTast.roomId ? _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.closeNewTask();
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })]) : _vm._e(), _c("div", {
    staticClass: "m-title"
  }, [_c("i", {
    staticClass: "el-icon-warning m-icon"
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.newTast.infoSource === "workList" ? "待处理通知" : _vm.newTast.tagName ? "\u3010".concat(_vm.newTast.tagName, "\u3011\u4EFB\u52A1\u5206\u914D\u901A\u77E5") : "任务分配通知"))])]), _c("div", {
    staticClass: "message",
    staticStyle: {
      color: "red"
    }
  }, [_vm.newTast.infoSource !== "workList" ? _c("div", [_c("span", [_vm._v("有新" + _vm._s(_vm.newTast.tagName ? "\u7684\u3010".concat(_vm.newTast.tagName, "\u3011") : "") + "任务分配给你了,"), _vm.newTast.roomId || _vm.newTast.onCallStatus === "cancel_call" ? _c("span", {
    staticStyle: {
      color: "#E6A23C"
    }
  }, [_vm._v("『" + _vm._s(_vm.newTast.storeName) + "』客户语音" + _vm._s(_vm.newTast.onCallStatus === "calling" ? "呼叫中" : "有未接呼叫") + ",")]) : _vm._e(), _vm._v("请马上处理!")]), !_vm.newTast.roomId || _vm.newTast.onCallStatus !== "cancel_call" ? _c("span", [_c("br")]) : _vm._e()]) : _vm._e(), _vm.newTast.infoSource === "workList" ? _c("div", [_c("span", {
    staticStyle: {
      color: "#333",
      "font-weight": "600"
    }
  }, [_vm.newTast.roomId || _vm.newTast.onCallStatus === "cancel_call" ? _c("span", [_c("span", {
    staticStyle: {
      color: "#E6A23C"
    }
  }, [_vm._v("『" + _vm._s(_vm.newTast.storeName) + "』")]), _vm._v("客户语音" + _vm._s(_vm.newTast.onCallStatus === "calling" ? "呼叫中" : "有未接呼叫") + ",")]) : _vm._e(), _vm._v("请尽快处理喔!")]), !_vm.newTast.roomId || _vm.newTast.onCallStatus !== "cancel_call" ? _c("span") : _vm._e()]) : _vm._e(), !["未接听", "新消息"].includes(_vm.btnText) ? _c("el-button", {
    staticClass: "onanswer",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.onAnswer();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.btnText))]) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };