import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm$curentInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "wbMain",
    staticClass: "page"
  }, [_c("el-collapse-transition", [_vm.unusualStr && _vm.unusualOpen && _vm.taskList.length > 0 ? _c("div", {
    staticClass: "unusual"
  }, [_c("div", {
    staticClass: "box"
  }, [_c("i", {
    staticClass: "el-icon-close close",
    on: {
      click: function click($event) {
        _vm.unusualOpen = false;
      }
    }
  }), _c("div", {
    staticClass: "box-title"
  }, [_c("i", {
    staticClass: "el-icon-s-opportunity",
    staticStyle: {
      color: "#F56C6C",
      "font-size": "20px"
    }
  }), _vm._v("异常处理")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.unusualStr)
    }
  })])]) : _vm._e()]), _c("div", {
    staticClass: "page-header",
    attrs: {
      flex: "cross:center box:justify"
    }
  }, [_c("div", [_c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_WORK",
      expression: "'GJ_VENDING_WORK_TABLE_WORK'"
    }],
    attrs: {
      slot: "footer",
      loading: _vm.detailLoading || _vm.loading,
      type: _vm.customerWorkStatus === "offline" ? "primary" : "warning",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.contorlSwitch();
      }
    },
    slot: "footer"
  }, [_vm._v(_vm._s(_vm.customerWorkStatus === "offline" ? "开始接收任务" : "结束接收任务"))])], 1), _vm.taskList.length > 0 ? _c("div", {
    staticClass: "mainControl-status",
    attrs: {
      flex: ""
    }
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.curentInfo.taskNumber))]), _c("span", {
    staticStyle: {
      color: "#F56C6C",
      "margin-left": "10px",
      display: "block",
      width: "120px"
    }
  }, [_vm._v(_vm._s(_vm.entranceDuration < 0 ? 0 : _vm.entranceDuration) + "分钟前进店 ")])]), _vm._l(_vm.curentInfo.mainControlStatusObj, function (val, key, index) {
    return _c("div", {
      key: index,
      attrs: {
        flex: "cross:center"
      }
    }, [_c("div", {
      staticClass: "grid-content d2-ml"
    }, [_c("el-tag", {
      staticClass: "eltag",
      attrs: {
        type: "warning",
        size: "small",
        effect: "plain"
      }
    }, [_vm._v(_vm._s(_vm.curentInfo.mainControlStatus.length > 1 ? "\u95E8\u9501".concat(index + 1, "\u72B6\u6001") : "门锁状态"))]), _c("el-tag", {
      staticStyle: {
        "margin-left": "5px"
      },
      attrs: {
        type: val.gateStatus ? "danger" : "success",
        effect: "light"
      }
    }, [_vm._v(_vm._s(_vm.gateStatusListType[val.gateStatus]))]), _c("el-tag", {
      staticStyle: {
        "margin-left": "5px"
      },
      attrs: {
        type: val.lockStatus ? "danger" : "success",
        effect: "light"
      }
    }, [_vm._v(_vm._s(_vm.lockStatusListType[val.lockStatus]))])], 1), _c("div", {
      staticClass: "grid-content d2-ml"
    }, [_c("el-tag", {
      staticClass: "eltag",
      attrs: {
        type: "warning",
        size: "small",
        effect: "plain"
      }
    }, [_vm._v(_vm._s(_vm.curentInfo.mainControlStatus.length > 1 ? "\u4E3B\u63A7".concat(index + 1, "\u72B6\u6001") : "主控状态"))]), _c("el-tag", {
      staticStyle: {
        margin: "0 5px"
      },
      attrs: {
        type: val.onlineStatus ? "success" : "danger",
        effect: "light"
      }
    }, [_c("span", {
      staticClass: "point",
      style: {
        background: val.onlineStatus ? "#67c23a" : "#f56c6c"
      }
    }), _c("span", [_vm._v(_vm._s(_vm.mainControlStatusType[val.onlineStatus]))])])], 1)]);
  })], 2) : _vm._e(), _vm.taskList.length > 0 ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    attrs: {
      flex: "cross:center"
    }
  }, [_c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_FORCE_CLOSE",
      expression: "'GJ_VENDING_WORK_TABLE_FORCE_CLOSE'"
    }],
    attrs: {
      type: "danger",
      size: "medium",
      loading: _vm.forceCloseLoading,
      disabled: ((_vm$curentInfo = _vm.curentInfo) === null || _vm$curentInfo === void 0 || (_vm$curentInfo = _vm$curentInfo.storeInfo) === null || _vm$curentInfo === void 0 ? void 0 : _vm$curentInfo.dutyStatus) === "normal_business"
    },
    on: {
      click: function click($event) {
        return _vm.forceCloseGate(_vm.curentTaskNumber, _vm.curentInfo.storeInfo.forceClose);
      }
    }
  }, [_vm._v(_vm._s(_vm.curentInfo.storeInfo.forceClose === 1 ? "解除强制关锁" : "强制关锁"))]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_AUDIO_CALL",
      expression: "'GJ_VENDING_WORK_TABLE_AUDIO_CALL'"
    }],
    attrs: {
      type: "success",
      size: "medium",
      disabled: !!_vm.talkTaggle || !["end_call", "free"].includes(_vm.onTrtcCall)
    },
    on: {
      click: function click($event) {
        return _vm.startTrtcCall();
      }
    }
  }, [_vm._v("发起语音通话")]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_STORE_PASSWORD",
      expression: "'GJ_VENDING_WORK_TABLE_STORE_PASSWORD'"
    }],
    attrs: {
      type: "warning",
      size: "medium"
    },
    on: {
      click: _vm.dynamicPassword
    }
  }, [_vm._v("密码锁动态密码")]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_WORK_TABLE_OPEN_GATE",
      expression: "'GJ_VENDING_WORK_TABLE_OPEN_GATE'"
    }],
    attrs: {
      disabled: _vm.curentInfo.storeInfo.forceClose === 1,
      type: "warning",
      size: "medium"
    },
    on: {
      click: _vm.onHandleOpen
    }
  }, [_vm._v("手动开门")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "medium",
      disabled: !!this.talkTaggle || !["end_call", "free"].includes(_vm.onTrtcCall)
    },
    on: {
      click: function click($event) {
        return _vm.closeTask(_vm.curentInfo.taskNumber);
      }
    }
  }, [_vm._v("关闭任务")]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "店内无人时,需手动结束任务",
      placement: "top-start"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    staticStyle: {
      color: "#E6A23C",
      "font-size": "20px",
      display: "block",
      "margin-left": "10px"
    }
  })])], 1) : _vm._e()]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticClass: "page-main"
  }, [_vm.taskList.length > 0 && _vm.isPageShow ? _c("div", {
    staticClass: "wb-list"
  }, _vm._l(_vm.taskList, function (item, index) {
    return _c("div", {
      key: "list" + item.taskNumber,
      class: {
        "grid-box": true,
        "active-video": _vm.curentTaskNumber == item.taskNumber
      },
      on: {
        click: function click($event) {
          return _vm.taggleCard(item, index);
        }
      }
    }, [_c("div", {
      staticStyle: {
        margin: "10px 0"
      }
    }, [_vm._v(_vm._s(item.storeInfo.storeName))]), item.deviceList.length && item.panorama && item.panorama.liveNormalUrl ? _c("video-card", {
      ref: "videoContainerList" + index,
      refInFor: true,
      attrs: {
        talkTaggle: _vm.talkTaggle,
        liveUrl: item.panorama.liveNormalUrl,
        videoInfo: item.panorama,
        ysToken: _vm.ysToken,
        index: item.taskNumber,
        keyRef: "list" + item.taskNumber,
        curentIndex: _vm.curentTaskNumber,
        width: 240,
        height: 180,
        isStatus: false
      }
    }) : _c("div", [_c("div", {
      staticClass: "wb-nodata",
      staticStyle: {
        padding: "25px"
      }
    }, [_vm._v("暂无全景摄像头视频数据")])])], 1);
  }), 0) : _vm._e(), _vm.taskList.length > 0 && _vm.isPageShow ? _c("div", {
    staticClass: "wb-box"
  }, [_vm.isTaggleCard ? _c("div", {
    staticClass: "play-voiceList"
  }, [_c("div", {
    staticStyle: {
      color: "#F56C6C",
      "word-break": "keep-all"
    }
  }, [_vm._v("快捷播报：")]), _vm._l(_vm.playVoiceList, function (voice, index) {
    return _c("playVoice", {
      key: index,
      attrs: {
        curentTaskNumber: _vm.curentTaskNumber,
        cashierSnList: _vm.curentInfo.cashierSnList,
        voice: voice
      }
    });
  }), !_vm.playVoiceList.length ? _c("span", [_vm._v("暂无可播放语音列表")]) : _vm._e()], 2) : _vm._e(), _c("div", {
    staticClass: "wb-box-main"
  }, [_c("draggable", {
    staticClass: "wb-box-main"
  }, _vm._l(_vm.curentInfo.cameraListEnum, function (item, key, index) {
    return _c("div", {
      key: "main" + item.serialNumber
    }, [item.liveUrl && item.type === "camera" ? _c("video-card", {
      ref: "main" + item.serialNumber,
      refInFor: true,
      staticStyle: {
        margin: "10px"
      },
      attrs: {
        liveUrl: item.liveNormalUrl,
        videoInfo: item,
        index: index,
        ysToken: _vm.ysToken,
        keyRef: "main" + item.serialNumber,
        curentIndex: "999",
        height: _vm.height,
        width: _vm.width,
        isStatus: true
      },
      on: {
        closeVideo: _vm.closeVideo,
        handleError: _vm.handleError
      },
      scopedSlots: _vm._u([{
        key: "footer",
        fn: function fn() {
          return [_c("el-button", {
            staticClass: "ezopenStartTalk",
            attrs: {
              type: "primary",
              size: "small",
              disabled: _vm.talkDisabled("main" + item.serialNumber)
            },
            on: {
              click: function click($event) {
                return _vm.ezopenStartTalk("main" + item.serialNumber);
              }
            }
          }, [_vm._v(" " + _vm._s("main" + item.serialNumber === _vm.talkTaggle ? "对讲中" : "发起对讲") + " ")])];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()], 1);
  }), 0), !_vm.curentInfo.cameraListArr.length ? _c("div", {
    staticClass: "d2-m"
  }, [_vm._v("暂无视频")]) : _vm._e()], 1), _vm.talkTaggle !== null ? _c("div", {
    staticClass: "wb-video"
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      "margin-right": "20px"
    },
    attrs: {
      src: require("./image/video-icon.png")
    }
  }), _vm.talkTaggle !== null ? _c("sound-wave", {
    ref: "soundWave"
  }) : _vm._e(), _c("el-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      slot: "footer",
      type: "primary",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.ezopenStopTalk();
      }
    },
    slot: "footer"
  }, [_vm._v("结束对讲")])], 1) : _vm._e()]) : _vm._e(), _vm.taskList.length === 0 ? _c("div", {
    staticClass: "wb-box"
  }, [_vm._v(" 暂无待处理任务 ")]) : _vm._e(), _vm.taskList.length > 0 && _vm.isPageShow ? _c("div", {
    staticClass: "wb-list wb-store"
  }, [_c("storeInfoCard", {
    attrs: {
      curentInfo: _vm.curentInfo
    },
    on: {
      getTaskDetail: _vm.getTaskDetail
    }
  })], 1) : _vm._e()]), _c("el-dialog", {
    attrs: {
      title: "拨号",
      "destroy-on-close": true,
      visible: _vm.dialogVisibleCall,
      width: "360px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleCall = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("span", [_vm._v("呼叫设备：")]), _c("el-select", {
    staticStyle: {
      width: "240px",
      "margin-right": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.curentInfo.cashierSn,
      callback: function callback($$v) {
        _vm.$set(_vm.curentInfo, "cashierSn", $$v);
      },
      expression: "curentInfo.cashierSn"
    }
  }, _vm._l(_vm.curentInfo.cashierSnList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "medium"
    },
    on: {
      click: function click($event) {
        ;
        _vm.dialogVisibleCall = false, _vm.startCall();
      }
    }
  }, [_vm._v("发起语音对讲")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };