import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
// import { joinCall } from '@/api/businessSystem'
import { mapActions } from 'vuex';
export default {
  props: {
    newTast: Object
  },
  data: function data() {
    return _defineProperty({
      reportDuration: 20,
      second: 20
    }, 'setIntervalTimer' + this.newTast.storeNumber, null);
  },
  computed: {
    /**
     * 1.非工作台都显示【去处理】
     * 2.工作台 有roomId 说明存在过通话 未接听的 和接听的
     * @author wangxx
     * @date 2023-06-06 15:34
     * @param {*}
     * @returns
     */
    btnText: function btnText() {
      if (this.$route.name !== 'taskManagement-workbench') {
        return '前去处理';
      } else {
        if (this.newTast.roomId) {
          return '接听';
        } else {
          return this.newTast.onCallStatus === 'cancel_call' ? '未接听' : this.newTast.onCallStatus === 'calling' ? '接听' : '未接听';
        }
      }
    }
  },
  watch: {
    'newTast.roomId': function newTastRoomId(newValue) {
      this.countDown();
    }
  },
  beforeDestroy: function beforeDestroy() {
    var intervalTimer = 'setIntervalTimer' + this.newTast.storeNumber;
    this[intervalTimer] && clearInterval(this[intervalTimer]);
  },
  mounted: function mounted() {
    this.countDown();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('d2admin/websocket', ['setTaskResident', 'setNewTask', 'setIsOnline', 'setNewNotifyList'])), mapActions('d2admin/user', ['handleTrtcCall', 'setCallCurentInfo'])), {}, {
    countDown: function countDown() {
      var _this = this;
      var intervalTimer = 'setIntervalTimer' + this.newTast.storeNumber;
      if (!this.newTast.roomId) {
        this[intervalTimer] && clearInterval(this[intervalTimer]);
        this.reportDuration = this.second;
        this[intervalTimer] = setInterval(function () {
          _this.reportDuration--;
          if (_this.reportDuration === 0) {
            _this.closeNewTask();
            _this[intervalTimer] && clearInterval(_this[intervalTimer]);
            _this[intervalTimer] = null;
          }
        }, 1000);
      } else {
        this[intervalTimer] && clearInterval(this[intervalTimer]);
      }
    },
    closeNewTask: function closeNewTask() {
      this.$emit('closeNewTask', this.newTast.storeNumber);
    },
    onAnswer: function onAnswer() {
      this.$emit('onAnswer', this.newTast);
    }
  })
};