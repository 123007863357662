var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "el-menu",
    attrs: {
      flex: ""
    }
  }, _vm._l(_vm.header, function (item, index) {
    return _c("li", {
      key: index,
      class: {
        "el-menu-item": true,
        "is-active": item.path == _vm.active
      },
      on: {
        click: function click($event) {
          return _vm.onClickMenu(item);
        }
      }
    }, [_c("i", {
      class: "fa fa-" + item.meta.icon
    }), _vm._v(" " + _vm._s(item.meta.title) + " ")]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };