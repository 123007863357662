import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip } from '@/config/dataDictionary';
import { getStoreNewlyRetention as _getStoreNewlyRetention } from '@/api/businessSystem';
export default {
  name: 'DutyNewUsers',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      homeTip: homeTip,
      loading: false,
      chartData: {},
      dataList: [{
        value: 382318,
        name: 'A类'
      }, {
        value: 136470,
        name: 'B类'
      }, {
        value: 208120,
        name: 'C类'
      }]
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getStoreNewlyRetention();
  },
  methods: {
    getStoreNewlyRetention: function getStoreNewlyRetention() {
      var _this = this;
      this.loading = true;
      var type = this.type,
        search = this.search;
      _getStoreNewlyRetention({
        type: type,
        search: search
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var _res$body = res.body,
            currentMonth = _res$body.currentMonth,
            retentionList = _res$body.retentionList;
          _this.init(retentionList, currentMonth);
        }
      });
    },
    init: function init(echartsDate, currentMonth) {
      var xData = [];
      var totalRevenue = [];
      var totalRevenueNew = [];
      console.log('[tooltip  ] >', echartsDate);
      echartsDate.map(function (x) {
        xData.push("".concat(x.year, "\u5E74").concat(x.month, "\u6708\u65B0\u589E\u7559\u5B58"));
        totalRevenue.push(_objectSpread(_objectSpread({}, x), {}, {
          value: x.newlyStoreCount
        }));
        totalRevenueNew.push(_objectSpread(_objectSpread({}, x), {}, {
          value: x.newlyRetention
        }));
      });
      this.chartData = {
        color: ['#4b65bf'],
        // 深蓝色和黄色
        tooltip: {
          trigger: 'axis',
          formatter: function formatter(params) {
            return "\n              ".concat(params[0].data.year, "\u5E74").concat(params[0].data.month, "\u6708\u65B0\u589E\u7528\u6237\u5728").concat(currentMonth, "\u6708\u7684\u7559\u5B58\u60C5\u51B5<br />\n              ").concat(params[0].marker).concat(params[0].data.month, "\u6708\u65B0\u589E\u6570:  ").concat(params[0].data.newlyStoreCount, "<br />\n              ").concat(params[1].marker).concat(currentMonth, "\u6708\u7559\u5B58\u6570:  ").concat(params[0].data.newlyRetention, "<br />\n              ").concat(params[1].marker).concat(currentMonth, "\u6708\u7559\u5B58\u7387:  ").concat(params[0].data.retentionRate, "%<br />\n            ");
          }
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true
          },
          minInterval: 1
        },
        legend: {},
        series: [{
          itemStyle: {
            normal: {
              color: '#dbdbdb' // 深蓝色
            }
          },
          data: totalRevenue,
          type: 'bar'
        }, {
          barGap: '-100%',
          itemStyle: {
            normal: {
              color: '#55f55f' // 深蓝色
            }
          },
          data: totalRevenueNew,
          type: 'bar'
        }]
      };
    }
  }
};