import "core-js/modules/es.array.map.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip, echartToolbox } from '@/config/dataDictionary';
import { getDutyTendency as _getDutyTendency } from '@/api/businessSystem';
export default {
  name: 'DutySituation',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      number: 5,
      homeTip: homeTip,
      chartData: null,
      loading: false,
      timeArr: [],
      dataList: [{
        value: 382318,
        name: 'A类'
      }, {
        value: 136470,
        name: 'B类'
      }, {
        value: 208120,
        name: 'C类'
      }]
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {
    search: function search(newVal) {
      // this.getDataList()
    }
  },
  created: function created() {
    var startTime = this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss');
    var endTime = "".concat(this.$dayjs().format('YYYY-MM-DD'));
    this.timeArr = [startTime || '', endTime || ''];
    this.getDutyTendency();
  },
  methods: {
    getDutyTendency: function getDutyTendency() {
      var _this = this;
      this.loading = true;
      _getDutyTendency({
        startTime: this.timeArr[0],
        endTime: this.timeArr[1]
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var echartsDate = res.body;
          _this.init(echartsDate);
        }
      });
    },
    init: function init(echartsDate) {
      var xData = this.$lodash.map(echartsDate, 'date');
      var dutyDuration = this.$lodash.map(echartsDate, 'dutyDuration');
      var dutyCount = this.$lodash.map(echartsDate, 'dutyCount');
      var payOrderCount = this.$lodash.map(echartsDate, 'payOrderCount');
      this.chartData = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          // 设置背景图片 rgba格式
          color: 'black'
          // formatter: '{a0}: {c0} 小时<br/>{a1}: {c1} 次<br/>{a2}: {c2} 单'
          // formatter: function (params) {
          //   var result = params[0].name + '<br />'
          //   for (var i = 0; i < params.length; i++) {
          //     result += params[i].marker + params[i].seriesName + ': ' + params[i].value + params[i].unit +'<br />'
          //   }
          //   return result
          // }
        },
        legend: {
          // icon: 'roundRect',
        },
        dataZoom: [{
          show: true,
          realtime: true,
          start: 0,
          end: 100
        }, {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: echartToolbox,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
        },
        yAxis: [{
          type: 'value',
          name: '数量',
          min: 1,
          minInterval: 1,
          axisLine: {
            show: true
          }
        }, {
          type: 'value',
          name: '值守时长(小时)',
          min: 0,
          axisLine: {
            show: true
          }
        }],
        series: [{
          unit: '小时',
          name: '值守时长',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#ea9b37'
            }
          },
          yAxisIndex: 1,
          data: dutyDuration
        }, {
          name: '客服任务数',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#29ba51'
            }
          },
          yAxisIndex: 0,
          data: dutyCount
        }, {
          name: '成交订单数',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#1785ff'
            }
          },
          yAxisIndex: 0,
          data: payOrderCount
        }]
      };
    }
  }
};