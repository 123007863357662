var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "平均在店时长(昨日)",
      isTime: true,
      infoDate: _vm.userOverview.averageTimeInStore,
      tip: _vm.homeTip.averageTimeInStore
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "平均选品时长(昨日)",
      isTime: true,
      infoDate: _vm.userOverview.chooseGoodsAverageTime,
      tip: _vm.homeTip.chooseGoodsAverageTime
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "进店转化率(昨日)",
      unit: " %",
      infoDate: _vm.userOverview.entranceTransferRate,
      tip: _vm.homeTip.entranceTransferRate
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "成交顾客平均在店时长(昨日)",
      isTime: true,
      infoDate: _vm.userOverview.dealAverageTime,
      tip: _vm.homeTip.dealAverageTime
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "平均结账时长(昨日)",
      isTime: true,
      infoDate: _vm.userOverview.payAverageTime,
      tip: _vm.homeTip.payAverageTime
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "购买转化率(昨日)",
      infoDate: _vm.userOverview.purchaseTransferRate,
      tip: _vm.homeTip.purchaseTransferRate
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("customersInStore")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("customerConversionRate")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };