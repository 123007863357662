import _toConsumableArray from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import { businessSystemRouter } from './modules/businessSystem';
// import home from './modules/home'

import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'login'
  },
  component: layoutHeaderAside,
  children: [
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      title: '首页',
      auth: true,
      parent: '/index'
    },
    component: _import('system/index')
  },
  // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}].concat(_toConsumableArray(businessSystemRouter));
// /**
//  * 将子路由转换为扁平化路由数组（仅一级）
//  * @param {待转换的子路由数组} routes
//  * @param {父级路由路径} parentPath
//  */
// function castToFlatRoute(routes, parentPath, flatRoutes = []) {
//   for (let item of routes) {
//     if (item.children && item.children.length > 0) {
//       if (item.redirect && item.redirect !== 'noRedirect') {
//         flatRoutes.push({
//           name: item.name,
//           path: (parentPath + "/" + item.path).substring(1),
//           redirect: item.redirect,
//           meta: item.meta
//         });
//       }
//       castToFlatRoute(item.children, parentPath + "/" + item.path, flatRoutes);
//     } else {
//       flatRoutes.push({
//         name: item.name,
//         path: (parentPath + "/" + item.path).substring(1),
//         component: item.component,
//         meta: item.meta
//       });
//     }
//   }

//   return flatRoutes;
// }
/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(_toConsumableArray(frameIn), frameOut, errorPage);