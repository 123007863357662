import { render, staticRenderFns } from "./pageAuth.vue?vue&type=template&id=c628f798&scoped=true"
import script from "./pageAuth.vue?vue&type=script&lang=js"
export * from "./pageAuth.vue?vue&type=script&lang=js"
import style0 from "./pageAuth.vue?vue&type=style&index=0&id=c628f798&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c628f798",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/gj-gos-system_qxbP/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c628f798')) {
      api.createRecord('c628f798', component.options)
    } else {
      api.reload('c628f798', component.options)
    }
    module.hot.accept("./pageAuth.vue?vue&type=template&id=c628f798&scoped=true", function () {
      api.rerender('c628f798', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/error/pageAuth.vue"
export default component.exports