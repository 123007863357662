var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      background: "#fff",
      height: "100%",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticClass: "wscn-http404"
  }, [_c("div", {
    staticClass: "pic-404"
  }, [_c("img", {
    staticClass: "pic-404__parent",
    attrs: {
      src: _vm.img404,
      alt: "404"
    }
  }), _c("div", {
    staticClass: "bullshit__headline"
  }, [_vm._v(_vm._s(_vm.message))]), _c("div", {
    staticClass: "bullshit__info"
  }, [_vm._v("请联系管理员添加")])]), _c("div", {
    staticClass: "bullshit"
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };