var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-cascader", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: !_vm.disabled ? "请选择省市区" : "暂无",
      size: "medium",
      options: _vm.options,
      clearable: "",
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.selectedOptions,
      callback: function callback($$v) {
        _vm.selectedOptions = $$v;
      },
      expression: "selectedOptions"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };