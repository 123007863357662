import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip } from '@/config/dataDictionary';
import { getStoreCity as _getStoreCity } from '@/api/businessSystem';
export default {
  name: 'StoreDistribution',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      source: 'all',
      homeTip: homeTip,
      loading: false,
      chartData: {}
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getStoreCity();
  },
  methods: {
    getStoreCity: function getStoreCity() {
      var _this = this;
      this.loading = true;
      _getStoreCity({
        source: this.source
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var echarts = res.body.cityList;
          _this.init(echarts);
        }
      });
    },
    init: function init(echarts) {
      var total = 0;
      console.log('[ echartsDate ] >', echarts);
      var echartsDate = echarts.map(function (x) {
        total += parseFloat(x.storeCount);
        return {
          name: x.city,
          value: x.storeCount,
          percent: x.percent
        };
      });
      console.log('[ echartsDate ] >', echartsDate);
      this.chartData = {
        legend: {
          orient: 'vertical',
          right: '0',
          top: 'center',
          padding: [0, 20, 0, 20],
          // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: echartsDate,
          formatter: function formatter(name) {
            var value = echartsDate.filter(function (x) {
              return x.name === name;
            })[0].value;
            var percent = echartsDate.filter(function (x) {
              return x.name === name;
            })[0].percent;
            var arr = ["{name|".concat(name, "}{border| | }{percentage|").concat(percent, "%}{value|").concat(value, "}")];
            return arr.join('\n');
          },
          textStyle: {
            rich: {
              name: {
                fontSize: 20,
                align: 'left',
                padding: [0, 0, 10, 10],
                lineHeight: 40
              },
              border: {
                fontSize: 20,
                align: 'right',
                color: '#888888',
                padding: [10, 0, 10, 10]
              },
              percentage: {
                fontSize: 16,
                align: 'right',
                width: 100,
                color: '#888888',
                padding: [20, 20, 20, 0]
              },
              value: {
                fontSize: 16,
                align: 'right',
                padding: [20, 0, 20, 20]
              }
            }
          }
        },
        title: {
          zlevel: 0,
          text: ['{name|运营门店}', "{value|".concat(total, "} \u5BB6")].join('\n'),
          top: '35%',
          left: '35%',
          textAlign: 'center',
          textStyle: {
            rich: {
              name: {
                color: '#888',
                fontSize: 15,
                lineHeight: 28
              },
              value: {
                color: '#303133',
                fontSize: 40,
                fontWeight: 'bold',
                lineHeight: 50
              }
            }
          }
        },
        tooltip: {},
        series: [{
          name: '门店数量',
          type: 'pie',
          center: ['35%', '45%'],
          radius: ['40%', '55%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          // 取消掉环形图鼠标移上去时自动放大
          // labelLayout: {
          //   hideOverlap: false
          // },
          label: {
            // position: 'outer',
            // alignTo: 'labelLine',
            formatter: '{name|{b}}  {value|{c}} ( {percent| {d} %} )',
            edgeDistance: 10,
            rich: {
              name: {
                fontSize: 14
              },
              value: {
                fontSize: 14
              },
              percent: {
                fontSize: 12
              }
            }
          },
          itemStyle: {
            // 图形外文字上下显示
            normal: {
              // color: function (colors) {
              //   var colorList = [
              //     '#8c54ed',
              //     '#3396ff',
              //     '#39bdbe',
              //     '#44c468',
              //     '#f9cd30',
              //     '#f0f870'
              //   ]
              //   return colorList[colors.dataIndex]
              // }
            }
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '20'
            }
          },
          labelLine: {
            show: true
          },
          data: echartsDate
        }]
      };
    }
  }
};