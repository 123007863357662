var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "title-main"
  }, [_vm._v("值守时长排行榜")]), _c("el-select", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.getList
    },
    model: {
      value: _vm.dateDesc,
      callback: function callback($$v) {
        _vm.dateDesc = $$v;
      },
      expression: "dateDesc"
    }
  }, _vm._l(_vm.dateDescList, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.value,
        value: item.key
      }
    });
  }), 1), _c("el-tooltip", {
    staticClass: "tooltipPost",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.rankingDutyHours)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: "400",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "rank",
      label: "排名",
      width: "70"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "storeName",
      label: "门店名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "dutyDuration",
      label: "值守时长"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "averageDutyDuration",
      label: "日值守时长(小时)"
    }
  })], 1), _c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };