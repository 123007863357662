var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.voiceType === "image" ? _c("div", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "left"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v(_vm._s(_vm.voice.reportSceneName))]), _vm.voiceType === "image" && _vm.secord == 0 ? _c("div", [_vm.voice.reportScene !== "steal_alert" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    on: {
      click: _vm.handleVoice
    }
  }, [_c("img", {
    staticClass: "tooltip_content",
    style: {
      width: _vm.iconHeight
    },
    attrs: {
      slot: "content",
      src: _vm.voice.iconUrl,
      alt: "",
      srcset: ""
    },
    slot: "content"
  })]) : _vm._e(), _c("el-popconfirm", {
    ref: _vm.voice.reportScene,
    attrs: {
      "confirm-button-text": "下发",
      "cancel-button-text": "取消",
      icon: "el-icon-info",
      "icon-color": "red",
      title: "确定需要在门店播放偷盗警告音频？"
    },
    on: {
      confirm: function confirm($event) {
        return _vm.handleVoice();
      }
    }
  }, [_vm.voice.reportScene === "steal_alert" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticClass: "tooltip_content",
    style: {
      height: _vm.iconHeight
    },
    attrs: {
      slot: "content",
      src: _vm.voice.iconUrl,
      alt: "",
      srcset: ""
    },
    slot: "content"
  })]) : _vm._e()])], 1) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("img", {
    staticClass: "tooltip_content",
    style: {
      width: _vm.iconHeight
    },
    attrs: {
      slot: "content",
      src: require("../../workbench/image/playing.png"),
      alt: "",
      srcset: ""
    },
    slot: "content"
  })])])], 1) : _vm._e(), _vm.voiceType === "text" ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "voice-btn",
    attrs: {
      size: "small",
      type: _vm.secord !== 0 ? "primary" : "",
      disabled: _vm.secord !== 0,
      plain: _vm.secord !== 0 ? false : true
    },
    nativeOn: {
      click: function click($event) {
        return _vm.handleVoice.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.voice.reportSceneName) + _vm._s(_vm.secord !== 0 ? "(播放中)" : "") + " ")]) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "\u64AD\u653E\u3010".concat(_vm.voice.reportSceneName, "\u3011\u8BED\u97F3"),
      "destroy-on-close": true,
      visible: _vm.dialogVisibleVideo,
      "append-to-body": true,
      width: "400px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleVideo = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", [_vm._v("播放设备：")]), _c("el-select", {
    staticStyle: {
      width: "280px",
      "margin-right": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.cashierSn,
      callback: function callback($$v) {
        _vm.cashierSn = $$v;
      },
      expression: "cashierSn"
    }
  }, _vm._l(_vm.cashierSnList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "medium",
      plain: ""
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "success",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.onPlay(_vm.cashierSn);
      }
    }
  }, [_vm._v("播放语音")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };