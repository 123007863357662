import { request } from '@/api/_service.js';

/**
 * @description 登录
 * @param {Object} data 登录携带的信息
 */
export function SYS_USER_LOGIN() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/automatic-vending/system-user/login',
    method: 'post',
    data: data
  });
}
export function logoutApi() {
  var url = '/automatic-vending/system-user/logout';
  return request({
    url: url,
    method: 'post'
  });
}