import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("div", [_c("span", {
    staticClass: "title-main"
  }, [_vm._v(" 销售额 ")]), _c("el-tooltip", {
    staticStyle: {
      color: "#797979ad",
      "font-size": "19px",
      "margin-left": "10px"
    },
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.salesTrend)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      flex: "cross:center"
    }
  }, [_c("el-radio-group", {
    staticStyle: {
      "margin-right": "20px"
    },
    on: {
      change: _vm.radioGroupChange
    },
    model: {
      value: _vm.group,
      callback: function callback($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "day"
    }
  }, [_vm._v("按日")]), _c("el-radio", {
    attrs: {
      label: "week"
    }
  }, [_vm._v("按周")]), _c("el-radio", {
    attrs: {
      label: "month"
    }
  }, [_vm._v("按月")])], 1), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      clearable: false,
      "value-format": "yyyy-MM-dd",
      "range-separator": "至",
      "picker-options": _vm.pickerOptions,
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    on: {
      change: _vm.dateChange
    },
    model: {
      value: _vm.timeArr,
      callback: function callback($$v) {
        _vm.timeArr = $$v;
      },
      expression: "timeArr"
    }
  })], 1)])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 15
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("销售额趋势 ")]), _c("echartsMain", {
    attrs: {
      chartData: _vm.chartData
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("门店销售额排名")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      align: "center",
      "header-align": "center",
      height: "480"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "date",
      label: "排名",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_c("div", {
          class: {
            index_style: true,
            index_select: $index < 3
          },
          attrs: {
            flex: "main:center cross:center"
          }
        }, [_vm._v(_vm._s($index + 1))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "storeName",
      label: "门店名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "businessRevenue",
      label: "总销售额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s(Number(row.totalRevenue || 0).toLocaleString() || 0))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "businessRevenue",
      label: "有人值守"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(_vm._s(Number(row.businessRevenue || 0).toLocaleString() || 0))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "autoDutyRevenue",
      label: "云值守"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(Number(row.autoDutyRevenue || 0).toLocaleString() || 0))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };