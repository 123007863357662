import "core-js/modules/es.array.map.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip, echartToolbox } from '@/config/dataDictionary';
import { getDutyActivityTendency as _getDutyActivityTendency } from '@/api/businessSystem';
export default {
  name: 'DutyStoreActivityRate',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      homeTip: homeTip,
      chartData: null,
      loading: false,
      timeArr: [],
      dataList: [{
        value: 382318,
        name: 'A类'
      }, {
        value: 136470,
        name: 'B类'
      }, {
        value: 208120,
        name: 'C类'
      }]
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {},
  created: function created() {
    var startTime = this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss');
    var endTime = "".concat(this.$dayjs().format('YYYY-MM-DD'));
    this.timeArr = [startTime || '', endTime || ''];
    this.getDutyActivityTendency();
    // this.getDataList()
  },
  methods: {
    getDutyActivityTendency: function getDutyActivityTendency() {
      var _this = this;
      this.loading = true;
      console.log('[this.timeArr  ] >', this.timeArr);
      _getDutyActivityTendency({
        startTime: this.timeArr[0],
        endTime: this.timeArr[1]
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var echartsDate = res.body.dutyActivityList;
          _this.init(echartsDate);
        }
      });
    },
    init: function init(echartsDate) {
      var xData = this.$lodash.map(echartsDate, 'date');
      var activityRate = this.$lodash.map(echartsDate, 'activityRate');
      this.chartData = {
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [{
          show: true,
          realtime: true,
          start: 0,
          end: 100
        }, {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: echartToolbox,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: 'value',
          name: '活跃率',
          axisLabel: {
            formatter: '{value}%'
          },
          axisLine: {
            show: true
          }
        },
        series: [{
          name: '活跃率（%）',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#1785ff'
            }
          },
          data: activityRate
        }]
      };
    }
  }
};