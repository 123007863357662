export default {
  name: 'app',
  $route: function $route() {},
  data: function data() {
    return {
      messageInstance: null
    };
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  mounted: function mounted() {
    var _this = this;
    // 检测断网
    window.addEventListener('offline', function (e) {
      console.log(e);
      _this.messageInstance && _this.messageInstance.close();
      _this.messageInstance = _this.$message({
        message: '网络连接失败',
        type: 'error',
        duration: 0
      });
    });
    window.addEventListener('online', function (e) {
      console.log(e);
      _this.messageInstance && _this.messageInstance.close();
      _this.messageInstance = _this.$message({
        message: '网络已恢复，刷新中....',
        type: 'error',
        duration: 0
      });
      window.location.reload();
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('offline');
    window.removeEventListener('online');
    localStorage.setItem('hasWin', '');
  },
  methods: {
    i18nHandle: function i18nHandle(val, oldVal) {
      sessionStorage.setItem('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  }
};