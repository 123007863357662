import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "call-notify"
  }, [!_vm.wsCall.roomId ? _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.closeWsCall();
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })]) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("img", {
    staticClass: "phone",
    attrs: {
      src: require("@/assets/phone.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "message"
  }, [_vm._v(" 来自『" + _vm._s(_vm.wsCall.storeName) + "』顾客" + _vm._s(_vm.wsCall.onCallStatus === "calling" ? "呼叫请求" : "未接呼叫") + " "), _vm.wsCall.onCallStatus !== "calling" ? _c("span", [_vm._v(",请及时处理~")]) : _vm._e(), _vm.wsCall.onCallStatus === "calling" ? _c("span", [_c("br"), _vm._v("(已等待 "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.second.includes("NaN") ? "00:00:00" : _vm.second))]), _vm._v(",请及时处理)")]) : _vm._e(), _vm.wsCall.onCallStatus === "calling" ? _c("el-button", {
    staticClass: "join",
    staticStyle: {
      background: "#ee722e"
    },
    attrs: {
      type: "warning",
      size: "mini"
    },
    on: {
      click: _vm.onWsAnswer
    }
  }, [_vm._v("接听")]) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };