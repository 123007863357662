var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      background: "#fff",
      height: "100%",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticClass: "wscn-http404",
    attrs: {
      flex: "dir:top main:center cross:center"
    }
  }, [_c("div", {
    staticClass: "pic-404"
  }, [_c("img", {
    staticClass: "pic-404__parent",
    attrs: {
      src: _vm.img404,
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child left",
    attrs: {
      src: _vm.img404cloud,
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child mid",
    attrs: {
      src: _vm.img404cloud,
      alt: "404"
    }
  }), _c("img", {
    staticClass: "pic-404__child right",
    attrs: {
      src: _vm.img404cloud,
      alt: "404"
    }
  })]), _c("div", {
    staticClass: "bullshit"
  }, [_c("div", {
    staticClass: "bullshit__oops"
  }, [_vm._v("OOPS!")]), _vm._m(0), _c("div", {
    staticClass: "bullshit__headline"
  }, [_vm._v(_vm._s(_vm.message))]), _c("div", {
    staticClass: "bullshit__info"
  }, [_vm._v("请检查您输入的网址是否正确，请点击以下按钮返回上一页")]), _c("div", {
    staticClass: "bullshit__return-home",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回上一页")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bullshit__info"
  }, [_vm._v("版权所有"), _c("a", {
    staticClass: "link-type",
    staticStyle: {
      color: "#1482f0"
    },
    attrs: {
      rel: "noopener noreferrer",
      href: "http://www.haobangshou.shop/",
      target: "_blank"
    }
  }, [_vm._v("观见智联")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };