import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm$curentInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "store-info-card"
  }, [!_vm.assistOrdersShow && !_vm.orderSuccessShow ? _c("div", [_c("el-card", {
    staticClass: "store-card",
    staticStyle: {
      background: "#fff"
    },
    attrs: {
      flex: "main:justify"
    }
  }, [_vm._l(_vm.selectTag, function (tag, index) {
    return _c("el-tag", {
      key: tag.tagNumber,
      staticClass: "tags",
      staticStyle: {
        border: "none"
      },
      attrs: {
        effect: "dark",
        closable: _vm.allTag.includes(tag.tagNumber),
        size: "medium",
        "disable-transitions": true,
        color: tag.tone
      },
      on: {
        close: function close($event) {
          return _vm.handleClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.tagName) + " ")]);
  }), _c("el-dropdown", {
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-tag", {
    staticClass: "tags",
    attrs: {
      size: "medium"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus",
    staticStyle: {
      "margin-right": "5px"
    }
  }), _vm._v("打标签")]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.systemTag, function (tag, index) {
    return _c("el-dropdown-item", {
      key: tag.tagNumber,
      attrs: {
        command: index,
        disabled: _vm.tagDisabled(tag)
      }
    }, [_vm._v(" " + _vm._s(tag.tagName))]);
  }), 1)], 1)], 2), _c("el-card", {
    staticClass: "store-card"
  }, [_c("div", {
    staticClass: "header",
    staticStyle: {
      color: "#333"
    },
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_c("span", [_vm._v("实时订单")]), _c("el-button", {
    staticClass: "fuzhu",
    attrs: {
      type: "success",
      size: "mini"
    },
    on: {
      click: _vm.helpOrder
    }
  }, [_vm._v("辅助结算")])], 1), _vm._l(_vm.getOrderList(), function (o) {
    return _c("div", {
      key: o.orderId,
      staticClass: "product",
      attrs: {
        flex: "dir:top"
      }
    }, [_c("div", {
      staticClass: "product-order"
    }, [_c("div", {
      attrs: {
        flex: "main:justify"
      }
    }, [_c("span", [_vm._v("支付状态：" + _vm._s(_vm.payStatusType[o.orderStatus] || o.orderStatus))]), _c("span", [_vm._v(_vm._s(o.productCount || "--") + "件")]), _c("span", {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v("¥" + _vm._s(o.buyerPayAmount || "--"))])]), o.payTime ? _c("span", [_vm._v("支付时间：" + _vm._s(o.payTime || "--"))]) : _vm._e()]), _vm._l(o.productInfoList, function (i, index) {
      return _c("div", {
        key: index,
        attrs: {
          flex: "box:last"
        }
      }, [_c("span", {
        staticClass: "product-name"
      }, [_vm._v(_vm._s(i.productName || "--"))]), _c("span", {
        staticClass: "buy-count"
      }, [_vm._v("X" + _vm._s(i.buyCount || "--"))]), _c("span", {
        staticClass: "buyer-payAmount"
      }, [_vm._v("¥" + _vm._s(i.buyerPayAmount || "--"))])]);
    }), o.orderStatus === "no_pay" ? _c("div", {
      staticStyle: {
        "text-align": "right",
        "margin-top": "5px"
      }
    }, [_c("el-button", {
      staticClass: "fuzhu",
      attrs: {
        type: "success",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.orderHelpOrder(o);
        }
      }
    }, [_vm._v("辅助结算")])], 1) : _vm._e()], 2);
  }), !_vm.getOrderList().length ? _c("div", [_c("el-empty", {
    attrs: {
      description: "暂无订单"
    }
  })], 1) : _vm._e(), ((_vm$curentInfo = _vm.curentInfo) === null || _vm$curentInfo === void 0 ? void 0 : _vm$curentInfo.orderInfo.dutyOrderInfoList.length) > 1 ? _c("div", {
    staticClass: "has-more",
    on: {
      click: _vm.handleOpenOrder
    }
  }, [_vm._v(" " + _vm._s(_vm.openOrder ? "关闭" : "本任务存在多支付订单")), _c("i", {
    staticClass: "el-icon-d-arrow-right",
    class: {
      rotate: _vm.openOrder
    }
  })]) : _vm._e()], 2), _c("el-card", {
    staticClass: "store-card"
  }, [_c("div", {
    staticClass: "header",
    staticStyle: {
      color: "#333"
    },
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_c("span", [_vm._v("门店信息")])]), _vm._l(_vm.wordStoreInfo, function (val, key, i) {
    return _c("div", {
      key: i,
      staticClass: "info"
    }, [_c("span", {
      staticClass: "title",
      style: {
        width: "".concat(_vm.wordStoreInfo[key].width || 85, "px")
      },
      domProps: {
        innerHTML: _vm._s(key + ":")
      }
    }), _vm.wordStoreInfo[key].showType === "tag" && _vm.getInfoText(val) !== "--" ? _c("el-tag", {
      staticClass: "tag",
      attrs: {
        size: "small",
        effect: "dark"
      }
    }, [_vm._v(_vm._s(_vm.getInfoText(val)))]) : _c("span", {
      staticClass: "content",
      domProps: {
        innerHTML: _vm._s(_vm.getInfoText(val))
      }
    })], 1);
  })], 2), _c("el-card", {
    staticClass: "store-card"
  }, [_c("div", {
    staticClass: "header",
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_c("span", [_vm._v("紧急联系电话")])]), _vm._l(_vm.wordEmergentInfo, function (val, key, i) {
    return _c("div", {
      key: i,
      staticClass: "info"
    }, [_c("span", {
      staticClass: "title",
      style: {
        width: "".concat(_vm.wordEmergentInfo[key].width || 85, "px")
      }
    }, [_vm._v(" " + _vm._s(key) + ":")]), _c("span", {
      staticClass: "content"
    }, [_vm._v(_vm._s(_vm.getInfoText(val)))])]);
  })], 2)], 1) : _vm._e(), _vm.assistOrdersShow && !_vm.orderSuccessShow ? _c("div", {
    staticClass: "assistOrders"
  }, [_c("div", {
    staticClass: "assistOrders-title"
  }, [_vm._v("辅助下单")]), _c("el-card", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.searchLoading,
      expression: "searchLoading"
    }],
    staticClass: "good-card"
  }, [_c("div", {
    staticClass: "header good-title",
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_vm._v(" 查询商品 ")]), _c("div", {
    staticClass: "search-input"
  }, [_c("el-input", {
    attrs: {
      size: "small",
      clearable: "",
      placeholder: "请输入商品名称/商品编码"
    },
    on: {
      clear: _vm.clearKeyword
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchGood.apply(null, arguments);
      }
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1), _c("el-button", {
    staticClass: "search-btn",
    attrs: {
      type: "primary",
      disabled: !_vm.keyword,
      size: "mini"
    },
    on: {
      click: _vm.searchGood
    }
  }, [_vm._v("查询")]), _vm.goodList.length ? _c("div", {
    staticClass: "good-list"
  }, _vm._l(_vm.goodList, function (item, index) {
    return _c("div", {
      key: index,
      class: {
        "good-item": true,
        "good-item-active": index === _vm.goodActive
      },
      attrs: {
        flex: "main:justify"
      },
      on: {
        click: function click($event) {
          return _vm.selectGood(item, index);
        }
      }
    }, [_c("div", {
      staticClass: "good-name"
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: item.goodsName,
        placement: "top"
      }
    }, [_c("div", {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(item.goodsName))])]), _c("div", [_vm._v("(" + _vm._s(item.goodsCode || "临时商品") + ")")])], 1), _c("span", [_vm._v("¥ " + _vm._s(item.retailPrice) + " 元 ")])]);
  }), 0) : _vm._e(), _vm.isNeedAddGood ? _c("div", {
    staticClass: "good-addList"
  }, [_c("div", {
    staticClass: "good-nodate"
  }, [_vm._v("查询不到商品，可重新输入关键字或输入条码查询,确定系统未维护,可在下方输入商品信息，创建临时商品")]), _c("el-input", {
    attrs: {
      size: "small",
      maxlength: "40",
      clearable: "",
      placeholder: "请输入商品名称"
    },
    model: {
      value: _vm.goodsName,
      callback: function callback($$v) {
        _vm.goodsName = $$v;
      },
      expression: "goodsName"
    }
  }), _c("div", {
    staticClass: "add-good"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("el-input", {
    staticClass: "item-input",
    attrs: {
      size: "small",
      type: "number",
      step: "0.01",
      max: "99999",
      min: "0",
      placeholder: "购买单价",
      oninput: "validity.valid||(value=+value>99999?99999:Number(value).toFixed(2))"
    },
    model: {
      value: _vm.goodsPrice,
      callback: function callback($$v) {
        _vm.goodsPrice = $$v;
      },
      expression: "goodsPrice"
    }
  }), _vm._v("元 ")], 1), _c("div", {
    staticClass: "item"
  }, [_c("el-input", {
    staticClass: "item-input",
    attrs: {
      size: "small",
      maxlength: "3",
      placeholder: "购买数量"
    },
    on: {
      input: function input(e) {
        if (e <= 0) {
          _vm.goodsNumber = "";
        } else {
          _vm.goodsNumber = _vm.validtoNumber(e);
        }
      }
    },
    model: {
      value: _vm.goodsNumber,
      callback: function callback($$v) {
        _vm.goodsNumber = $$v;
      },
      expression: "goodsNumber"
    }
  }), _vm._v("件 ")], 1)]), _c("el-button", {
    staticClass: "search-btn",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleAddGood
    }
  }, [_vm._v("确定")])], 1) : _vm._e()], 1), _c("el-card", {
    staticClass: "good-card"
  }, [_c("div", {
    staticClass: "header good-title",
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_vm._v(" 用户选购商品列表 ")]), _vm.productList.length ? _c("div", {
    staticClass: "order-item",
    attrs: {
      flex: "box:last cross:center"
    }
  }, [_c("span", {
    staticClass: "buyer-payAmount",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" 总价：" + _vm._s(_vm.calculateTotalPrice(_vm.productList)) + " 元")]), _c("span", {
    staticClass: "buyer-payAmount",
    staticStyle: {
      width: "90px",
      "text-align": "center"
    }
  }, [_vm._v(" 数量：" + _vm._s(_vm.calculateTotalSum(_vm.productList)) + " 件 ")])]) : _vm._e(), _vm._l(_vm.productList, function (i, index) {
    return _c("div", {
      key: i.productId + index,
      staticClass: "order-item",
      attrs: {
        flex: "box:last cross:center"
      }
    }, [_c("span", {
      staticClass: "product-name"
    }, [_vm._v(_vm._s(i.productName || "--"))]), _c("span", {
      staticClass: "buyer-payAmount"
    }, [_vm._v(" 单价 " + _vm._s(i.productPrice || "--") + " 元")]), _c("el-input-number", {
      staticStyle: {
        width: "90px"
      },
      attrs: {
        size: "mini",
        step: 1,
        "step-strictly": "",
        max: 100
      },
      on: {
        change: function change(currentValue, oldValue) {
          _vm.handleChange(currentValue, oldValue, i, index);
        }
      },
      model: {
        value: i.buyCount,
        callback: function callback($$v) {
          _vm.$set(i, "buyCount", $$v);
        },
        expression: "i.buyCount"
      }
    })], 1);
  }), !_vm.productList.length ? _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("暂无订单 ")]) : _vm._e()], 2), _c("el-card", {
    staticClass: "good-card"
  }, [_c("div", {
    staticClass: "header good-title",
    attrs: {
      slot: "header",
      flex: "main:center"
    },
    slot: "header"
  }, [_vm._v(" 其他支付方式 ")]), _c("span", {
    staticClass: "tip"
  }, [_vm._v("请告知顾客：使用下方支付方式支付，均无法享有会员价以及其他优惠折扣，需要按原价支付。")]), _c("div", {
    staticClass: "payforList"
  }, _vm._l(_vm.payforList, function (item) {
    return _c("el-button", {
      key: item.key,
      staticClass: "pay",
      attrs: {
        type: _vm.payWay === item.key ? "success" : ""
      },
      on: {
        click: function click($event) {
          return _vm.choosePayWay(item);
        }
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)])], 1) : _vm._e(), _vm.assistOrdersShow && !_vm.orderSuccessShow ? _c("div", {
    staticClass: "btn-list"
  }, [_c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.closeHelpOrder
    }
  }, [_vm._v("取消订单")]), _c("el-button", {
    attrs: {
      type: "success",
      disabled: !_vm.payWay || _vm.productList.length === 0
    },
    on: {
      click: _vm.creatOrder
    }
  }, [_vm._v("提交订单")])], 1) : _vm._e(), _vm.orderSuccessShow ? _c("div", {
    staticClass: "assistOrders"
  }, [_c("div", {
    staticClass: "assistOrders-title"
  }, [_vm._v("手动创建订单")]), _c("orderSuccess", {
    on: {
      closeModal: function closeModal($event) {
        _vm.orderSuccessShow = false;
      }
    }
  })], 1) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "选择收银",
      "append-to-body": true,
      "destroy-on-close": true,
      visible: _vm.dialogVisibleSn,
      width: "560px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleSn = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("span", [_vm._v("所属收银:")]), _c("el-select", {
    staticStyle: {
      width: "450px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.cashierIndex,
      callback: function callback($$v) {
        _vm.cashierIndex = $$v;
      },
      expression: "cashierIndex"
    }
  }, _vm._l(_vm.curentInfo.cashierSnList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.openHelp();
      }
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };