var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-success"
  }, [_c("img", {
    staticClass: "order-img",
    attrs: {
      src: require("./order-success.jpg"),
      alt: "",
      srcset: ""
    }
  }), _c("div", [_vm._v("创建订单成功，请指导用户离店。(" + _vm._s(_vm.countdown) + "s)")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };