import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      inline: true,
      size: "small"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "创建时间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      "picker-options": _vm.pickerOptions,
      type: "daterange",
      "range-separator": "至",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.creatTime,
      callback: function callback($$v) {
        _vm.creatTime = $$v;
      },
      expression: "creatTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "处理时间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      "picker-options": _vm.pickerOptions,
      type: "daterange",
      "range-separator": "至",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.handleTime,
      callback: function callback($$v) {
        _vm.handleTime = $$v;
      },
      expression: "handleTime"
    }
  })], 1), _c("el-form-item", [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "160px"
    },
    model: {
      value: _vm.merchantTypeKey,
      callback: function callback($$v) {
        _vm.merchantTypeKey = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "merchantTypeKey"
    }
  }, _vm._l(_vm.searchmerchant, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1), _c("el-input", {
    attrs: {
      placeholder: "\u8BF7\u8F93\u5165".concat(_vm.searchmerchantType[_vm.merchantTypeKey]),
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.merchantName = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.merchantName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchantName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.merchantName"
    }
  })], 1)]), _c("el-form-item", [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "160px"
    },
    model: {
      value: _vm.storeTypeKey,
      callback: function callback($$v) {
        _vm.storeTypeKey = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "storeTypeKey"
    }
  }, _vm._l(_vm.searchStore, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1), _c("el-input", {
    attrs: {
      placeholder: "\u8BF7\u8F93\u5165".concat(_vm.searchStoreType[_vm.storeTypeKey]),
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.storeName = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.storeName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "storeName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.storeName"
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      label: "任务编码"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务编码",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.taskNumber = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.taskNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taskNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.taskNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "用户手机号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入用户手机号",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.userPhone = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.userPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userPhone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.userPhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "任务处理人"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务处理人",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.handleUser = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.handleUser,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "handleUser", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.handleUser"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "任务状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择任务状态",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.taskStatusList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "是否购物"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择是否购物",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.form.purchase,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "purchase", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.purchase"
    }
  }, _vm._l(_vm.purchaseStatusList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "任务标签"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      filterable: "",
      multiple: "",
      placeholder: "请选择任务标签"
    },
    model: {
      value: _vm.form.tagName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tagName", $$v);
      },
      expression: "form.tagName"
    }
  }, _vm._l(_vm.tagNameList, function (item) {
    return _c("el-option", {
      key: item.tagNumber,
      attrs: {
        label: item.tagName,
        value: item.tagName
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("查询")]), _c("el-button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.onReset
    }
  }, [_vm._v("重置")]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_DUTY_TASK_CREATE",
      expression: "'GJ_VENDING_DUTY_TASK_CREATE'"
    }],
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.infoVisible = true;
      }
    }
  }, [_vm._v("手动创建任务")])], 1)], 1)], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "contentWrap",
    staticClass: "content-wrap"
  }, [_c("div", {
    staticClass: "table-wrap"
  }, [_c("el-table", {
    ref: "table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      stripe: "",
      data: _vm.tableData,
      "max-height": _vm.height
    },
    on: {
      "sort-change": _vm.onSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._l(_vm.tableName, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        sortable: item.sortable ? "custom" : false,
        "show-overflow-tooltip": "",
        prop: item.prop,
        label: item.label,
        width: item.width
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var row = _ref.row;
          return [item.slot == "status" ? _c("div", [_c("el-tag", {
            attrs: {
              type: _vm.taskStatusColor[row.status]
            }
          }, [_vm._v(" " + _vm._s(_vm.taskStatus[row.status]))])], 1) : item.slot == "purchase" ? _c("div", [_vm._v(" " + _vm._s(_vm.purchaseStatus[row.purchase]) + " ")]) : item.slot == "tags" ? _c("div", {
            staticStyle: {
              "overflow-x": "auto"
            }
          }, _vm._l(row.tags, function (tag) {
            return _c("el-tag", {
              key: tag.tagName,
              staticStyle: {
                border: "none",
                "margin-right": "5px"
              },
              attrs: {
                "disable-transitions": true,
                effect: "dark",
                color: tag.tone
              }
            }, [_vm._v(" " + _vm._s(tag.tagName))]);
          }), 1) : _c("div", [_vm._v(_vm._s(row[item.prop]))])];
        }
      }], null, true)
    });
  }), _vm.$isHasAuth(["GJ_VENDING_DUTY_TASK_DETAIL"]) ? _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row,
          $index = _ref2.$index;
        return [_c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_DUTY_TASK_DETAIL",
            expression: "'GJ_VENDING_DUTY_TASK_DETAIL'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goDeatil(row.taskNumber, $index);
            }
          }
        }, [_vm._v("查看")])];
      }
    }], null, false, 640671571)
  }) : _vm._e()], 2)], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1), _vm.infoVisible ? _c("el-dialog", {
    attrs: {
      title: "手动创建进店任务",
      width: "600px",
      "destroy-on-close": true,
      visible: _vm.infoVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.infoVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm"
  }, [_c("el-form-item", {
    attrs: {
      prop: "userName"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.creatStoreTypeChange
    },
    model: {
      value: _vm.creatStoreTypeKey,
      callback: function callback($$v) {
        _vm.creatStoreTypeKey = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "creatStoreTypeKey"
    }
  }, _vm._l(_vm.searchStore, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1), _c("el-select", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      clearable: true,
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: "\u8BF7\u8F93\u5165".concat(_vm.searchStoreType[_vm.creatStoreTypeKey]),
      "remote-method": _vm.remoteMethod,
      loading: _vm.storeLoading
    },
    on: {
      change: _vm.onSelectChange,
      clear: function clear($event) {
        _vm.currentInfo = {};
        _vm.options = [];
      }
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.storeNumber,
      attrs: {
        label: "\u3010 ".concat(item.storeName, " \u3011( ").concat(item.province).concat(item.city).concat(item.region).concat(item.address, " )"),
        value: item.storeNumber
      }
    });
  }), 1)], 1)]), _vm.currentInfo.dutyTaskStatus === "handling" ? _c("div", {
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm.info.name !== _vm.currentInfo.currentHandleUser ? _c("span", [_vm._v(" 该门店当前存在"), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("正在进行中")]), _vm._v("任务，无法创建新任务"), _c("br"), _c("span", {
    staticStyle: {
      "line-height": "30px"
    }
  }, [_vm._v(' 当前处理人员："' + _vm._s(_vm.currentInfo.currentHandleUser) + '" '), _c("br")]), _vm._v(" 如需将当前任务直接「"), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("转交")]), _vm._v("」到您的名下处理，可点击下方确定按钮 ")]) : _c("span", [_vm._v("该门店当前存在待处理任务，是否直接前往处理任务？")])]) : _vm._e(), _vm.currentInfo.dutyTaskStatus === null ? _c("div", {
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm._v(" 该门店当前无正在进行任务，如确定为门店创建新进店任务，可点击下方确定按钮 ")]) : _vm._e(), _vm.currentInfo.dutyTaskStatus === "handle_todo" ? _c("div", {
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm._v(" 该门店当前存在待处理任务，是否直接前往处理任务？ ")]) : _vm._e()], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.cannel
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("确定")])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };