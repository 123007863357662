export default {
  name: 'DutyDataCard',
  props: {
    infoDate: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tip: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    isTime: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  created: function created() {},
  methods: {}
};