import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-layout-header-aside-menu-header',
  data: function data() {
    return {
      active: ''
    };
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['header'])),
  watch: {
    $route: {
      handler: function handler(val) {
        this.active = val.meta.parent;
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/page', ['closeAll'])), {}, {
    onClickMenu: function onClickMenu(data) {
      var _this = this;
      if (data.path === this.active) return;
      this.closeAll();
      setTimeout(function () {
        _this.$router.push({
          path: data.children[0].children[0].path || data.path
        });
      }, 0);
    }
  })
};