import "/root/workspace/gj-gos-system_qxbP/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/gj-gos-system_qxbP/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/gj-gos-system_qxbP/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/gj-gos-system_qxbP/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
// 核心插件
import d2Admin from '@/plugin/d2admin';
// store
import store from '@/store/index';
import { validSe } from '@/utils/validate.js';
// 菜单和路由设置
import router from './router';
import { menuHeader } from '@/utils/menu.js';
import { frameInRoutes } from '@/router/routes';
import { markTrackEvent } from '@/utils/utils.js';
import { isHasAuth } from '@/utils/extraAuth.js';
import gjPagination from '@/components/gj-pagination';
import auth from '@/directive/auth';
import drag from '@/directive/drag';
import ba from 'vue-ba';
import uweb from 'vue-uweb';
import dayjs from 'dayjs';
import lodash from 'lodash';
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$lodash = lodash;
Vue.use(auth);
Vue.use(drag);
if (['test', 'prod'].includes(process.env.VUE_APP_EVN)) {
  Vue.use(ba, process.env.VUE_APP_BWEBSITEID);
  Vue.use(uweb, process.env.VUE_APP_UWEBSITEID);
}
Vue.prototype.validSe = validSe;
Vue.prototype.$markTrackEvent = markTrackEvent;
Vue.prototype.$isHasAuth = isHasAuth;

// import "video.js/dist/video-js.css";

// Vue.prototype.$markTrackEvent = function (category, action, label, value) {
//   try {
//     this.$uweb.trackEvent(category, action, label, value)
//     this.$ba.trackEvent(category, action, label, value)
//   } catch (error) {
//     console.log('埋点', error)
//   }
// }
// 注册为全局组件
Vue.component('gj-pagination', gjPagination);
// 核心插件
Vue.use(d2Admin);
var vueThis = new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    // 初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow');
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load');
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get');
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen');
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var menuHeaderList = menuHeader();
        this.$store.commit('d2admin/menu/headerSet', menuHeaderList);
        var _side = menuHeaderList.filter(function (menu) {
          return menu.path === route.meta.parent;
        });
        // if (!_side.length) {
        //   this.$router.push({ path: menuHeaderList[0]?.children[0]?.path })
        // }
        this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : []);
      },
      immediate: true
    }
  }
}).$mount('#app');
export default vueThis;