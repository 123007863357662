import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.loading,
      expression: "loading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    staticClass: "page"
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-detailInfo-inline",
    attrs: {
      inline: true,
      "label-width": "100px",
      "label-position": "top",
      size: "medium"
    }
  }, [_c("el-card", {
    staticClass: "box-card title",
    staticStyle: {
      position: "relative"
    }
  }, [_c("el-button", {
    staticClass: "back",
    on: {
      click: _vm.onCannel
    }
  }, [_vm._v("返回")]), _vm._v("【" + _vm._s(_vm.store.name) + "】门店功能配置")], 1), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("值守模式配置")])]), _c("div", {
    staticClass: "text item",
    attrs: {
      flex: ""
    }
  }, [_c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "切换模式: ",
      required: ""
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "15px"
    },
    attrs: {
      placeholder: "请选择商户状态设置"
    },
    model: {
      value: _vm.store.dutyStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "dutyStatus", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "store.dutyStatus"
    }
  }, _vm._l(_vm.dutyStatusList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("开锁方式配置")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "进店开锁方式:",
      required: ""
    }
  }, [_c("el-checkbox-group", {
    staticClass: "checkbox",
    model: {
      value: _vm.store.entranceOpenGateWay,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "entranceOpenGateWay", $$v);
      },
      expression: "store.entranceOpenGateWay"
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: "",
      label: "scan_qrcode"
    }
  }, [_vm._v("扫码开锁")]), _c("el-checkbox", {
    attrs: {
      disabled: _vm.store.leaveOpenGateWay.includes("pay"),
      label: "inductor"
    }
  }, [_vm._v("感应开锁")])], 1)], 1), _c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "出店开锁方式:",
      required: ""
    }
  }, [_c("div", {
    attrs: {
      flex: ""
    }
  }, [_c("el-checkbox-group", {
    staticClass: "checkbox",
    on: {
      change: _vm.leaveOpenGateWayChange
    },
    model: {
      value: _vm.store.leaveOpenGateWay,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "leaveOpenGateWay", $$v);
      },
      expression: "store.leaveOpenGateWay"
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: "",
      label: "scan_qrcode"
    }
  }, [_vm._v("扫码开锁")]), _c("el-checkbox", {
    attrs: {
      disabled: _vm.store.entranceOpenGateWay.includes("inductor"),
      label: "pay"
    }
  }, [_vm._v("支付成功开锁")]), _c("el-checkbox", {
    attrs: {
      label: "pay_count"
    }
  }, [_vm._v("历史支付成功")]), _vm._v(" "), _c("el-input-number", {
    staticClass: "input-number",
    attrs: {
      "controls-position": "right",
      min: _vm.store.leaveOpenGateWay.includes("pay_count") ? 1 : 0,
      max: 99
    },
    model: {
      value: _vm.store.payOpenConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "payOpenConfig", $$v);
      },
      expression: "store.payOpenConfig"
    }
  })], 1), _c("span", [_vm._v("次开锁")])], 1)])], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("摄像头功能设置")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "识别到有人: ",
      required: ""
    }
  }, [_c("el-checkbox", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.store.cameraDetectCreateTask,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "cameraDetectCreateTask", $$v);
      },
      expression: "store.cameraDetectCreateTask"
    }
  }, [_vm._v("创建任务 "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "选择此项，如门店内的摄像头已开启“人形识别”功能，且当前门店还未创建客服任务，则则摄像头识别到有人的情况下，系统会自动创建客服任务，分配客服接入",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "el-icon-question question"
  })])], 1), _c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.store.cameraDetectVoiceReport,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "cameraDetectVoiceReport", $$v);
      },
      expression: "store.cameraDetectVoiceReport"
    }
  }, [_vm._v("自动播放场景语音 "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "选择此项，如门店内的摄像头已开启“人形识别”功能，且当前门店摄像头已配置场景语音，则摄像头识别到有人的情况下，会根据播放规则自动播放对应场景语音",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "el-icon-question question"
  })])], 1)], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("自助收银APP功能设置")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "有人值守语音播报: ",
      required: ""
    }
  }, [_c("el-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.store.businessVoiceReportConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "businessVoiceReportConfig", $$v);
      },
      expression: "store.businessVoiceReportConfig"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("播报引导语音（播报会产生费用）")]), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("不播报引导语音")])], 1)], 1), _c("el-form-item", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "无人值守语音播报: ",
      required: ""
    }
  }, [_c("el-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.store.autoDutyVoiceReportConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.store, "autoDutyVoiceReportConfig", $$v);
      },
      expression: "store.autoDutyVoiceReportConfig"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("播报引导语音（播报会产生费用）")]), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("不播报引导语音")])], 1)], 1)], 1)]), _c("el-card", [_c("el-form-item", {
    staticStyle: {
      "text-align": "right",
      width: "100%",
      margin: "0"
    }
  }, [_c("el-button", {
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.onCannel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "medium"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };