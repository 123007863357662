import _toConsumableArray from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      inline: true
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "创建时间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      "picker-options": _vm.pickerOptions,
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.timeDate,
      callback: function callback($$v) {
        _vm.timeDate = $$v;
      },
      expression: "timeDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "门店名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入门店名称",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.storeName = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.storeName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "storeName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.storeName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商户名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入商户名称",
      clearable: ""
    },
    on: {
      input: function input(e) {
        return _vm.form.merchantName = _vm.validSe(e);
      },
      clear: _vm.handleFilter
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.merchantName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchantName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.merchantName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "地区"
    }
  }, [_c("city", {
    ref: "citys",
    staticStyle: {
      width: "300px"
    },
    attrs: {
      list: _vm.adressList
    },
    on: {
      handleChange: _vm.handleFilter,
      "update:list": function updateList($event) {
        _vm.adressList = $event;
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "门店状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择门店状态",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.statusList, function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "当前值守模式"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择当前值守模式",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.form.dutyStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dutyStatus", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.dutyStatus"
    }
  }, _vm._l([{
    key: "",
    value: "全部"
  }].concat(_toConsumableArray(_vm.dutyStatusList)), function (i, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: i.value,
        value: i.key
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("查询")]), _c("el-button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.onReset
    }
  }, [_vm._v("重置")]), _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_STORE_ADD",
      expression: "'GJ_VENDING_STORE_ADD'"
    }],
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.goDeatil("", "add");
      }
    }
  }, [_vm._v("新增")])], 1)], 1)], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "contentWrap",
    staticClass: "content-wrap"
  }, [_c("div", {
    staticClass: "table-wrap"
  }, [_c("el-table", {
    ref: "myTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      stripe: "",
      data: _vm.tableData,
      "max-height": _vm.height
    },
    on: {
      "sort-change": _vm.onSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._l(_vm.tableName, function (item, index) {
    return _c("el-table-column", {
      key: index,
      attrs: {
        "show-overflow-tooltip": "",
        sortable: item.sortable ? "custom" : false,
        prop: item.prop,
        label: item.label,
        width: item.width
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var row = _ref.row;
          return [item.slot == "status" ? _c("div", [_vm._v(" " + _vm._s(_vm.storeStatus[row.status]) + " ")]) : item.slot == "headQuarters" ? _c("div", [_vm._v(" " + _vm._s(_vm.storeType[row.headQuarters]) + " ")]) : item.slot == "deviceActivateStatus" ? _c("div", [_c("el-tag", {
            attrs: {
              type: row.deviceActivateStatus !== "activated" ? "danger" : "success"
            }
          }, [_vm._v(_vm._s(_vm.deviceActivateStatusType[row.deviceActivateStatus] || row.deviceActivateStatus) + "中")])], 1) : item.slot == "dutyStatus" ? _c("div", [_c("el-tag", {
            attrs: {
              type: row.dutyStatus !== "auto_duty" ? "" : "warning"
            }
          }, [_vm._v(_vm._s(_vm.dutyStatusType[row.dutyStatus] || row.dutyStatus) + "中")])], 1) : _c("div", [_vm._v(_vm._s(row[item.prop]))])];
        }
      }], null, true)
    });
  }), _vm.$isHasAuth(["GJ_VENDING_STORE_DUTY_CONFIG", "GJ_VENDING_STORE_DUTY_RECORD_SEARCH", "GJ_VENDING_STORE_DETAIL", "GJ_VENDING_STORE_EDIT", "GJ_VENDING_STORE_DEVICE_ACTIVATE", "GJ_VENDING_STORE_LIVE_VIDEO"]) ? _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row,
          $index = _ref2.$index;
        return [_c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_DUTY_CONFIG",
            expression: "'GJ_VENDING_STORE_DUTY_CONFIG'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goConfig(row);
            }
          }
        }, [_vm._v("配置")]), _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_DUTY_RECORD_SEARCH",
            expression: "'GJ_VENDING_STORE_DUTY_RECORD_SEARCH'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goDutyRecords(row.number);
            }
          }
        }, [_vm._v("值守记录")]), _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_DETAIL",
            expression: "'GJ_VENDING_STORE_DETAIL'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goDeatil(row.number, "look");
            }
          }
        }, [_vm._v("查看")]), _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_EDIT",
            expression: "'GJ_VENDING_STORE_EDIT'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goDeatil(row.number, "edit");
            }
          }
        }, [_vm._v("修改")]), _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_DEVICE_ACTIVATE",
            expression: "'GJ_VENDING_STORE_DEVICE_ACTIVATE'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.deviceActivate(row);
            }
          }
        }, [_vm._v("重新激活")]), row.dutyStatus === "auto_duty" ? _c("el-button", {
          directives: [{
            name: "auth",
            rawName: "v-auth",
            value: "GJ_VENDING_STORE_LIVE_VIDEO",
            expression: "'GJ_VENDING_STORE_LIVE_VIDEO'"
          }],
          attrs: {
            type: "text",
            size: "medium"
          },
          on: {
            click: function click($event) {
              return _vm.goLiveRoom(row, $index);
            }
          }
        }, [_vm._v("直播")]) : _vm._e()];
      }
    }], null, false, 2880951002)
  }) : _vm._e()], 2)], 1)]), _c("div", {
    staticClass: "footer"
  }, [_c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };