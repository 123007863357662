import _toConsumableArray from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
/**
 * 校验输入框禁止特殊符号输入
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */
export function validSe(value) {
  value = value.replace(/[`～*~!@#$%^&*_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
  return value;
}
export function validEngNum(value) {
  value = value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, '');
  return value;
}

/**
 * 校验输入框只输入两位数小数点
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */
export function validtoFixed(value) {
  if (isNaN(value)) {
    value = '';
  }
  if (value >= 1) {
    value = '';
  }
  if (value === 1) {
    value = 1;
  }
  if (value.indexOf('.') > 0) {
    value = value.slice(0, value.indexOf('.') + 3);
  }
  return value;
}

/**
 * 校验输入框只输入数字
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */
export function validtoNumber(value) {
  value = value.replace(/[^\d]/g, '');
  return value;
}
/**
 * 校验输入框只输入价钱
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */

export function validatePrice(inputValue) {
  var priceRegex = /^\d*\.?\d{0,2}$/;
  if (!priceRegex.test(inputValue)) {
    inputValue = inputValue.slice(0, -1); // 删除最后一个字符
  }
  return inputValue;
}
/**
 * 校验输入框只输入数字
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */
export function validateNumberInput(inputValue) {
  // const regex = /^\d*\.?\d*$/ // 验证输入值是否为数字和小数
  inputValue = inputValue.replace(/^\d*\.?\d*$/g, '');
  return inputValue;
}
/**
 * 校验输入框只输入数字
 * @date 2023-03-03 16:18
 * @param {*}
 * @returns
 */
export function isMobile(value) {
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value);
}

/**
 * 数组里是否包含某个值
 * @date 2023-03-03 16:18
 * @param {value} 当前的值
 * @param {list} 数组
 * @param {key} 对象数组对应的值
 * @returns
 */
export function hasValByArr(value, list, key, number) {
  if (!list.length) return [];
  var arr = list.map(function (i) {
    return i[key];
  });
  arr = [].concat(_toConsumableArray(arr), [value]);
  var returnArr = [];
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    obj[arr[i]] = obj[arr[i]] + 1 || 1;
  }
  for (var r in obj) {
    if (+obj[r] === number) {
      returnArr.push(r);
    }
  }
  return returnArr;
}