import "core-js/modules/es.number.constructor.js";
import { mapState } from 'vuex';
import { getToken } from '@/api/businessSystem';
import ysBackVideo from '../ysBackVideo';
var OSS = require('ali-oss');
export default {
  name: 'videoPlay',
  components: {
    ysBackVideo: ysBackVideo
  },
  data: function data() {
    return {
      playVideo: true,
      canPlayError: '',
      isCanPlay: false,
      showPause: true,
      // 显示暂停按钮
      moveIn: true,
      // 显示底部控制条
      allTime: 0,
      // 总时间
      proTime: '00:00',
      // 进度时间
      percentage: 0,
      // 进度加载宽度
      vUrl: '' // url地址，在使用本地的资源时，需要用require()引入，否则控制台会报错
    };
  },
  props: {
    videoInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    liveUrl: {
      type: String,
      default: ''
    },
    deviceSerial: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    ysToken: String
  },
  watch: {
    'video.paused': {
      // 深度监听
      handler: function handler(paused) {
        console.log(paused);
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    onerror: function onerror(e) {
      var video = this.$refs.vPlay;
      this.canPlay = '视频源错误';
      console.log(video.duration);
    },
    play: function play() {
      var video = this.$refs.vPlay;
      // video.webkitRequestFullScreen();
      if (!this.videoInfo.ossVideo) {
        this.playVideo = false;
      } else {
        if (video.paused) {
          video.play();
          this.showPause = false;
        } else {
          video.pause();
          this.showPause = true;
        }
      }
    },
    initVideo: function initVideo() {
      var that = this;
      var video = this.$refs.vPlay;
      video.addEventListener('play', function (e) {
        console.log('提示该视频正在播放中');
        that.showPause = false;
      });
      video.addEventListener('pause', function (e) {
        console.log('暂停播放');
        that.showPause = true;
      });
      video.addEventListener('error', function (e) {
        that.showPause = false;
        that.canPlayError = '视频源出错啦～';
        console.log('视频出错了');
        console.log(e);
      });
      // 3、loadedmetadata ：元数据加载。当指定的音频/视频的元数据已加载时触发，元数据包括：时长、尺寸（仅视频）以及文本轨道
      video.addEventListener('loadedmetadata', function (e) {
        that.isCanPlay = true;
      });
    }
  },
  mounted: function mounted() {
    this.initVideo();
  }
};