import dayjs from 'dayjs';
import { formatSecord } from '@/utils/utils.js';
export default {
  props: {
    wsCall: Object
    // 呼叫
    //     appSource:"app"
    // eventTime:1686823722776
    // fromUser:"FPAL22303010028TYA12806"
    // roomId:"202306150131197"
    // storeName:"研测门店"
    // storeNumber:"GJMD0000131"
    // taskNumber:"RW202306150050"
    // toUser:"GJ00064"

    // 取消
    // onCallStatus:"cancel_call"
    // roomId:"202306150131750"
    // storeName:"研测门店"
    // storeNumber:"GJMD0000131"
  },
  computed: {},
  data: function data() {
    return {
      second: '',
      reportDuration: 20,
      secondAll: 20,
      setIntervalTimer: null,
      setSecondTimer: null
    };
  },
  beforeDestroy: function beforeDestroy() {},
  watch: {
    'wsCall.roomId': function wsCallRoomId(newValue) {
      if (newValue) {
        // 有值接听倒计时
        this.conutSecond(this.wsCall.eventTime);
      } else {
        // 没值 关闭弹窗倒计时
        this.countDown();
      }
    }
  },
  mounted: function mounted() {
    // 接听倒计时
    this.conutSecond(this.wsCall.eventTime);
    // 关闭弹窗倒计时
    this.countDown();
  },
  methods: {
    conutSecond: function conutSecond(startTime) {
      var _this = this;
      this.setSecondTimer && clearInterval(this.setSecondTimer);
      this.setSecondTimer = setInterval(function (x) {
        var hou = dayjs().format('YYYY-MM-DD HH:mm:ss');
        startTime = dayjs(startTime).format('YYYY-MM-DD HH:mm:ss');
        _this.second = formatSecord(dayjs(hou).diff(dayjs(startTime), 'second'));
      }, 1000);
    },
    countDown: function countDown() {
      var _this2 = this;
      this.setIntervalTimer && clearInterval(this.setIntervalTimer);
      this.reportDuration = this.secondAll;
      if (!this.wsCall.roomId) {
        this.setIntervalTimer = setInterval(function () {
          _this2.reportDuration--;
          console.log(_this2.reportDuration);
          if (_this2.reportDuration === 0) {
            _this2.closeWsCall();
            _this2.setIntervalTimer && clearInterval(_this2.setIntervalTimer);
            _this2.setIntervalTimer = null;
          }
        }, 1000);
      }
    },
    closeWsCall: function closeWsCall() {
      this.$emit('closeWsCall', this.wsCall.fromUser);
    },
    onWsAnswer: function onWsAnswer() {
      this.$emit('onWsAnswer', this.wsCall);
    }
  }
};