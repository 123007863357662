import "core-js/modules/es.array.map.js";
import echartsMain from '@/components/Charts/echartsMain';
import { homeTip, echartToolbox } from '@/config/dataDictionary';
import { getRealtimeOrder as _getRealtimeOrder } from '@/api/businessSystem';
import dayjs from 'dayjs';
export default {
  name: 'StoreRealTimeOrders',
  props: {
    type: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      homeTip: homeTip,
      radio: 3,
      loading: false,
      chartData: {},
      setIntervalTimer: null
    };
  },
  components: {
    echartsMain: echartsMain
  },
  computed: {},
  watch: {},
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.setIntervalTimer);
  },
  created: function created() {
    this.getRealtimeOrder();
    this.setIntervalTimer = setInterval(this.getRealtimeOrder, 10 * 60 * 1000);
    // this.setIntervalTimer = setInterval(this.getRealtimeOrder, 5 * 1 * 1000)
  },
  methods: {
    getRealtimeOrder: function getRealtimeOrder() {
      var _this = this;
      // this.loading = true
      var startTime = "".concat(dayjs().format('YYYY-MM-DD'), " 00:00:00");
      var endTime = "".concat(dayjs().format('YYYY-MM-DD HH:mm:ss'));
      _getRealtimeOrder({
        startTime: startTime,
        endTime: endTime
      }).then(function (res) {
        _this.loading = false;
        if (res) {
          var echartsDate = res.body;
          _this.init(echartsDate);
        }
      });
    },
    init: function init(echartsDate) {
      var xData = this.$lodash.map(echartsDate, 'date');
      var orderCount = this.$lodash.map(echartsDate, 'orderCount');
      this.chartData = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        dataZoom: [{
          show: true,
          realtime: true,
          start: 0,
          end: 100
        }, {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: echartToolbox,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: 'value',
          name: '笔数',
          minInterval: 1,
          axisLine: {
            show: true
          }
        },
        series: [{
          name: '支付笔数',
          type: 'line',
          itemStyle: {
            normal: {
              color: '#29ba51'
            }
          },
          data: orderCount
        }]
      };
    }
  }
};