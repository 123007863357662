import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import D2PageCover from './components/d2-page-cover';
import { menuHeader } from '@/utils/menu.js';
import { mapActions } from 'vuex';
export default {
  components: {
    D2PageCover: D2PageCover
  },
  created: function created() {
    var _menuHeaderList$, _menuHeaderList$2, _menuHeaderList$3, _menuHeaderList$4, _menuHeaderList$5, _menuHeaderList$6;
    var menuHeaderList = menuHeader();
    console.log(menuHeaderList);
    this.close({
      tagName: '/index'
    });
    var path = (_menuHeaderList$ = menuHeaderList[0]) !== null && _menuHeaderList$ !== void 0 && (_menuHeaderList$ = _menuHeaderList$.children[0]) !== null && _menuHeaderList$ !== void 0 && _menuHeaderList$.children && (_menuHeaderList$2 = menuHeaderList[0]) !== null && _menuHeaderList$2 !== void 0 && _menuHeaderList$2.children[0].children.length ? (_menuHeaderList$3 = menuHeaderList[0]) === null || _menuHeaderList$3 === void 0 || (_menuHeaderList$3 = _menuHeaderList$3.children[0]) === null || _menuHeaderList$3 === void 0 || (_menuHeaderList$3 = _menuHeaderList$3.children[0]) === null || _menuHeaderList$3 === void 0 ? void 0 : _menuHeaderList$3.path : (_menuHeaderList$4 = menuHeaderList[0]) === null || _menuHeaderList$4 === void 0 ? void 0 : _menuHeaderList$4.children[0].path;
    console.log(path);
    console.log(((_menuHeaderList$5 = menuHeaderList[0]) === null || _menuHeaderList$5 === void 0 ? void 0 : _menuHeaderList$5.children[0].children) && ((_menuHeaderList$6 = menuHeaderList[0]) === null || _menuHeaderList$6 === void 0 ? void 0 : _menuHeaderList$6.children[0].children.length));
    this.$router.replace({
      path: path
    });
  },
  methods: _objectSpread({}, mapActions('d2admin/page', ['close']))
};