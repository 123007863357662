import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "no-data-text": _vm.needType && !_vm.sourceType ? "请先选择商户来源" : "无数据",
      disabled: _vm.disabled,
      filterable: "",
      placeholder: _vm.placeholder
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.currentValue,
      callback: function callback($$v) {
        _vm.currentValue = $$v;
      },
      expression: "currentValue"
    }
  }, _vm._l(_vm.filtterAgentList(), function (item) {
    return _c("el-option", {
      key: item.number,
      attrs: {
        label: item.name,
        value: item.number
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };