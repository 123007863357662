import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "duty-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "online-title",
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("span", [_vm._v("总值守时长")]), _c("el-tooltip", {
    staticClass: "online-title",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.dutyTotleTime)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "card-digit",
    attrs: {
      flex: "main:center cross:center",
      "flex-box": "1"
    }
  }, [_c("span", [_vm._v(_vm._s(Number(_vm.dutyOverview.totalDutyDuration || 0).toLocaleString() || 0) + " "), _c("span", {
    staticClass: "unit"
  }, [_vm._v("(小时)")])])])])]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", [_c("div", {
    staticClass: "duty-card"
  }, [_c("div", {
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v("总值守任务数")]), _c("el-tooltip", {
    staticClass: "online-title",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.dutyTotleOrder)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "sum borderB"
  }, [_vm._v(" " + _vm._s(_vm.dutyOverview.totalDutyCount || 0) + " ")]), _c("div", {
    staticStyle: {
      margin: "0 10px"
    },
    attrs: {
      flex: " cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v("平均每小时值守任务数 ")]), _c("span", {
    staticStyle: {
      "margin-left": "30%",
      "font-size": "25px"
    }
  }, [_vm._v(_vm._s(_vm.dutyOverview.dutyCountPerHour || 0))])])])])]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", [_c("div", {
    staticClass: "duty-card"
  }, [_c("div", {
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v("总值守任务时长")]), _c("el-tooltip", {
    staticClass: "online-title",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.totalDutyHour)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "sum borderB"
  }, [_vm._v(" " + _vm._s(_vm.dutyOverview.totalDutyHour || 0) + " "), _c("span", {
    staticClass: "unit"
  }, [_vm._v("小时")])]), _c("div", {
    staticStyle: {
      margin: "0 10px"
    },
    attrs: {
      flex: " cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v("平均每任务时长 ")]), _c("span", {
    staticClass: "online-title",
    staticStyle: {
      "margin-left": "30%",
      "font-size": "16px"
    }
  }, [_vm._v(_vm._s(_vm.dutyOverview.dutyMinutePerTask || 0) + "分钟")])])])])]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("dutyDataCard", {
    attrs: {
      title: "值守时长（昨日）",
      subTitle: "店均值守",
      infoDate: _vm.lastDuty,
      tip: _vm.homeTip.yesDutyTotleTime
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("dutyDataCard", {
    attrs: {
      title: "值守任务数（昨日）",
      subTitle: "客服平均处理任务数",
      infoDate: _vm.lastDutyCountDate,
      tip: _vm.homeTip.yesDutyTotleOrder
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("dutyDataCard", {
    attrs: {
      title: "值守任务时长（昨日）",
      unit: "小时",
      subTitle: "客服平均处理任务时长",
      infoDate: _vm.lastDutyCustomerDuration,
      tip: _vm.homeTip.lastDutyCustomerDuration
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "任务及时响应率(昨日)",
      unit: " %",
      infoDate: _vm.dutyOverview.lastDutyCustomerTimelyResponse,
      tip: _vm.homeTip.lastDutyCustomerTimelyResponse
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "任务通话比例（昨日）",
      unit: " %",
      infoDate: _vm.dutyOverview.lastDutyCustomerCall,
      tip: _vm.homeTip.lastDutyCustomerCall
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("costomerDataCard", {
    attrs: {
      title: "任务快捷播报比例（昨日）",
      unit: " %",
      infoDate: _vm.lastDutyCustomerQuickReport,
      tip: _vm.homeTip.lastDutyCustomerQuickReport
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "duty-card",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v("门店留存率")]), _c("el-tooltip", {
    staticClass: "online-title",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.storeRetentionRate)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "card-digit",
    attrs: {
      flex: "main:center cross:center",
      "flex-box": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.dutyOverview.storeRetentionRate || 0) + "% ")])])]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("dutyDataCard", {
    attrs: {
      title: "昨日门店活跃率（值守服务使用率）",
      infoDate: _vm.lastStoreActivityRateDate,
      tip: _vm.homeTip.yesStoreRetentionRate
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("customerQuality")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("customerEfficiency")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("dutySituation")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("dutyAndOrder")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("dutyStoreActivityRate")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("dutyNewlyRetention")], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("dutyRankingHours")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };