import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dutyDataCard"
  }, [_c("div", {
    attrs: {
      flex: "main:justify cross:center"
    }
  }, [_c("span", {
    staticClass: "online-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("el-tooltip", {
    staticClass: "online-title",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.tip)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("div", {
    staticClass: "sum",
    class: {
      m30: _vm.title.includes("活跃率")
    },
    attrs: {
      flex: "main:justify"
    }
  }, [_c("span", {
    staticStyle: {
      "word-break": "keep-all"
    }
  }, [_vm._v(_vm._s(_vm.infoDate.dutyDate || 0) + " "), _c("span", {
    staticClass: "unit"
  }, [_vm._v(" " + _vm._s(_vm.unit ? _vm.unit : ""))])]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticStyle: {
      "margin-right": "30px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v("日环比")]), _c("span", [_vm.infoDate.dailyBasis >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(_vm.infoDate.dailyBasis || 0) + "%")])])]), _c("div", [_c("span", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v("周环比")]), _c("span", [_vm.infoDate.weeklyBasis >= 0 ? _c("i", {
    staticClass: "el-icon-caret-top",
    staticStyle: {
      color: "#84d460"
    }
  }) : _c("i", {
    staticClass: "el-icon-caret-bottom",
    staticStyle: {
      color: "#f72029"
    }
  }), _c("span", {
    staticClass: "number-color"
  }, [_vm._v(_vm._s(_vm.infoDate.weeklyBasis || 0) + "%")])])])])]), !_vm.title.includes("活跃率") ? _c("div", {
    staticClass: "border-top",
    attrs: {
      flex: "cross:center"
    }
  }, [_c("span", {
    staticClass: "dd"
  }, [_vm._v(_vm._s(_vm.subTitle) + " ")]), _c("span", {
    staticStyle: {
      "margin-left": "20%"
    }
  }, [_vm._v(_vm._s(_vm.infoDate.averageDuration || 0))])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };