import _defineProperty from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-scrollbar", [_c("el-menu", {
    ref: "menu",
    attrs: {
      collapse: _vm.asideCollapse,
      "collapse-transition": _vm.asideTransition,
      "default-active": _vm.$route.path,
      router: ""
    },
    on: {
      select: _vm.handleMenuSelect
    }
  }, [_vm._l(_vm.aside, function (menu, index) {
    return [!menu.onlyMenu ? _c("el-submenu", {
      key: menu.path,
      attrs: {
        index: menu.path
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [menu.meta.icon ? _c("i", {
            class: _defineProperty({
              fa: true
            }, "fa-".concat(menu.meta.icon), true)
          }) : _vm._e(), menu.meta.elIcon ? _c("i", {
            class: _defineProperty({}, "el-icon-".concat(menu.meta.elIcon), true)
          }) : _vm._e(), _c("span", {
            attrs: {
              slot: "title"
            },
            slot: "title"
          }, [_vm._v(_vm._s(menu.meta.title))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(menu.children, function (menuItem) {
      return _c("el-menu-item", {
        key: menuItem.path,
        attrs: {
          index: menuItem.path
        }
      }, [menuItem.meta.icon ? _c("i", {
        class: _defineProperty({
          fa: true
        }, "fa-".concat(menuItem.meta.icon), true)
      }) : _vm._e(), menuItem.meta.elIcon ? _c("i", {
        class: _defineProperty({}, "el-icon-".concat(menuItem.meta.elIcon), true)
      }) : _vm._e(), _c("span", {
        attrs: {
          slot: "title"
        },
        slot: "title"
      }, [_vm._v(_vm._s(menuItem.meta.title))])]);
    }), 1) : _c("el-menu-item", {
      key: index,
      attrs: {
        index: menu.children[0].path
      }
    }, [menu.meta.icon ? _c("i", {
      class: _defineProperty({
        fa: true
      }, "fa-".concat(menu.meta.icon), true)
    }) : _vm._e(), menu.meta.elIcon ? _c("i", {
      class: _defineProperty({}, "el-icon-".concat(menu.meta.elIcon), true)
    }) : _vm._e(), _c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(menu.meta.title))])])];
  })], 2), _vm.aside.length === 0 && !_vm.asideCollapse ? _c("div", {
    staticClass: "d2-layout-header-aside-menu-empty",
    attrs: {
      flex: "dir:top main:center cross:center"
    }
  }, [_c("d2-icon", {
    attrs: {
      name: "inbox"
    }
  }), _c("span", [_vm._v("没有侧栏菜单")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };