var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "video-card"
  }, [_c("div", {
    staticClass: "show-title",
    attrs: {
      slot: "other-btn"
    },
    slot: "other-btn"
  }, [_vm._t("title")], 2), _vm.messagError ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.messagError && _vm.loading,
      expression: "messagError&&loading"
    }],
    staticClass: "error-mask",
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      "element-loading-text": _vm.messagError.msg + ",重新加载中"
    }
  }) : _vm._e(), _c("div", {
    ref: _vm.keyRef,
    staticClass: "video-container",
    style: {
      width: "".concat(_vm.width, "px"),
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      id: _vm.keyRef
    }
  }), _c("div", {
    staticClass: "other-btn",
    attrs: {
      slot: "other-btn"
    },
    slot: "other-btn"
  }, [_vm._t("footer")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };