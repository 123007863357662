import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
var meta = {
  parent: '/businessSystem'
}; // 顶部菜单设置

export var businessSystemRouter = [{
  path: '/businessSystem/homeManage',
  name: 'businessSystem-homeManage',
  auth: 'GJ_VENDING_HOME_MANAGER_MENU',
  meta: _objectSpread({
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '首页',
    elIcon: 'data-analysis'
  }, meta),
  redirect: {
    name: 'businessSystem-home'
  },
  component: layoutHeaderAside,
  onlyMenu: true,
  children: [{
    path: '/businessSystem/home',
    name: 'businessSystem-home',
    auth: 'GJ_VENDING_HOME_MANAGER_MENU',
    component: _import('businessSystem/home/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_HOME_MANAGER_MENU'
    }, meta), {}, {
      title: '首页',
      elIcon: 'data-analysis'
    })
  }]
}, {
  path: '/businessSystem/merchantManage',
  name: 'businessSystem-merchantManage',
  auth: 'GJ_VENDING_MERCHANT_MANAGER_MENU',
  meta: _objectSpread({
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '商户管理',
    elIcon: 'menu'
  }, meta),
  redirect: {
    name: 'taskManagement-businessman'
  },
  component: layoutHeaderAside,
  children: [{
    path: '/merchantManage/businessman',
    name: 'merchantManage-businessman',
    auth: 'GJ_VENDING_MERCHANT_MENU',
    component: _import('businessSystem/merchantManage/businessman/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_MERCHANT_SEARCH'
    }, meta), {}, {
      title: '商户信息维护',
      elIcon: 's-custom',
      keepAlive: true
    })
  }, {
    path: '/merchantManage/store',
    name: 'merchantManage-store',
    auth: 'GJ_VENDING_STORE_MENU',
    component: _import('businessSystem/merchantManage/store/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_STORE_SEARCH'
    }, meta), {}, {
      title: '门店信息维护',
      elIcon: 's-shop',
      keepAlive: true
    })
  }, {
    path: '/merchantManage/businessman/detail',
    name: 'merchantManage-businessmanDetail',
    component: _import('businessSystem/merchantManage/businessman/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '查看商户'
    })
  }, {
    path: '/merchantManage/businessman/edit',
    name: 'merchantManage-businessmanEdit',
    component: _import('businessSystem/merchantManage/businessman/edit.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '编辑商户',
      hidden: true,
      upRouter: '/merchantManage/businessman'
    })
  }, {
    path: '/merchantManage/store/detail',
    name: 'merchantManage-storeDetail',
    component: _import('businessSystem/merchantManage/store/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '查看门店'
    })
  }, {
    path: '/merchantManage/store/edit',
    name: 'merchantManage-storeEdit',
    component: _import('businessSystem/merchantManage/store/edit.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '编辑门店',
      hidden: true,
      upRouter: '/merchantManage/store'
    })
  }, {
    path: '/merchantManage/store/storeConfig',
    name: 'merchantManage-storeConfig',
    component: _import('businessSystem/merchantManage/store/storeConfig.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店配置',
      hidden: true,
      upRouter: '/merchantManage/store'
    })
  }, {
    path: '/merchantManage/store/dutyRecords',
    name: 'merchantManage-dutyRecords',
    component: _import('businessSystem/merchantManage/store/dutyRecords.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '值守记录'
    })
  }]
}, {
  path: '/businessSystem/orderManage',
  name: 'businessSystem-orderManage',
  auth: 'GJ_VENDING_ORDER_MANAGER_MENU',
  meta: _objectSpread({
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '订单管理',
    elIcon: 'notebook-1'
  }, meta),
  redirect: {
    name: 'orderManage-orderList'
  },
  component: layoutHeaderAside,
  children: [{
    path: '/orderManage/orderList',
    name: 'orderManage-orderList',
    auth: 'GJ_VENDING_ORDER_LIST_MENU',
    component: _import('businessSystem/orderManage/orderList/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_ORDER_LIST_SEARCH'
    }, meta), {}, {
      title: '订单列表',
      elIcon: 's-order',
      keepAlive: true
    })
  }]
}, {
  path: '/businessSystem/taskManagement',
  name: 'businessSystem-taskManagement',
  auth: 'GJ_VENDING_TASK_CENTER_MENU',
  meta: _objectSpread({
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '任务中心',
    icon: 'tasks'
  }, meta),
  redirect: {
    name: 'taskManagement-workbench'
  },
  component: layoutHeaderAside,
  children: [{
    path: '/taskManagement/workbenchv',
    name: 'taskManagement-workbench',
    auth: 'GJ_VENDING_WORK_TABLE_MENU',
    component: _import('businessSystem/taskManagement/workbench/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_WORK_TABLE_SEARCH'
    }, meta), {}, {
      title: '工作台',
      icon: 'sitemap'
    })
  }, {
    path: '/taskManagement/workbenchDetail',
    name: 'taskManagement-workbenchDetail',
    component: _import('businessSystem/taskManagement/workbench/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '工详情',
      hidden: true,
      upRouter: '/taskManagement/workbenchv'
    })
  }, {
    path: '/taskManagement/taskInfo',
    name: 'taskManagement-taskInfo',
    auth: 'GJ_VENDING_DUTY_TASK_MENU',
    component: _import('businessSystem/taskManagement/taskInfo/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_DUTY_TASK_SEARCH'
    }, meta), {}, {
      title: '任务信息查询',
      icon: 'search',
      keepAlive: true
    })
  }, {
    path: '/taskManagement/taskInfo/detail',
    name: 'taskManagement-taskInfoDetail',
    component: _import('businessSystem/taskManagement/taskInfo/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '任务详情',
      icon: 'search'
    })
  }, {
    path: '/taskManagement/taskInfo/liveRoom',
    name: 'taskManagement-liveRoom',
    component: _import('businessSystem/taskManagement/taskInfo/liveRoom.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '门店监控',
      icon: 'search'
    })
  }, {
    path: '/taskManagement/taskInfo/backLive',
    name: 'taskManagement-backLive',
    component: _import('businessSystem/taskManagement/taskInfo/backLive.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '视频回放',
      icon: 'search'
    })
  }]
}, {
  path: '/businessSystem/permissionManage',
  name: 'businessSystem-permissionManage',
  auth: 'GJ_VENDING_AUTH_MANAGER_MENU',
  meta: {
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '权限管理',
    elIcon: 's-tools'
  },
  redirect: {
    name: 'permissionManage-user'
  },
  component: layoutHeaderAside,
  children: [{
    path: '/permissionManage/user',
    name: 'permissionManage-user',
    auth: 'GJ_VENDING_USER_MENU',
    component: _import('businessSystem/permissionManage/user/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_USER_SEARCH'
    }, meta), {}, {
      title: '用户维护',
      elIcon: 'user-solid'
    })
  }, {
    path: '/permissionManage/role',
    name: 'permissionManage-role',
    auth: 'GJ_VENDING_ROLE_MENU',
    component: _import('businessSystem/permissionManage/role/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_ROLE_SEARCH'
    }, meta), {}, {
      title: '角色维护',
      elIcon: 'connection',
      keepAlive: true
    })
  }, {
    path: '/permissionManage/role/roleDetail',
    name: 'permissionManage-roleDetail',
    component: _import('businessSystem/permissionManage/role/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增角色',
      hidden: true,
      upRouter: '/permissionManage/role'
    })
  }, {
    path: '/permissionManage/auth',
    name: 'permissionManage-auth',
    auth: 'GJ_VENDING_RESOURCE_MENU',
    component: _import('businessSystem/permissionManage/auth/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_RESOURCE_SEARCH'
    }, meta), {}, {
      title: '权限维护',
      elIcon: 's-check'
    })
  }, {
    path: '/permissionManage/dataRule',
    name: 'permissionManage-dataRule',
    auth: 'GJ_VENDING_DATA_RULE_MENU',
    component: _import('businessSystem/permissionManage/dataRule/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_DATA_RULE_SEARCH'
    }, meta), {}, {
      title: '数据权限维护',
      elIcon: 's-operation'
    })
  }, {
    path: '/permissionManage/tagManage',
    name: 'permissionManage-tagManage',
    auth: 'GJ_VENDING_TAG_MENU',
    component: _import('businessSystem/permissionManage/tagManage/index.vue'),
    meta: _objectSpread(_objectSpread({
      authPage: 'GJ_VENDING_TAG_MENU'
    }, meta), {}, {
      title: '标签管理',
      elIcon: 'price-tag'
    })
  }]
}];
export var businessSystemMenu = {
  path: '/businessSystem',
  name: 'businessSystem',
  auth: 'GJ_VENDING_BUSINESS_SYSTEM_MENU',
  meta: {
    authPage: 'GJ_VENDING_MERCHANT_MENU',
    title: '业务系统',
    icon: 'plug'
  },
  redirect: {
    name: 'merchantManage-businessman'
  },
  component: layoutHeaderAside,
  children: businessSystemRouter
};