import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.includes.js";
import { uniqueId } from 'lodash';

// 业务系统
// import businessSystem from './modules/businessSystem'
import { businessSystemMenu } from '@/router/modules/businessSystem.js';
// import home from './modules/home'
import store from '@/store';
function supplementPathq(menu, muenList) {
  var filterList = [];
  muenList = Array.isArray(muenList) ? muenList : [];
  menu.map(function (e) {
    if (muenList.includes(e.auth)) {
      filterList.push(_objectSpread(_objectSpread({}, e), {}, {
        path: e.path || uniqueId('d2-menu-empty-')
      }, e.children ? {
        children: supplementPathq(e.children, muenList)
      } : {}));
    }
  });
  return filterList;
}
// // 菜单 侧边栏
// export const menuAside = supplementPathq([
//   // home,
//   businessSystem
// ])

// 菜单 顶栏
export var menuHeader = function menuHeader() {
  var muenLists = store.state.d2admin.user.resourceList; // ['editBtn', 'commitBtn']
  return supplementPathq([
  // home,
  businessSystemMenu], muenLists);
};