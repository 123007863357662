export default {
  namespaced: true,
  state: {
    // 用户信息
    taskResident: {},
    newTask: {},
    wsCall: {},
    newNotifyListVuex: {},
    callNotifyListVuex: {},
    secordCallInfo: {},
    taskListInfo: {},
    taskTransfer: {},
    isOnline: '',
    taskListLen: {},
    workChange: {
      store_device_status_change: {},
      duty_new_order: {},
      duty_status_change: {}
    },
    tagChange: {},
    wsOnCalling: false
  },
  actions: {
    setTagChange: function setTagChange(_ref, tagChange) {
      var state = _ref.state,
        dispatch = _ref.dispatch;
      state.tagChange = tagChange;
    },
    setWorkChange: function setWorkChange(_ref2, workChange) {
      var state = _ref2.state,
        dispatch = _ref2.dispatch;
      state.workChange = workChange;
    },
    reSetWorkChange: function reSetWorkChange(_ref3) {
      var state = _ref3.state,
        dispatch = _ref3.dispatch;
      state.workChange = {
        store_device_status_change: {},
        duty_new_order: {},
        duty_status_change: {}
      };
    },
    setTaskTransfer: function setTaskTransfer(_ref4, taskTransfer) {
      var state = _ref4.state,
        dispatch = _ref4.dispatch;
      state.taskTransfer = taskTransfer;
    },
    setTaskResident: function setTaskResident(_ref5, taskResident) {
      var state = _ref5.state,
        dispatch = _ref5.dispatch;
      state.taskResident = taskResident;
    },
    setNewTask: function setNewTask(_ref6, _setNewTask) {
      var state = _ref6.state,
        dispatch = _ref6.dispatch;
      state.newTask = _setNewTask;
    },
    setCall: function setCall(_ref7, wsCall) {
      var state = _ref7.state,
        dispatch = _ref7.dispatch;
      state.wsCall = wsCall;
    },
    setIsOnline: function setIsOnline(_ref8, taskListInfo) {
      var state = _ref8.state,
        dispatch = _ref8.dispatch;
      state.taskListInfo = taskListInfo;
    },
    setOnCalling: function setOnCalling(_ref9, wsOnCalling) {
      var state = _ref9.state,
        dispatch = _ref9.dispatch;
      state.wsOnCalling = wsOnCalling;
    },
    setNewNotifyList: function setNewNotifyList(_ref10, listInfo) {
      var state = _ref10.state,
        dispatch = _ref10.dispatch;
      state[listInfo.type + 'Vuex'] = listInfo.list;
    },
    setSecordCallInfo: function setSecordCallInfo(_ref11, secordCallInfo) {
      var state = _ref11.state,
        dispatch = _ref11.dispatch;
      state.secordCallInfo = secordCallInfo;
    }
  }
};