import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { gateStatusListType, lockStatusListType, mainControlStatusType, payStatusType } from '@/config/dataDictionary.js';
import { add } from 'lodash';
export default {
  props: {
    currentStore: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    panorama: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    height: {
      type: Number
    },
    wbMainoffsetWidth: {
      type: Number
    },
    screenScale: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      gateStatusListType: gateStatusListType,
      lockStatusListType: lockStatusListType,
      mainControlStatusType: mainControlStatusType,
      payStatusType: payStatusType,
      isOpen: null
    };
  },
  watch: {
    orderStatus: {
      handler: function handler(orderStatus) {
        if (orderStatus === 'no_pay') {
          this.isOpen = true;
        } else {
          this.isOpen = false;
        }
      },
      deep: true,
      // 深度监听
      immediate: true // 初始化监听
    }
  },
  computed: {
    mainControlStatusObj: function mainControlStatusObj() {
      var mainControlStatusObj = {};
      // 获取门锁主控的对象枚举
      this.currentStore.mainControlStatus.map(function (i) {
        mainControlStatusObj[i.serialNumber] = i;
      });
      return mainControlStatusObj;
    },
    needIndex: function needIndex() {
      return this.currentStore.mainControlStatus.length > 1;
    },
    getFisrtGood: function getFisrtGood() {
      var _ref = this.getOrderList.productInfoList[0] || {},
        productName = _ref.productName,
        buyCount = _ref.buyCount,
        buyerPayAmount = _ref.buyerPayAmount;
      return "".concat(productName, "  ").concat(buyCount, "\u4EF6 ").concat(buyerPayAmount, "\u5143");
    },
    getOrderList: function getOrderList() {
      var _this$currentStore$or;
      return ((_this$currentStore$or = this.currentStore.orderInfo) === null || _this$currentStore$or === void 0 ? void 0 : _this$currentStore$or.dutyOrderInfoList[0]) || null;
    },
    orderStatus: function orderStatus() {
      var _this$currentStore$or2;
      return ((_this$currentStore$or2 = this.currentStore.orderInfo) === null || _this$currentStore$or2 === void 0 || (_this$currentStore$or2 = _this$currentStore$or2.dutyOrderInfoList[0]) === null || _this$currentStore$or2 === void 0 ? void 0 : _this$currentStore$or2.orderStatus) || null;
    },
    scaleStyle: function scaleStyle() {
      if (this.screenScale === 1) {
        return {
          fontSize: '14px'
        };
      } else {
        return {
          fontSize: '12px'
        };
      }
    }
  },
  mounted: function mounted() {
    var _this$currentStore$or3;
    this.open = ((_this$currentStore$or3 = this.currentStore.orderInfo) === null || _this$currentStore$or3 === void 0 || (_this$currentStore$or3 = _this$currentStore$or3.dutyOrderInfoList[0]) === null || _this$currentStore$or3 === void 0 ? void 0 : _this$currentStore$or3.orderStatus) === 'no_pay';
  },
  created: function created() {},
  components: {},
  methods: {
    getOrderStatusBg: function getOrderStatusBg() {
      var bgList = {
        no_pay: 'no_pay',
        finish: 'finish'
      };
      return this.orderStatus ? bgList[this.orderStatus] || 'hasOrder' : '';
    },
    calculateTotalSum: function calculateTotalSum(array) {
      return array.reduce(function (accumulator, currentItem) {
        return add(accumulator, currentItem.buyCount);
      }, 0);
    }
  },
  beforeDestroy: function beforeDestroy() {}
};