import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.includes.js";
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-layout-header-aside-menu-header',
  data: function data() {
    return {
      active: '',
      muenLists: ['permissionManage', 'user', 'store-home', 'merchantManageHome']
    };
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['aside', 'asideCollapse', 'asideTransition'])),
  watch: {
    $route: {
      handler: function handler(val) {
        this.active = val.path;
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/menu', ['asideCollapseToggle'])), {}, {
    isAuth: function isAuth(auth) {
      // const common = this.muenLists.filter(x => auth.includes(x))
      return this.muenLists.includes(auth);
    },
    handleMenuSelect: function handleMenuSelect(key, keyPath) {
      console.log(key);
      this.$router.push({
        path: key
      });
    },
    handleOpen: function handleOpen(key, keyPath) {
      this.asideCollapseToggle();
    },
    handleClose: function handleClose(key, keyPath) {
      this.asideCollapseToggle();
    }
  })
};