import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import vueThis from '@/main';
function getNowFormatDate() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var date = new Date();
  var year = date.getFullYear(); // 获取完整的年份(4位)
  var strDate = date.getDate(); //  获取当前日(1-31)
  var month = date.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
  if (month < 10) month = "0".concat(month); // 如果月份是个位数，在前面补0
  if (strDate < 10) strDate = "0".concat(strDate); // 如果日是个位数，在前面补0
  return "".concat(year).concat(type).concat(month).concat(type).concat(strDate);
}
// 判断对象类型
function typeOf(obj) {
  var toString = Object.prototype.toString;
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  };
  return map[toString.call(obj)];
}

// 深度拷贝
function deepCopy(data) {
  var t = typeOf(data);
  var o;
  if (t === 'array') {
    o = [];
  } else if (t === 'object') {
    o = {};
  } else {
    return data;
  }
  if (t === 'array') {
    for (var i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]));
    }
  } else if (t === 'object') {
    for (var _i in data) {
      o[_i] = deepCopy(data[_i]);
    }
  }
  return o;
}
function formatSecord(seconds) {
  var hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
  seconds -= 3600 * hour;
  var min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
  seconds -= 60 * min;
  var sec = seconds >= 10 ? seconds : '0' + seconds;
  return hour + ':' + min + ':' + sec;
}
function isUndefined(value) {
  return value === 'undefined';
}
function markTrackEvent(category, action, label, value) {
  if (['test', 'prod'].includes(process.env.VUE_APP_EVN)) {
    try {
      vueThis.$uweb.trackEvent(category, action, label, value);
      vueThis.$ba.trackEvent(category, action, label, value);
    } catch (error) {
      console.log('埋点', error);
    }
  }
}
function dispatchEventStroage() {
  var signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    var setEvent = new Event('setItemEvent');
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    signSetItem.apply(this, arguments);
  };
}
export { typeOf, deepCopy, getNowFormatDate, formatSecord, isUndefined, markTrackEvent, dispatchEventStroage };