import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.reflect.has.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import EZUIKit from 'ezuikit-js';
import dayjs from 'dayjs';
import { liveThemeData, ysErrorCode } from '@/config/dataDictionary';
var player = 'wordLive';
export default {
  name: 'videoCard',
  props: {
    liveUrl: String,
    videoInfo: Object,
    keyRef: String,
    talkTaggle: String,
    // 为了确保的确被关闭了声音
    // index: String,
    index: [Number, String],
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    curentIndex: [Number, String],
    ysToken: String,
    isStatus: Boolean,
    isTaskList: Boolean
  },
  data: function data() {
    var _ref;
    return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, player + this.keyRef, null), "messagError", null), "loading", false), "vidoeLoading", false), "startTime", 0), "count", 1), "successTime", ''), "cameraStatus", null), "showVideo", true), "option", {}), _defineProperty(_ref, "liveUrlLive", '');
  },
  watch: {
    height: function height(val) {
      if (val && this.width) {
        try {
          this[player + this.keyRef].reSize(this.width, val);
        } catch (error) {}
      }
    },
    talkTaggle: function talkTaggle(val) {
      if (!val) {
        console.log('关闭语音');
        this.ezopenStopTalk();
      }
    },
    curentIndex: function curentIndex(val) {
      // console.log(this.index)
      // setTimeout(() => {
      //   if (this.index === val) {
      //     this.stop()
      //   } else {
      //     this.play()
      //   }
      // }, 1000)
    }
  },
  created: function created() {
    this.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
  },
  mounted: function mounted() {
    this.liveUrlLive = this.liveUrl;
    this.initVideo();
  },
  methods: {
    initVideo: function initVideo() {
      var _this = this;
      var that = this;
      var option = {
        id: that.keyRef,
        // 视频容器ID
        accessToken: this.ysToken,
        url: this.liveUrlLive,
        // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
        // template: 'pcRec-pc',
        audio: false,
        plugin: ['talk'],
        // 加载插件，talk-对讲
        width: this.width,
        height: this.height,
        themeData: liveThemeData,
        // staticPath: '/',
        handleSuccess: function handleSuccess() {
          _this.cameraStatus = 'online';
          _this.messagError = null;
          if (_this.count === 1) {
            var successEndTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
            _this.successTime = dayjs(successEndTime).diff(dayjs(_this.startTime), 'second');
            console.log(that.keyRef, _this.successTime);
            // this.$markTrackEvent(`${that.videoInfo.serialNumber}`, '时长', that.videoInfo.storeName, this.successTime)
          }
          _this.count = _this.count + 1;
          _this.loading = false;
          // if (this.index === this.curentIndex) {
          //   setTimeout(() => {
          //     console.log(this.index === this.curentIndex)
          //     this.stop()
          //   }, 3000)
          // }
          _this.$emit('handleError', {
            errorRef: _this.keyRef,
            errorValue: null
          });
          // this.$ba.trackEvent('工作台', '视频加载时长', that.videoInfo.serialNumber, this.successTime)
          // console.log(successTime)
        },
        handleError: function handleError(res) {
          console.log([5405, 6519, 5404].includes(res.retcode));
          if ([5405, 6519, 5404].includes(res.retcode)) {
            _this.cameraStatus = 'offline';
          }
          var errorEndTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
          var errorTime = dayjs(errorEndTime).diff(dayjs(_this.startTime), 'second');
          console.log(that.keyRef, errorTime);
          // _hmt.push(['_trackEvent', '工作台', '视频错误码', that.keyRef, res.retcode])
          // window._czc.push(['_trackEvent', '工作台', '视频错误码' + that.keyRef, res.retcode])
          // 6519 设备端断流 重新加载 https://open.ys7.com/help/37
          if (Reflect.has(ysErrorCode, res.retcode)) {
            var _res$msg, _res$msg2;
            res.msg = (_res$msg = res.msg) === null || _res$msg === void 0 ? void 0 : _res$msg.replace('，刷新重试', '');
            res.msg = (_res$msg2 = res.msg) === null || _res$msg2 === void 0 ? void 0 : _res$msg2.replace('，请重试', '');
            _this.messagError = res;
            _this.loading = true;
            _this[player + _this.keyRef].stop();
            _this[player + _this.keyRef].play();
          }
          // if(res)
          _this.$emit('handleError', _objectSpread(_objectSpread({}, res), {}, {
            errorRef: _this.keyRef,
            errorValue: _this.keyRef
          }));
          _this.$markTrackEvent("".concat(that.videoInfo.serialNumber), '错误码', that.videoInfo.storeName, res.retcode);
        }
      };
      this[player + this.keyRef] = new EZUIKit.EZUIKitPlayer(option);
      this.option = option;
    },
    reset: function reset() {
      this.vidoeLoading = false;
      this[player + this.keyRef].stop();
      this[player + this.keyRef].changePlayUrl(_objectSpread({}, this.option));
      this[player + this.keyRef].play();
      // this[player + this.keyRef] = null
      // this.initVideo()
    },
    changePlayUrl: function changePlayUrl(optionUrl) {
      this.liveUrlLive = optionUrl.url;
      this.option.url = optionUrl.url;
      this.reset();
      // var playPromise = this[player + this.keyRef].changePlayUrl({ ...this.option, ...option })
      // playPromise.then((data) => {
      //   console.log('promise 获取 数据', data)
      // })
    },
    // 开始播放
    play: function play() {
      this.loading = true;
      var playPromise = this[player + this.keyRef].play();
      playPromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    // 停止播放
    stop: function stop() {
      var stopPromise = this[player + this.keyRef].stop();
      stopPromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    getOSDTime: function getOSDTime() {
      console.log(this[player + this.keyRef].getOSDTime());
      var getOSDTimePromise = this[player + this.keyRef].getOSDTime();
      getOSDTimePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    capturePicture: function capturePicture() {
      var capturePicturePromise = this[player + this.keyRef].capturePicture("".concat(new Date().getTime()));
      capturePicturePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    openSound: function openSound() {
      var openSoundPromise = this[player + this.keyRef].openSound();
      openSoundPromise.then(function (data) {
        console.log('promise 关闭声音', data);
      });
    },
    closeSound: function closeSound() {
      var openSoundPromise = this[player + this.keyRef].closeSound();
      openSoundPromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    startSave: function startSave() {
      var startSavePromise = this[player + this.keyRef].startSave("".concat(new Date().getTime()));
      startSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    stopSave: function stopSave() {
      var stopSavePromise = this[player + this.keyRef].stopSave();
      stopSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    ezopenStartTalk: function ezopenStartTalk() {
      // this[player + this.keyRef].openSound()
      this[player + this.keyRef].startTalk();
    },
    ezopenStopTalk: function ezopenStopTalk() {
      // this[player + this.keyRef].closeSound()
      try {
        this[player + this.keyRef].stopTalk();
      } catch (error) {
        this.$emit('closeVideo');
      }
    },
    fullScreen: function fullScreen() {
      var fullScreen = this[player + this.keyRef].fullScreen();
      fullScreen.then(function (data) {});
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this[player + this.keyRef]) {
      try {
        this.ezopenStopTalk();
        this[player + this.keyRef].stop();
        this[player + this.keyRef].destroy();
        this[player + this.keyRef] = null;
      } catch (error) {}
    }
  }
};