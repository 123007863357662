import { hasPermission } from '@/utils/extraAuth';

// Creat by Chiusinchan
export default (function (Vue) {
  Vue.directive('auth', {
    inserted: function inserted(el, _ref) {
      var value = _ref.value;
      if (!hasPermission(value)) {
        el.parentNode.removeChild(el);
      }
    }
  });
});