import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import storeOverview from './storeOverview';
import dutyOverview from './dutyOverview';
import customerview from './customerview';
export default {
  components: {
    dutyOverview: dutyOverview,
    storeOverview: storeOverview,
    customerview: customerview
  },
  data: function data() {
    return {
      radioList: [{
        label: 'customerview',
        value: '顾客数据',
        auth: 'GJ_VENDING_HOME_CUSTOMERVIEW'
      }, {
        label: 'dutyOverview',
        value: '值守数据',
        auth: 'GJ_VENDING_HOME_DUTYOVERVIEW'
      }, {
        label: 'storeOverview',
        value: '门店数据',
        auth: 'GJ_VENDING_HOME_STOREOVERVIEW'
      }],
      radioListShow: [],
      dataType: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.radioListShow = this.radioList.filter(function (x) {
      return _this.$isHasAuth([x.auth]);
    });
    this.dataType = this.radioListShow[0].label;
  },
  methods: {}
};