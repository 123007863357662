var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-radio-group", {
    staticClass: "radio-group",
    model: {
      value: _vm.dataType,
      callback: function callback($$v) {
        _vm.dataType = $$v;
      },
      expression: "dataType"
    }
  }, _vm._l(_vm.radioListShow, function (item, index) {
    return _c("el-radio-button", {
      key: index,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1), _vm.dataType === "storeOverview" ? _c("div", [_c("storeOverview")], 1) : _vm._e(), _vm.dataType === "dutyOverview" ? _c("div", [_c("dutyOverview")], 1) : _vm._e(), _vm.dataType === "customerview" ? _c("div", [_c("customerview")], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };