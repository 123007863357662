import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import dayjs from 'dayjs';
var downloadMixin = {
  methods: {
    downloadFile: function downloadFile(url, fileName) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        _this.$axios.get(url, {
          responseType: 'blob'
        }).then(function (res) {
          if (res.type === 'application/json') {
            var reader = new FileReader();
            reader.onload = function (e) {
              var result = JSON.parse(e.target.result);
              if (result.error && result.error !== 0) {
                _this.$message.error(result.message);
              }
              resolve(res);
            };
            reader.readAsText(res);
          } else {
            _this.saveFile(res, fileName);
            resolve(res);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    saveFile: function saveFile(response, fileName) {
      var queryTime = dayjs(new Date()).format('YYYY-MM-DD HH.mm.ss');
      var name = fileName || "file_".concat(queryTime, ".xlsx");
      var blob = new Blob([response], {
        type: response.type
      });
      this.saveBlob(blob, name);
    },
    saveToImage: function saveToImage(body, fileName) {
      var raw = window.atob(body);
      var rawLength = raw.length;
      var response = new Uint8Array(rawLength);
      for (var i = 0; i < rawLength; ++i) {
        response[i] = raw.charCodeAt(i);
      }
      this.saveFile(response, fileName);
    },
    saveBlob: function saveBlob(blob, name) {
      var queryTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
      var fileName = name || "file_".concat(queryTime, ".csv");
      var urlObject = window.URL || window.webkitURL || window;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        var alink = document.createElement('a');
        alink.download = fileName;
        // const binaryData = []
        // binaryData.push(blob)
        alink.href = urlObject.createObjectURL(new Blob([blob]));
        // alink.href = urlObject.createObjectURL(blob)
        alink.click();
        urlObject.revokeObjectURL(alink.href);
      }
    }
  }
};
export default downloadMixin;