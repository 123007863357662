import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.loading,
      expression: "loading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    staticClass: "page"
  }, [_c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      "text-align": "center",
      position: "relative"
    }
  }, [_c("el-button", {
    staticClass: "back info",
    staticStyle: {
      position: "absolute",
      left: "10px",
      top: "10px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _c("span", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "600"
    }
  }, [_vm._v("任务详情")])], 1), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("任务信息")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-form", {
    staticClass: "demo-form-inline",
    attrs: {
      inline: true,
      "label-width": "100px",
      "label-position": "top",
      size: "small"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务编码:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.taskNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "taskNumber", $$v);
      },
      expression: "taskInfo.taskNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否购物:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.purchase,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "purchase", $$v);
      },
      expression: "taskInfo.purchase"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关闭任务方式:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.closeMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "closeMethod", $$v);
      },
      expression: "taskInfo.closeMethod"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "创建时间:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.createTime,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "createTime", $$v);
      },
      expression: "taskInfo.createTime"
    }
  })], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "处理完成时间:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.finishTime,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "finishTime", $$v);
      },
      expression: "taskInfo.finishTime"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务处理人:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.handleUser,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "handleUser", $$v);
      },
      expression: "taskInfo.handleUser"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务状态:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.statusText,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "statusText", $$v);
      },
      expression: "taskInfo.statusText"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务标签:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.taskInfo.tagsList,
      callback: function callback($$v) {
        _vm.$set(_vm.taskInfo, "tagsList", $$v);
      },
      expression: "taskInfo.tagsList"
    }
  })], 1)], 1)], 1)], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("用户进店记录")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      data: _vm.visitList,
      "max-height": 300
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "手机号",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "entranceTime",
      label: "进店时间",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "leaveTime",
      label: "离店时间",
      width: "180"
    }
  })], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("用户支付订单")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      border: "",
      data: _vm.payOrderList,
      "max-height": 300
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "openId",
      label: "用户openid",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "settlementAmount",
      label: "支付金额",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "payWay",
      label: "支付方式",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_vm._v(_vm._s(_vm.payWayType[row.payWay] || row.payWay))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "discountAmount",
      label: "优惠金额",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "payTime",
      label: "支付成功时间",
      width: "180"
    }
  })], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("门店信息")])]), _c("div", {
    staticClass: "text item"
  }, [_c("el-form", {
    staticClass: "demo-form-inline",
    attrs: {
      inline: true,
      model: _vm.storeVo,
      "label-width": "110px",
      "label-position": "top",
      size: "small"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店归属商户:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.merchantName,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "merchantName", $$v);
      },
      expression: "storeVo.merchantName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店名称:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.name,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "name", $$v);
      },
      expression: "storeVo.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店负责人名称:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.managerName,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "managerName", $$v);
      },
      expression: "storeVo.managerName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店负责人电话:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.managerPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "managerPhone", $$v);
      },
      expression: "storeVo.managerPhone"
    }
  })], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店所在省市区:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.addressPc,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "addressPc", $$v);
      },
      expression: "storeVo.addressPc"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "门店详细地址:"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.storeVo.address,
      callback: function callback($$v) {
        _vm.$set(_vm.storeVo, "address", $$v);
      },
      expression: "storeVo.address"
    }
  })], 1)], 1)], 1)], 1)], 1)]), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("关联音视频")])]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "text item"
  }, [_c("i", {
    staticClass: "el-icon-video-camera-solid",
    staticStyle: {
      "font-size": "25px"
    }
  }), _c("span", {
    staticStyle: {
      margin: "0 20px 0 10px"
    }
  }, [_vm._v(_vm._s(_vm.taskInfo.status === "handling" ? "视频直播中" : "视频文件"))]), ![null, "null"].includes(_vm.taskInfo.status) ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onTaskVideo
    }
  }, [_vm._v(_vm._s(_vm.taskInfoStatusText[_vm.taskInfo.status]))]) : _vm._e()], 1), _c("el-button", {
    staticClass: "back info",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };