import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rtc-container"
  }, [_c("div", {
    staticClass: "local-stream-content",
    attrs: {
      id: "localStream"
    }
  }), _c("div", {
    staticClass: "op-wrap"
  }, [_vm.callStatus === "on_call" ? _c("div", {
    staticClass: "mic-testing-container"
  }, [_c("div", {
    staticClass: "mic-bar-container"
  }, _vm._l(new Array(20).fill(""), function (item, index) {
    return _c("div", {
      key: index,
      class: ["mic-bar ".concat(_vm.volumeNum > index && "active")]
    });
  }), 0), _c("div", {
    attrs: {
      id: "audio-container"
    }
  })]) : _vm._e(), !["on_call"].includes(_vm.callStatus) ? _c("div", {
    staticClass: "calling"
  }, [_vm._v("正在建立连接...")]) : _vm._e(), _vm.callStatus === "on_call" ? _c("div", {
    staticClass: "on_call"
  }, [_vm._v("【" + _vm._s(_vm.callCurentInfo.storeName) + "】通话中 " + _vm._s(_vm.callAllTime) + " ")]) : _vm._e()]), _c("el-button", {
    staticClass: "button",
    attrs: {
      type: "danger",
      size: "small"
    },
    on: {
      click: _vm.closeTrtc
    }
  }, [_vm._v("挂断")]), _c("div", {
    staticClass: "remote-container"
  }, _vm._l(_vm.remoteStreamList, function (item) {
    return _c("div", {
      key: item.getUserId(),
      staticClass: "remote-stream-container",
      attrs: {
        id: item.getUserId()
      }
    });
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };