export default {
  name: 'orderSuccess',
  props: {},
  data: function data() {
    return {
      showModal: true,
      interval: null,
      countdown: 5 // 初始倒计时秒数
    };
  },
  watch: {},
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown: function startCountdown() {
      var _this = this;
      this.interval = setInterval(function () {
        if (_this.countdown > 0) {
          _this.countdown--;
        } else {
          clearInterval(_this.interval); // 停止倒计时
          _this.closeModal();
        }
      }, 1000); // 每秒执行一次
    },
    closeModal: function closeModal() {
      this.$emit('closeModal');
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.interval); // 停止倒计时
  }
};