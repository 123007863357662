var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "v_coBox",
    on: {
      mouseover: function mouseover($event) {
        _vm.moveIn = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.moveIn = false;
      }
    }
  }, [_c("video", {
    ref: "vPlay",
    staticClass: "pvideo",
    attrs: {
      preload: "",
      oncanplay: "",
      controls: "",
      src: _vm.liveUrl,
      "webkit-playsinline": "",
      disablePictureInPicture: "",
      playsinline: ""
    }
  }), _c("span", {
    staticClass: "deviceSerial"
  }, [_vm._v("设备号：" + _vm._s(_vm.deviceSerial))]), _vm.showPause && !_vm.canPlay ? _c("i", {
    staticClass: "el-icon-video-play spImg",
    on: {
      click: _vm.play
    }
  }) : _vm._e(), _vm.canPlay ? _c("span", {
    staticClass: "spImg canPlay"
  }, [_vm._v(_vm._s(_vm.canPlay))]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };