import _objectSpread from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import * as echarts from 'echarts';
// import echarts from 'echarts/lib/echarts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/toolbox'
// import 'echarts/lib/component/dataZoom'
import macarons from 'echarts/theme/macarons';
import { cloneDeep } from 'lodash';
export default {
  name: 'LineChart',
  props: {
    chartData: {
      type: [Object, Array],
      default: function _default() {
        return [];
      }
    },
    chartColor: {
      type: [Object, Array],
      default: function _default() {
        return ['#4ea397', '#516b91', '#4ea397', '#75bedc', '#3fb1e3', '#6be6c1', '#ef6567', '#a0a7e6', '#91cd77', '#f9c956', '#5470c6', '#96dee8'];
      }
    },
    // 把data转化为option的方法
    optionMethod: {
      type: Function,
      default: function _default(para) {
        return {};
      }
    },
    height: {
      type: String,
      default: '500px'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data: function data() {
    return {
      chart: null
      // chartStyle: {
      //   // width: '100%',
      //   width: this.width,
      //   height: this.height
      // }
    };
  },
  computed: {
    domWidth: function domWidth() {
      return this.width;
    }
  },
  watch: {
    chartData: function chartData(val) {
      var iVal = cloneDeep(val);
      var option = _objectSpread(_objectSpread({}, iVal), {}, {
        color: this.chartColor
      });
      this.setOption(option);
    }
  },
  mounted: function mounted() {
    this.initChart();
    var option = _objectSpread(_objectSpread({}, this.chartData), {}, {
      color: this.chartColor
    });
    this.setOption(option);
  },
  beforeDestroy: function beforeDestroy() {
    this.chart.clear();
    // this.chartData.dispose()
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      this.chart = echarts.init(this.$refs['chart']);
      window.addEventListener('resize', function () {
        _this.chart.resize();
      }); // 自适应伸缩
    },
    setOption: function setOption(option) {
      // this.chart.clear()
      this.chart.setOption(option);
    }
  }
};