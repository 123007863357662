var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-around"
    }
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _c("div", [_c("h4", [_vm._v('垂直水平居中(flex="main:center cross:center")')]), _vm._m(4), _c("h4", [_vm._v('垂直水平居中(flex="dir:top main:center cross:center ")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "dir:top main:center cross:center box:justify"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue",
      "overflow-y": "auto"
    }
  }, _vm._l(100, function (i, index) {
    return _c("p", {
      key: index
    }, [_vm._v(_vm._s(index))]);
  }), 0), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h4", [_vm._v('从上到下(flex="dir:top")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('从右到左(flex="dir:right")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "dir:right"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('从下到上(flex="dir:bottom")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "dir:bottom"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('从左到右（默认）(flex="dir:left")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "dir:left"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h4", [_vm._v('从右到左(flex="main:right")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "main:right"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('从左到右（默认）(flex="main:left")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "main:left"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v("两端对齐")]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "main:justify"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('居中对齐( flex="main:center")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "main:center"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h4", [_vm._v('从上到下（默认）( flex="cross:top")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "cross:top"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('从下到上(flex="cross:bottom")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "cross:bottom"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('基线对齐(flex="cross:baseline")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "cross:baseline"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      "font-size": "30px",
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      "font-size": "12px",
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      "font-size": "40px",
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('居中对齐(flex="cross:center")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "cross:center"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('高度并排铺满(flex="cross:stretch")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "cross:stretch"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h4", [_vm._v('子元素平分空间( flex="box:mean")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "box:mean"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('第一个子元素不要多余空间，其他子元素平分多余空间(flex="box:first")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "box:first"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('最后一个子元素不要多余空间，其他子元素平分多余空间(flex="box:last")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "box:last"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v('两端第一个元素不要多余空间，其他子元素平分多余空间(flex="box:justify")')]), _c("div", {
    staticClass: "box",
    attrs: {
      flex: "box:justify"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]), _c("h4", [_vm._v("flex-box实现两端不需要多余空间，中间占满剩余空间")]), _vm._v(" flex-box= "), _c("div", {
    staticClass: "box",
    attrs: {
      flex: ""
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    },
    attrs: {
      "flex-box": "0"
    }
  }, [_vm._v("1(0)")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    },
    attrs: {
      "flex-box": "1"
    }
  }, [_vm._v("2(1)")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    },
    attrs: {
      "flex-box": "0"
    }
  }, [_vm._v("3(0)")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box",
    attrs: {
      flex: "main:center cross:center"
    }
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      background: "red"
    }
  }, [_vm._v("1")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "blue"
    }
  }, [_vm._v("2")]), _c("div", {
    staticClass: "item",
    staticStyle: {
      background: "#000"
    }
  }, [_vm._v("3")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };