import img404 from '@/assets/404.png';
import img404cloud from '@/assets/404_cloud.png';
export default {
  props: {
    message: {
      type: String,
      default: '该页面您无权限喔......'
    }
  },
  data: function data() {
    return {
      img404: img404,
      img404cloud: img404cloud
    };
  }
};