var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_vm.playVideo ? _c("div", {
    staticClass: "v_coBox",
    on: {
      mouseover: function mouseover($event) {
        _vm.moveIn = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.moveIn = false;
      }
    }
  }, [_c("video", {
    ref: "vPlay",
    staticClass: "pvideo",
    attrs: {
      preload: "",
      oncanplay: "",
      controls: "",
      src: _vm.videoInfo.ossVideo,
      "webkit-playsinline": "",
      disablePictureInPicture: "",
      playsinline: ""
    }
  }), _c("span", {
    staticClass: "deviceSerial"
  }, [_vm._v("设备号：" + _vm._s(_vm.videoInfo.deviceSerial))]), _vm.showPause ? _c("i", {
    staticClass: "el-icon-video-play spImg",
    on: {
      click: _vm.play
    }
  }) : _vm._e(), _vm.canPlayError ? _c("span", {
    staticClass: "spImg canPlay"
  }, [_vm._v(_vm._s(_vm.canPlayError))]) : _vm._e()]) : _vm._e(), !_vm.playVideo ? _c("ys-back-video", {
    ref: "back" + _vm.videoInfo.deviceSerial,
    attrs: {
      index: _vm.videoInfo.deviceSerial,
      liveUrl: _vm.videoInfo.liveVideo,
      width: _vm.width,
      height: _vm.height,
      ysToken: _vm.ysToken,
      keyRef: "back" + _vm.videoInfo.deviceSerial
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };