import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "page"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("h1", [_vm._v("系统标签")]), _c("h3", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("系统默认标签，不同任务创建方式对应不同系统标签，标签不可删除")])]), _c("div", {
    staticClass: "tag-list"
  }, _vm._l(_vm.systemTag, function (tag, index) {
    return _c("el-tag", {
      key: tag.tagNumber + index,
      staticClass: "tag",
      staticStyle: {
        border: "none"
      },
      attrs: {
        effect: "dark",
        size: "medium",
        color: tag.tone
      }
    }, [_vm._v(" " + _vm._s(tag.tagName) + " ")]);
  }), 1), _c("div", {
    attrs: {
      flex: "cross:center"
    }
  }, [_c("h1", [_vm._v("客服标签")]), _c("h3", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("客服自定义标签，用于客服在任务过程中标记任务情况，标签可以删除(不影响历史任务的标签记录)")])]), _c("div", {
    staticClass: "tag-list",
    attrs: {
      flex: "cross:center"
    }
  }, [_vm._l(_vm.dynamicTags, function (tag, index) {
    return _c("div", {
      key: tag.tagNumber + index,
      staticClass: "tag"
    }, [_vm.editIndex !== index ? _c("el-tag", {
      staticClass: "tag",
      staticStyle: {
        border: "none"
      },
      attrs: {
        closable: _vm.$isHasAuth(["GJ_VENDING_TAG_MENU"]),
        effect: "dark",
        size: "medium",
        color: tag.tone,
        "disable-transitions": true
      },
      on: {
        close: function close($event) {
          return _vm.handleClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.tagName) + " ")]) : _vm._e(), _vm.editIndex === index ? _c("el-input", {
      ref: "saveTagInput",
      refInFor: true,
      staticClass: "input-new-tag",
      attrs: {
        maxlength: 6,
        size: "small",
        autofocus: ""
      },
      on: {
        blur: function blur($event) {
          return _vm.handleInputConfirm("editValue");
        },
        input: function input(e) {
          return _vm.editValue = _vm.validSe(e);
        }
      },
      nativeOn: {
        keyup: function keyup($event) {
          if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.handleInputConfirm("editValue");
        }
      },
      model: {
        value: _vm.editValue,
        callback: function callback($$v) {
          _vm.editValue = typeof $$v === "string" ? $$v.trim() : $$v;
        },
        expression: "editValue"
      }
    }) : _vm._e()], 1);
  }), _vm.showAddValue ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      maxlength: 6,
      size: "medium"
    },
    on: {
      blur: function blur($event) {
        return _vm.handleInputConfirm("addValue");
      },
      input: function input(e) {
        return _vm.addValue = _vm.validSe(e);
      }
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm("addValue");
      }
    },
    model: {
      value: _vm.addValue,
      callback: function callback($$v) {
        _vm.addValue = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "addValue"
    }
  }) : _c("el-button", {
    directives: [{
      name: "auth",
      rawName: "v-auth",
      value: "GJ_VENDING_TAG_MENU",
      expression: "'GJ_VENDING_TAG_MENU'"
    }],
    staticClass: "button-new-tag",
    attrs: {
      size: "medium"
    },
    on: {
      click: _vm.showInput
    }
  }, [_vm._v("+ 新增标签")])], 2)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };