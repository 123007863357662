import _defineProperty from "/root/workspace/gj-gos-system_qxbP/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.reflect.has.js";
import EZUIKit from 'ezuikit-js';
import { ysErrorCode } from '@/config/dataDictionary';
var player = 'playBack';
export default {
  name: 'videoCard',
  props: {
    liveUrl: {
      type: [String, Number],
      default: ''
    },
    keyRef: String,
    // index: String,
    index: [Number, String],
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    curentIndex: [Number, String],
    ysToken: String
  },
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, player + this.keyRef, null), "playAuto", 'start'), "showMask", true), "messagError", null), "loading", false);
  },
  watch: {
    height: function height(val) {
      if (val) {
        try {
          this[player + this.keyRef].reSize(this.width, val);
        } catch (error) {}
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    onReset: function onReset() {
      if (this.messagError) {
        this.messagError = null;
        this[player + this.keyRef] = null;
        this.init();
      }
    },
    init: function init() {
      var _this = this;
      var that = this;
      var option = {
        id: that.keyRef,
        // 视频容器ID
        audio: false,
        autoplay: false,
        accessToken: that.ysToken,
        url: that.liveUrl,
        // themeData: liveRoomthemeData,
        template: 'pcRec',
        // pcRec simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
        width: that.width,
        height: that.height,
        handleSuccess: function handleSuccess() {
          _this.messagError = null;
          _this.loading = false;
          // if (this.playAuto === 'start') {
          // // 延时是为了有画面
          //   setTimeout(() => {
          //     this[player + this.keyRef].closeSound()
          //     this[player + this.keyRef].stop()
          //     this.playAuto = 'noStart'
          //   }, 3000)
          // }
          _this.showMask = true;
        },
        handleError: function handleError(res) {
          // 6519 设备端断流 重新加载
          console.log(Reflect.has(ysErrorCode));
          // if (Reflect.has(ysErrorCode, res.retcode)) {
          //   res.msg = res.msg?.replace('，刷新重试', '')
          //   res.msg = res.msg?.replace('，请重试', '')
          _this.messagError = res;
          //   this.loading = true
          //   this[player + this.keyRef].stop()
          //   this[player + this.keyRef].play()
          // }
        }
      };
      this[player + this.keyRef] = new EZUIKit.EZUIKitPlayer(option);
    },
    // 开始播放
    play: function play() {
      var playPromise = this[player + this.keyRef].play();
      playPromise.then(function (data) {});
    },
    // 停止播放
    stop: function stop() {
      var stopPromise = this[player + this.keyRef].stop();
      stopPromise.then(function (data) {});
    },
    getOSDTime: function getOSDTime() {
      var getOSDTimePromise = this[player + this.keyRef].getOSDTime();
      getOSDTimePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    capturePicture: function capturePicture() {
      var capturePicturePromise = this[player + this.keyRef].capturePicture("".concat(new Date().getTime()));
      capturePicturePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    openSound: function openSound() {
      var openSoundPromise = this[player + this.keyRef].openSound();
      openSoundPromise.then(function (data) {
        console.log('promise 关闭声音', data);
      });
    },
    closeSound: function closeSound() {
      var openSoundPromise = this[player + this.keyRef].closeSound();
      openSoundPromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    startSave: function startSave() {
      var startSavePromise = this[player + this.keyRef].startSave("".concat(new Date().getTime()));
      startSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    stopSave: function stopSave() {
      var stopSavePromise = this[player + this.keyRef].stopSave();
      stopSavePromise.then(function (data) {
        console.log('promise 获取 数据', data);
      });
    },
    ezopenStartTalk: function ezopenStartTalk() {
      // this[player + this.keyRef].openSound()
      this[player + this.keyRef].startTalk();
    },
    ezopenStopTalk: function ezopenStopTalk() {
      // this[player + this.keyRef].closeSound()
      try {
        this[player + this.keyRef].stopTalk();
      } catch (error) {
        this.$emit('closeVideo');
      }
    },
    fullScreen: function fullScreen() {
      this[player + this.keyRef].fullScreen();
    },
    beforePlayer: function beforePlayer() {
      if (this[player + this.keyRef]) {
        try {
          this.ezopenStopTalk();
          this[player + this.keyRef].stop();
          this[player + this.keyRef].destroy();
          this[player + this.keyRef] = null;
        } catch (error) {
          // console.log(error)
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.beforePlayer();
  }
};