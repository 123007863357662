export default (function (Vue) {
  Vue.directive('drag', {
    // 指令的定义
    bind: function bind(el) {
      var oDiv = el; // 获取当前元素
      oDiv.onmousedown = function (e) {
        oDiv.style.cursor = 'move';
        // 算出鼠标相对元素的位置
        var disX = e.clientX - oDiv.offsetLeft;
        var disY = e.clientY - oDiv.offsetTop;
        document.onmousemove = function (e) {
          // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
          var left = e.clientX - disX;
          var top = e.clientY - disY;
          // 移动当前元素
          oDiv.style.left = left + 'px';
          oDiv.style.top = top + 'px';
        };
        document.onmouseup = function (e) {
          oDiv.style.cursor = 'pointer';
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    }
  });
});