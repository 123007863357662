var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "title-main"
  }, [_vm._v("商品销售排行榜")]), _c("el-date-picker", {
    staticClass: "date-picker",
    attrs: {
      type: "daterange",
      clearable: false,
      "value-format": "yyyy-MM-dd",
      "range-separator": "至",
      "picker-options": _vm.pickerOptions,
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    on: {
      change: _vm.getList
    },
    model: {
      value: _vm.timeArr,
      callback: function callback($$v) {
        _vm.timeArr = $$v;
      },
      expression: "timeArr"
    }
  }), _c("el-tooltip", {
    staticClass: "tooltipPost",
    attrs: {
      effect: "dark",
      placement: "top-start"
    }
  }, [_c("div", {
    staticClass: "tooltip_content",
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.homeTip.productRanking)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: "445",
      border: ""
    },
    on: {
      "sort-change": _vm.onSortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "rank",
      label: "排名",
      width: "70"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productName",
      label: "商品名称"
    }
  }), _c("el-table-column", {
    attrs: {
      sortable: "",
      prop: "saleCount",
      label: "销量"
    }
  }), _c("el-table-column", {
    attrs: {
      sortable: "",
      prop: "saleAmount",
      label: "销售额"
    }
  })], 1), _c("gj-pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.size
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        _vm.size = $event;
      },
      pagination: _vm.getList
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };