import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export default {
  data: function data() {
    return {
      isUserMedia: false,
      stream: null,
      audio: null,
      recorder: null,
      chunks: [],
      startTime: 0,
      isRecord: false,
      // 是否在录音
      volume: 0 // 当前音量
    };
  },
  mounted: function mounted() {
    this.getRecorderManager();
  },
  methods: {
    getRecorderManager: function getRecorderManager() {
      var _this = this;
      this.audio = document.createElement('audio');
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then(function (stream) {
        _this.isUserMedia = true;
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
        _this.start();
      }).catch(function (err) {
        console.log(err);
      });
    },
    start: function start() {
      var _this2 = this;
      if (!this.isUserMedia) return console.log('设备不支持');
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then(function (stream) {
        _this2.isRecord = true;
        _this2.beginDetect(stream);
        console.log(stream, '52');
        _this2.startTime = new Date().getTime();
        _this2.stream = stream;
        _this2.recorder = new MediaRecorder(stream);
        _this2.recorder.ondataavailable = _this2.getRecordingData;
        _this2.recorder.onstop = _this2.saveRecordingData;
        _this2.recorder.start();
      }).catch(function (err) {
        console.log(err);
        // this.onErrorHandler(err)
      });
    },
    // 麦克风音量检测
    beginDetect: function beginDetect(stream) {
      var _this3 = this;
      var audioContext = new (window.AudioContext || window.webkitAudioContext)();
      var mediaStreamSource = null;
      var scriptProcessor = null;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 将麦克风的声音输入这个对象
        mediaStreamSource = audioContext.createMediaStreamSource(stream);
        // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
        scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
        // 将该分析对象与麦克风音频进行连接
        mediaStreamSource.connect(scriptProcessor);
        // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
        scriptProcessor.connect(audioContext.destination);
        // 开始处理音频
        scriptProcessor.onaudioprocess = function (e) {
          // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
          var buffer = e.inputBuffer.getChannelData(0);
          // 获取缓冲区中最大的音量值
          var maxVal = Math.max.apply(Math, buffer);
          // 显示音量值
          if (_this3.isRecord) {
            // if (this.volume > 0) {
            _this3.volume = Math.round(maxVal * 10);
            // }
            // this.$emit('getVolume', Math.round(maxVal * 10))
          }
          ;
        };
      } else {
        console.log('不支持获取媒体接口');
      }
    },
    stop: function stop() {
      this.recorder.stop();
      this.stream.getTracks().forEach(function (track) {
        console.log(track, '66');
        track.stop();
      });
    },
    getRecordingData: function getRecordingData(e) {
      console.log(e, '71');
      this.chunks.push(e.data);
    },
    saveRecordingData: function saveRecordingData() {
      var blob = new Blob(this.chunks, {
        type: 'audio/mpeg'
      });
      var localUrl = URL.createObjectURL(blob);
      var endTime = new Date().getTime();
      var duration = (endTime - this.startTime).toString().split('');
      duration.splice(duration.length - 2);
      duration.splice(duration.length - 1, 0, '.');
      duration = parseFloat(duration.join(''));
      var recorder = {
        data: blob,
        duration: duration,
        localUrl: localUrl
      };
      this.$emit('success', recorder);
      this.isRecord = false;
    }
  },
  destroyed: function destroyed() {
    this.stop();
  }
};